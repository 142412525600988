function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useId } from 'react';
import { SVGIconProps } from '../types/svgIconProps';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
type SpinnerProps = {
  thickness?: number;
  className?: string;
  compact?: boolean;
} & ({
  compact?: boolean;
  size?: never;
} | {
  compact?: never;
  size?: number;
}) & SVGIconProps;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1ewlgsu",
  styles: "@keyframes spin{to{transform:rotate(360deg);}}animation:spin 1s linear infinite"
} : {
  name: "1xwd0s-Spinner",
  styles: "@keyframes spin{to{transform:rotate(360deg);}}animation:spin 1s linear infinite;label:Spinner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function Spinner({
  thickness = 4,
  className,
  compact = false,
  size = 24,
  ...restProps
}: SpinnerProps) {
  const id = useId();
  const diameter = compact ? 18 : size;
  const radius = diameter / 2;
  return _jsxs("svg", {
    css: _ref,
    className: className,
    viewBox: `0 0 ${diameter} ${diameter}`,
    width: diameter,
    height: diameter,
    "data-testid": "spinner",
    ...restProps,
    children: [_jsx("defs", {
      children: _jsxs("mask", {
        id: `${id}-spinnerMask`,
        children: [_jsx("rect", {
          width: "100%",
          height: "100%",
          fill: "white"
        }), _jsx("circle", {
          cx: radius,
          cy: radius,
          r: radius - thickness,
          fill: "black"
        }), _jsx("polygon", {
          points: `${radius} 0, ${radius} ${radius}, 0 ${diameter}, ${diameter} ${diameter}, ${diameter} 0, ${radius} 0`,
          fill: "black"
        })]
      })
    }), _jsx("circle", {
      cx: radius,
      cy: radius,
      r: radius,
      fill: "currentColor",
      mask: `url(#${id}-spinnerMask)`
    })]
  });
}
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "plxcyc",
  styles: "position:fixed;inset:0;z-index:80;display:grid;place-content:center;--tw-text-opacity:1;color:rgb(204 210 210 / var(--tw-text-opacity))"
} : {
  name: "1esm89t-PageSpinner",
  styles: "position:fixed;inset:0;z-index:80;display:grid;place-content:center;--tw-text-opacity:1;color:rgb(204 210 210 / var(--tw-text-opacity));label:PageSpinner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const PageSpinner = () => _jsx("div", {
  css: _ref2,
  children: _jsx(Spinner, {})
});