import { ComponentPropsWithoutRef, MouseEvent, useCallback, useState } from 'react';
import { Confetti, ConfettiProps } from '.';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ConfettiClick = ({
  onClick,
  children,
  ...restProps
}: ComponentPropsWithoutRef<'button'>) => {
  const [confettiShots, setConfettiShot] = useState<{
    key: number;
    options: NonNullable<ConfettiProps['options']>;
  }[]>([]);
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const clickedPosition = {
      x: event.clientX,
      y: event.clientY
    };
    if (event.clientX === 0 && event.clientY === 0) {
      const bounds = event.currentTarget.getBoundingClientRect();
      clickedPosition.x = bounds.x + bounds.width / 2;
      clickedPosition.y = bounds.y + bounds.height / 2;
    }
    setConfettiShot([{
      key: Date.now(),
      options: {
        origin: {
          x: clickedPosition.x / window.innerWidth,
          y: clickedPosition.y / window.innerHeight
        },
        angle: 0,
        spread: 360,
        startVelocity: 10,
        decay: 0.96,
        ticks: 50,
        gravity: 0.5,
        disableForReducedMotion: true
      }
    }]);
    onClick?.(event);
  }, [onClick]);
  return _jsxs("button", {
    tw: "relative",
    onClick: handleClick,
    ...restProps,
    children: [children, confettiShots.map(shot => _jsx(Confetti, {
      options: shot.options,
      onComplete: () => {
        setConfettiShot([]);
      }
    }, shot.key))]
  });
};