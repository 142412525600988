import { useCallback, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { BarProps } from 'recharts';
import { useDidMount } from 'rooks';
import { useIsResizingViewport } from '../../utils/useViewportSize';
type ChartAnimationProps = Pick<BarProps, 'isAnimationActive' | 'animationDuration' | 'animationBegin'>;

/**
 * Hook for easily setting animation props on chart components.
 *
 * Supports adding a delay for appear animations only. Subsequent animations will
 * not be delayed.
 *
 * Only triggers animation for in view charts (bind `chartRef` to the chart or a container
 * for this to work).
 *
 * Will automatically disable animations while resizing also.
 */
export function useChartAnimation({
  animationBegin = 0
}) {
  const appearTimeoutRef = useRef<NodeJS.Timeout>();
  const [appear, setAppear] = useState(false);
  const isResizing = useIsResizingViewport();
  const [chartRef, isInView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });
  const animateChart = useCallback(({
    delay,
    duration
  }: {
    delay?: number;
    duration?: number;
  } = {}): ChartAnimationProps => {
    return {
      isAnimationActive: !isResizing,
      animationDuration: duration,
      animationBegin: delay
    };
  }, [isResizing]);
  useDidMount(() => {
    if (__IS_BROWSER__) {
      appearTimeoutRef.current = setTimeout(() => {
        clearTimeout(appearTimeoutRef.current);
        setAppear(true);
      }, animationBegin);
    }
  });
  return {
    animateChart,
    chartRef,
    animatedActive: isInView && appear
  };
}