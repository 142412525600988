import { defineMessage, defineMessages, MessageDescriptor } from 'react-intl';
export enum FeatureAvailability {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Undecided = 'UNDECIDED',
}
export type ListingFeatureDetails = {
  availability: FeatureAvailability;
  label: string;
};
export type ListingFeatures = {
  activityRoom: ListingFeatureDetails;
  appleTv: ListingFeatureDetails;
  barbecue: ListingFeatureDetails;
  bathtub: ListingFeatureDetails;
  bicycles: ListingFeatureDetails;
  billiards: ListingFeatureDetails;
  boardGames: ListingFeatureDetails;
  boats: ListingFeatureDetails;
  books: ListingFeatureDetails;
  campfireSite: ListingFeatureDetails;
  childBed: ListingFeatureDetails;
  chromecast: ListingFeatureDetails;
  closeToHikingPaths: ListingFeatureDetails;
  closeToSkiResort: ListingFeatureDetails;
  coffeeMachine: ListingFeatureDetails;
  crossCountrySkiing: ListingFeatureDetails;
  desk: ListingFeatureDetails;
  deskLamp: ListingFeatureDetails;
  dishwasher: ListingFeatureDetails;
  dryer: ListingFeatureDetails;
  electricKettle: ListingFeatureDetails;
  enclosedGarden: ListingFeatureDetails;
  evCharger: ListingFeatureDetails;
  exhaustHood: ListingFeatureDetails;
  extraMattress: ListingFeatureDetails;
  flashlight: ListingFeatureDetails;
  freezer: ListingFeatureDetails;
  gardenRecliners: ListingFeatureDetails;
  hammock: ListingFeatureDetails;
  heatPump: ListingFeatureDetails;
  heightAdjustableDesk: ListingFeatureDetails;
  highChair: ListingFeatureDetails;
  horseStable: ListingFeatureDetails;
  hotTub: ListingFeatureDetails;
  hotWater: ListingFeatureDetails;
  jetty: ListingFeatureDetails;
  linenAndTowels: ListingFeatureDetails;
  microwave: ListingFeatureDetails;
  officeChair: ListingFeatureDetails;
  outdoorGames: ListingFeatureDetails;
  outdoorKitchen: ListingFeatureDetails;
  outdoorPool: ListingFeatureDetails;
  outdoorShower: ListingFeatureDetails;
  oven: ListingFeatureDetails;
  parasol: ListingFeatureDetails;
  partiesAllowed: ListingFeatureDetails;
  patioFurniture: ListingFeatureDetails;
  petsAllowed: ListingFeatureDetails;
  pizzaOven: ListingFeatureDetails;
  playground: ListingFeatureDetails;
  pool: ListingFeatureDetails;
  ridingArena: ListingFeatureDetails;
  sauna: ListingFeatureDetails;
  seaView: ListingFeatureDetails;
  skiInOut: ListingFeatureDetails;
  smokingAllowed: ListingFeatureDetails;
  spa: ListingFeatureDetails;
  streamingService: ListingFeatureDetails;
  suitableForChildren: ListingFeatureDetails;
  suitableForInfants: ListingFeatureDetails;
  supBoards: ListingFeatureDetails;
  tableTennis: ListingFeatureDetails;
  teaTowelsAndDishcloth: ListingFeatureDetails;
  terrace: ListingFeatureDetails;
  toys: ListingFeatureDetails;
  trampoline: ListingFeatureDetails;
  tv: ListingFeatureDetails;
  ultraVioletLights: ListingFeatureDetails;
  washingMachine: ListingFeatureDetails;
  wheelchairAccessible: ListingFeatureDetails;
  wifi: ListingFeatureDetails;
  wirelessMusicPlayer: ListingFeatureDetails;
  woodburningStove: ListingFeatureDetails;
};
export type ListingFeatureWithKey = ListingFeatureDetails & {
  key: keyof ListingFeatures;
};
export type ListingFeatureGroupKey = 'outdoor' | 'indoor' | 'kitchen' | 'technology' | 'workspace';
export type ListingFeatureGroup = {
  key: ListingFeatureGroupKey;
  title: MessageDescriptor;
  features: (keyof ListingFeatures)[];
};
const OUTDOOR_FEATURES = (({
  key: 'outdoor',
  title: defineMessage({
    id: "e8kj97TPby",
    defaultMessage: [{
      "type": 0,
      "value": "Outdoor"
    }]
  }),
  features: ['outdoorGames', 'playground', 'trampoline', 'bicycles', 'boats', 'outdoorShower', 'outdoorPool', 'spa', 'hotTub', 'jetty', 'seaView', 'campfireSite', 'barbecue', 'outdoorKitchen', 'terrace', 'hammock', 'skiInOut', 'crossCountrySkiing', 'closeToSkiResort', 'patioFurniture', 'gardenRecliners', 'parasol', 'supBoards', 'closeToHikingPaths', 'enclosedGarden']
} as const) satisfies ListingFeatureGroup);
const INDOOR_FEATURES = (({
  key: 'indoor',
  title: defineMessage({
    id: "ZjMTug8CTz",
    defaultMessage: [{
      "type": 0,
      "value": "Indoor"
    }]
  }),
  features: ['boardGames', 'books', 'toys', 'ultraVioletLights', 'heatPump', 'woodburningStove', 'sauna', 'pool', 'dryer', 'washingMachine', 'childBed', 'highChair', 'bathtub', 'hotWater', 'activityRoom', 'wheelchairAccessible']
} as const) satisfies ListingFeatureGroup);
const KITCHEN_FEATURES = (({
  key: 'kitchen',
  title: defineMessage({
    id: "THfcEAeMA+",
    defaultMessage: [{
      "type": 0,
      "value": "Kitchen"
    }]
  }),
  features: ['coffeeMachine', 'dishwasher', 'freezer', 'oven', 'microwave', 'pizzaOven', 'exhaustHood', 'electricKettle']
} as const) satisfies ListingFeatureGroup);
const TECHNOLOGY_FEATURES = (({
  key: 'technology',
  title: defineMessage({
    id: "rZsZOwxVHd",
    defaultMessage: [{
      "type": 0,
      "value": "Technology"
    }]
  }),
  features: ['evCharger', 'tv', 'wifi', 'streamingService', 'appleTv', 'chromecast', 'wirelessMusicPlayer']
} as const) satisfies ListingFeatureGroup);
const WORKSPACE_FEATURES = (({
  key: 'workspace',
  title: defineMessage({
    id: "CowNN4M/ez",
    defaultMessage: [{
      "type": 0,
      "value": "Workspace"
    }]
  }),
  features: ['desk', 'heightAdjustableDesk', 'officeChair', 'deskLamp']
} as const) satisfies ListingFeatureGroup);
export const FEATURES_GROUPS = (([OUTDOOR_FEATURES, INDOOR_FEATURES, KITCHEN_FEATURES, TECHNOLOGY_FEATURES, WORKSPACE_FEATURES] as const) satisfies ListingFeatureGroup[]);
export const FEATURES_SUBSET = FEATURES_GROUPS.flatMap(group => group.features);
export const listingFeatureLabels = defineMessages({
  activityRoom: {
    id: "OuwMFxGeHq",
    defaultMessage: [{
      "type": 0,
      "value": "Activity room"
    }]
  },
  appleTv: {
    id: "ziDtLFnLGK",
    defaultMessage: [{
      "type": 0,
      "value": "Apple TV"
    }]
  },
  barbecue: {
    id: "7nzRoxtA38",
    defaultMessage: [{
      "type": 0,
      "value": "Barbecue (gas/charcoal)"
    }]
  },
  bathtub: {
    id: "0n2S/hB25U",
    defaultMessage: [{
      "type": 0,
      "value": "Bathtub"
    }]
  },
  bicycles: {
    id: "A+hFS+1bcb",
    defaultMessage: [{
      "type": 0,
      "value": "Bicycles"
    }]
  },
  billiards: {
    id: "IBTEhDCusi",
    defaultMessage: [{
      "type": 0,
      "value": "Billiards"
    }]
  },
  boardGames: {
    id: "YyUuTdToxO",
    defaultMessage: [{
      "type": 0,
      "value": "Board games"
    }]
  },
  boats: {
    id: "waWyce7NRo",
    defaultMessage: [{
      "type": 0,
      "value": "Canoe/Kayak/Row-boat"
    }]
  },
  books: {
    id: "oeZDAXNiub",
    defaultMessage: [{
      "type": 0,
      "value": "Books"
    }]
  },
  campfireSite: {
    id: "ULztQ5Rm0P",
    defaultMessage: [{
      "type": 0,
      "value": "Campfire site"
    }]
  },
  childBed: {
    id: "wgORjZPYI4",
    defaultMessage: [{
      "type": 0,
      "value": "Cot"
    }]
  },
  chromecast: {
    id: "gm3rm939oA",
    defaultMessage: [{
      "type": 0,
      "value": "Chromecast"
    }]
  },
  closeToHikingPaths: {
    id: "b2c2bU3ooZ",
    defaultMessage: [{
      "type": 0,
      "value": "Close to hiking paths"
    }]
  },
  closeToSkiResort: {
    id: "Y0cSKeueRD",
    defaultMessage: [{
      "type": 0,
      "value": "Close to ski resort"
    }]
  },
  coffeeMachine: {
    id: "ytoOdcq9eF",
    defaultMessage: [{
      "type": 0,
      "value": "Coffee machine"
    }]
  },
  crossCountrySkiing: {
    id: "L+zhdqB4+9",
    defaultMessage: [{
      "type": 0,
      "value": "Cross country skiing in/out"
    }]
  },
  desk: {
    id: "BlYIg8bnHh",
    defaultMessage: [{
      "type": 0,
      "value": "Desk"
    }]
  },
  deskLamp: {
    id: "ofsXIBp9sh",
    defaultMessage: [{
      "type": 0,
      "value": "Desk lamp"
    }]
  },
  dishwasher: {
    id: "uJbxNuZ5ub",
    defaultMessage: [{
      "type": 0,
      "value": "Dishwasher"
    }]
  },
  dryer: {
    id: "MNsQUc2Nl0",
    defaultMessage: [{
      "type": 0,
      "value": "Dryer"
    }]
  },
  electricKettle: {
    id: "fXx1MR2FlN",
    defaultMessage: [{
      "type": 0,
      "value": "Electric kettle"
    }]
  },
  enclosedGarden: {
    id: "Shf4oDTo2V",
    defaultMessage: [{
      "type": 0,
      "value": "Enclosed garden"
    }]
  },
  evCharger: {
    id: "zPL8XVAOX0",
    defaultMessage: [{
      "type": 0,
      "value": "EV charger"
    }]
  },
  exhaustHood: {
    id: "pCHwYq1KJ9",
    defaultMessage: [{
      "type": 0,
      "value": "Exhaust hood"
    }]
  },
  extraMattress: {
    id: "YP4OEPahDc",
    defaultMessage: [{
      "type": 0,
      "value": "Extra mattress"
    }]
  },
  flashlight: {
    id: "nn2DgP7kNg",
    defaultMessage: [{
      "type": 0,
      "value": "Flashlight"
    }]
  },
  freezer: {
    id: "bwJbTtxNcI",
    defaultMessage: [{
      "type": 0,
      "value": "Freezer"
    }]
  },
  gardenRecliners: {
    id: "pSDI27515c",
    defaultMessage: [{
      "type": 0,
      "value": "Garden recliners"
    }]
  },
  hammock: {
    id: "jBH9gDE6z2",
    defaultMessage: [{
      "type": 0,
      "value": "Hammock"
    }]
  },
  heatPump: {
    id: "7GcY4Jrb1f",
    defaultMessage: [{
      "type": 0,
      "value": "Heat pump"
    }]
  },
  heightAdjustableDesk: {
    id: "X9Z/yKFpsJ",
    defaultMessage: [{
      "type": 0,
      "value": "Height-adjustable desk"
    }]
  },
  highChair: {
    id: "KjwSfIzMO0",
    defaultMessage: [{
      "type": 0,
      "value": "High chair"
    }]
  },
  horseStable: {
    id: "J0Fkkce9uY",
    defaultMessage: [{
      "type": 0,
      "value": "Horse stable"
    }]
  },
  hotTub: {
    id: "AuWyBvTqPv",
    defaultMessage: [{
      "type": 0,
      "value": "Wilderness bath"
    }]
  },
  hotWater: {
    id: "Xeaai4j1jn",
    defaultMessage: [{
      "type": 0,
      "value": "Hot water"
    }]
  },
  jetty: {
    id: "CzjlOELLAs",
    defaultMessage: [{
      "type": 0,
      "value": "Jetty"
    }]
  },
  linenAndTowels: {
    id: "LTl5kPKR0D",
    defaultMessage: [{
      "type": 0,
      "value": "Linen and towels"
    }]
  },
  microwave: {
    id: "29X2N01n9i",
    defaultMessage: [{
      "type": 0,
      "value": "Microwave"
    }]
  },
  officeChair: {
    id: "8uDFQfh5Ko",
    defaultMessage: [{
      "type": 0,
      "value": "Office chair"
    }]
  },
  outdoorGames: {
    id: "GVZmldd+y5",
    defaultMessage: [{
      "type": 0,
      "value": "Outdoor games"
    }]
  },
  outdoorKitchen: {
    id: "fwOnLE9kZ4",
    defaultMessage: [{
      "type": 0,
      "value": "Outdoor kitchen"
    }]
  },
  outdoorPool: {
    id: "tNCtbQ6fIK",
    defaultMessage: [{
      "type": 0,
      "value": "Outdoor pool"
    }]
  },
  outdoorShower: {
    id: "QBT5w1J1HM",
    defaultMessage: [{
      "type": 0,
      "value": "Outdoor shower"
    }]
  },
  oven: {
    id: "4S/W2IMo/f",
    defaultMessage: [{
      "type": 0,
      "value": "Oven"
    }]
  },
  parasol: {
    id: "EQkcPwZgsu",
    defaultMessage: [{
      "type": 0,
      "value": "Parasol"
    }]
  },
  partiesAllowed: {
    id: "WneyMI4hGC",
    defaultMessage: [{
      "type": 0,
      "value": "Parties allowed"
    }]
  },
  patioFurniture: {
    id: "+jWu3yt44i",
    defaultMessage: [{
      "type": 0,
      "value": "Garden furniture"
    }]
  },
  petsAllowed: {
    id: "0bFzdV0kKi",
    defaultMessage: [{
      "type": 0,
      "value": "Pets allowed"
    }]
  },
  pizzaOven: {
    id: "+3C12b3Fx3",
    defaultMessage: [{
      "type": 0,
      "value": "Pizza oven"
    }]
  },
  playground: {
    id: "QCThjQbp0b",
    defaultMessage: [{
      "type": 0,
      "value": "Playground"
    }]
  },
  pool: {
    id: "CQEl9FvVvB",
    defaultMessage: [{
      "type": 0,
      "value": "Pool"
    }]
  },
  ridingArena: {
    id: "zeLdiwy6q5",
    defaultMessage: [{
      "type": 0,
      "value": "Riding arena"
    }]
  },
  sauna: {
    id: "M6/exlPZW6",
    defaultMessage: [{
      "type": 0,
      "value": "Sauna"
    }]
  },
  seaView: {
    id: "HCjfkqTsiY",
    defaultMessage: [{
      "type": 0,
      "value": "Sea view"
    }]
  },
  skiInOut: {
    id: "u29GAaddII",
    defaultMessage: [{
      "type": 0,
      "value": "Alpine ski in/out"
    }]
  },
  smokingAllowed: {
    id: "ViqtUlb9mB",
    defaultMessage: [{
      "type": 0,
      "value": "Smoking allowed"
    }]
  },
  spa: {
    id: "vo521GU8Ue",
    defaultMessage: [{
      "type": 0,
      "value": "Hot tub"
    }]
  },
  streamingService: {
    id: "gK/rBvlQr1",
    defaultMessage: [{
      "type": 0,
      "value": "Streaming service"
    }]
  },
  suitableForChildren: {
    id: "6U2m4IYFJc",
    defaultMessage: [{
      "type": 0,
      "value": "Children are welcome (3-12 years)"
    }]
  },
  suitableForInfants: {
    id: "PXhzIWXFUg",
    defaultMessage: [{
      "type": 0,
      "value": "Infants are welcome (Below 3 years)"
    }]
  },
  supBoards: {
    id: "RmXDMOB3Vv",
    defaultMessage: [{
      "type": 0,
      "value": "SUP boards"
    }]
  },
  tableTennis: {
    id: "w4WKRq18mL",
    defaultMessage: [{
      "type": 0,
      "value": "Table tennis"
    }]
  },
  teaTowelsAndDishcloth: {
    id: "UsUdFqfgwh",
    defaultMessage: [{
      "type": 0,
      "value": "Tea towels and dishcloths"
    }]
  },
  terrace: {
    id: "lq1a42ip+D",
    defaultMessage: [{
      "type": 0,
      "value": "Terrace"
    }]
  },
  toys: {
    id: "oBObGDQsyv",
    defaultMessage: [{
      "type": 0,
      "value": "Toys"
    }]
  },
  trampoline: {
    id: "0+N6Jv702m",
    defaultMessage: [{
      "type": 0,
      "value": "Trampoline"
    }]
  },
  tv: {
    id: "GwfwKbwzwA",
    defaultMessage: [{
      "type": 0,
      "value": "TV"
    }]
  },
  ultraVioletLights: {
    id: "GgA4Mw2HZI",
    defaultMessage: [{
      "type": 0,
      "value": "Amber lights"
    }]
  },
  washingMachine: {
    id: "S9uyBg4hxn",
    defaultMessage: [{
      "type": 0,
      "value": "Washing machine"
    }]
  },
  wheelchairAccessible: {
    id: "Btp+F/R375",
    defaultMessage: [{
      "type": 0,
      "value": "Wheelchair friendly"
    }]
  },
  wifi: {
    id: "UR8OQC0kuO",
    defaultMessage: [{
      "type": 0,
      "value": "Wifi"
    }]
  },
  wirelessMusicPlayer: {
    id: "kGz5lgcSok",
    defaultMessage: [{
      "type": 0,
      "value": "Wireless music player"
    }]
  },
  woodburningStove: {
    id: "XVRZzqycrc",
    defaultMessage: [{
      "type": 0,
      "value": "Woodburning stove"
    }]
  }
});
export const listingFeatureBookerTooltips = defineMessages({
  evCharger: {
    id: "MtPZ9e+BkE",
    defaultMessage: [{
      "type": 0,
      "value": "Make an agreement directly with the host if you want to use the EV charger - this is not included in the price"
    }]
  }
});