import { isEqual, pick } from 'lodash';
import { useCallback, useRef } from 'react';
import type { CategoricalChartFunc, CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
export type ChartInspectionPayload = {
  dataKey?: string;
  value?: string | number;
};
export type ChartInspection = {
  label?: string;
  payload?: ChartInspectionPayload[];
};
export type ChartInspectionCallback<T = object> = (inspected: ChartInspection & T) => void;
type UseChartInspectionOptions = {
  transform?: (event: CategoricalChartState) => ChartInspection | undefined;
};

/**
 * A custom hook that facilitates tracking of user inspections of a chart.
 * It provides handlers for various mouse events and processes the event data
 * through an optional transform callback before invoking the tracking callback.
 *
 * - `track`: The callback function to be called with the inspected data.
 * - `options.transform`: Optional function to transform the inspected data.
 *
 * Returns an object with handlers for onClick, onMouseMove, and onMouseLeave events.
 */
export default function useTrackChartInspection(track?: ChartInspectionCallback, options?: UseChartInspectionOptions): {
  onClick: CategoricalChartFunc;
  onMouseMove: CategoricalChartFunc;
  onMouseLeave: CategoricalChartFunc;
} {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const activeInspection = useRef<ChartInspection | undefined>();

  // Returns a default inspection state using either default or custom transform.
  const getInspection = useCallback((event: CategoricalChartState): ChartInspection => {
    const defaultState = {
      label: event.activeLabel,
      payload: (event.activePayload ?? []).map(p => pick(p, 'dataKey', 'value')).filter(Boolean)
    };
    const customState = options?.transform ? options.transform(event) : undefined;
    return {
      label: customState?.label ?? defaultState.label,
      payload: customState?.payload ?? defaultState.payload
    };
  }, [options]);

  // Fires track callback (with debounce for hover inspections)
  const emitInspection = useCallback((inspection: ChartInspection, isHover: boolean): void => {
    if (isHover) {
      timeoutRef.current = setTimeout(() => track?.(inspection), 300);
    } else {
      track?.(inspection);
    }
  }, [track]);

  // Updates the active inspection state and triggers the tracking callback when changes occur.
  const updateInspection = useCallback((event: CategoricalChartState, isHover = false): void => {
    const inspection = getInspection(event);

    // Check if the inspection data has changed since the last event.
    const inspectionChanged = !isEqual(inspection.payload, activeInspection.current?.payload) || activeInspection.current?.label !== inspection.label;
    if (inspectionChanged) {
      timeoutRef.current && clearTimeout(timeoutRef.current);

      // Clear the current inspection state if no data or label is present.
      if (inspection.payload?.length === 0 && !inspection.label) {
        activeInspection.current = undefined;
        return;
      }
      activeInspection.current = inspection;
      emitInspection(inspection, isHover);
    }
  }, [emitInspection, getInspection]);
  return {
    onClick: useCallback((event: CategoricalChartState) => updateInspection(event), [updateInspection]),
    onMouseMove: useCallback((event: CategoricalChartState) => updateInspection(event, true), [updateInspection]),
    onMouseLeave: useCallback(() => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
      activeInspection.current = undefined;
    }, [])
  };
}