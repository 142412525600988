import { differenceInCalendarDays } from 'date-fns';
import { useIntl } from 'react-intl';
import { getDateFromTimestamp } from '../../utils/date';
import { NOW } from '../../utils/time';
import Badge from '../Badge';
import { BookingState } from '../Calendar/types';
import bookingStateLabel, { BookingStateOrProgress } from './bookingStateLabel';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function getBadgeTypeForState(state: BookingStateOrProgress) {
  switch (state) {
    case 'STARTED':
    case 'UNDERWAY':
      return 'success';
    case BookingState.Checkout:
    case BookingState.Pending:
      return 'warning';
    case BookingState.Withdrawn:
    case BookingState.Declined:
    case BookingState.Cancelled:
      return 'error';
    case BookingState.Confirmed:
      return 'success';
    case BookingState.Completed:
      return 'muted';
    default:
      return undefined;
  }
}
export default function BookingStateBadge({
  state,
  className,
  checkinOn,
  checkoutOn
}: {
  state: BookingState;
  className?: string;
  checkinOn?: string;
  checkoutOn?: string;
}) {
  const intl = useIntl();
  const checkinDate = checkinOn ? getDateFromTimestamp(checkinOn) : undefined;
  const checkoutDate = checkoutOn ? getDateFromTimestamp(checkoutOn) : undefined;
  let badgeState: BookingStateOrProgress = state;
  if (state === BookingState.Confirmed && checkinDate && checkoutDate && differenceInCalendarDays(checkinDate, NOW) <= 0 && differenceInCalendarDays(checkoutDate, NOW) >= 0) {
    // If less than 3 days into stay set state as started, otherwise underway
    badgeState = differenceInCalendarDays(NOW, checkinDate) < 3 ? 'STARTED' : 'UNDERWAY';
  }
  const label = bookingStateLabel(badgeState);
  return _jsx(Badge, {
    className: className,
    type: getBadgeTypeForState(badgeState),
    children: label && intl.formatMessage(label)
  });
}