function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ComponentPropsWithoutRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ArrowLeft from '../../../../../icons/ArrowLeft.svg';
import { useCalendarSelectionContext } from '../../../context';
import { useCalendarAsideContext } from '../../context';
import { ViewHeading } from '../../shared';
import { DateConfigurationViews } from '..';
import { CheckRadioBox } from './shared';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1iz4oz4",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem"
} : {
  name: "1ddkiry-MixedAvailability",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem;label:MixedAvailability;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "185o5ba",
  styles: "text-decoration-line:underline"
} : {
  name: "bnxwt7-MixedAvailability",
  styles: "text-decoration-line:underline;label:MixedAvailability;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "xvw0zl",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem"
} : {
  name: "se774w-MixedAvailability",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem;label:MixedAvailability;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "riiewv",
  styles: "margin-top:1rem;display:flex;flex-direction:column;gap:1rem"
} : {
  name: "10iyztw-MixedAvailability",
  styles: "margin-top:1rem;display:flex;flex-direction:column;gap:1rem;label:MixedAvailability;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "8kx9fd-MixedAvailability",
  styles: "font-weight:600;label:MixedAvailability;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "8kx9fd-MixedAvailability",
  styles: "font-weight:600;label:MixedAvailability;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const MixedAvailability = ({
  setView,
  ...props
}: {
  setView: (view: DateConfigurationViews | undefined) => void;
} & ComponentPropsWithoutRef<'div'>) => {
  const [availablility, setAvailability] = useState<boolean>();
  const {
    selectedDates
  } = useCalendarSelectionContext();
  const {
    onSubmitConfiguration
  } = useCalendarAsideContext();
  return _jsxs("div", {
    ...props,
    children: [_jsxs("button", {
      css: _ref,
      type: "button",
      onClick: () => setView(undefined),
      children: [_jsx(ArrowLeft, {}), _jsx("p", {
        css: _ref2,
        children: _jsx(FormattedMessage, {
          id: "8AvlH37f7b",
          defaultMessage: [{
            "type": 0,
            "value": "Back"
          }]
        })
      })]
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsx(ViewHeading, {
        children: _jsx(FormattedMessage, {
          id: "sO2q1Pnc7N",
          defaultMessage: [{
            "type": 0,
            "value": "Edit availability"
          }]
        })
      }), _jsx("p", {
        children: _jsx(FormattedMessage, {
          id: "Eaa693/Xu4",
          defaultMessage: [{
            "type": 0,
            "value": "Your selected nights have different availability."
          }]
        })
      }), _jsxs("fieldset", {
        role: "radiogroup",
        css: _ref4,
        children: [_jsx(CheckRadioBox, {
          type: "radio",
          onClick: () => {
            setAvailability(true);
            onSubmitConfiguration?.({
              dates: selectedDates,
              available: true
            });
          },
          checked: availablility === true,
          value: "OPEN",
          label: _jsxs(_Fragment, {
            children: [_jsx("h4", {
              css: _ref5,
              children: _jsx(FormattedMessage, {
                id: "28SnSHi48s",
                defaultMessage: [{
                  "type": 0,
                  "value": "Open all"
                }]
              })
            }), _jsx("p", {
              children: _jsx(FormattedMessage, {
                id: "+alYs+wr8/",
                defaultMessage: [{
                  "type": 0,
                  "value": "Open the selected "
                }, {
                  "type": 6,
                  "value": "nights",
                  "options": {
                    "one": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " night"
                      }]
                    },
                    "other": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " nights"
                      }]
                    }
                  },
                  "offset": 0,
                  "pluralType": "cardinal"
                }, {
                  "type": 0,
                  "value": " for bookings"
                }],
                values: {
                  nights: selectedDates.length
                }
              })
            })]
          })
        }), _jsx(CheckRadioBox, {
          type: "radio",
          onClick: () => {
            setAvailability(false);
            onSubmitConfiguration?.({
              dates: selectedDates,
              available: false
            });
          },
          checked: availablility === false,
          value: "CLOSED",
          label: _jsxs(_Fragment, {
            children: [_jsx("h4", {
              css: _ref6,
              children: _jsx(FormattedMessage, {
                id: "H0D4rBFWQ0",
                defaultMessage: [{
                  "type": 0,
                  "value": "Close all"
                }]
              })
            }), _jsx("p", {
              children: _jsx(FormattedMessage, {
                id: "nJtPtiOujk",
                defaultMessage: [{
                  "type": 0,
                  "value": "Close the selected "
                }, {
                  "type": 6,
                  "value": "nights",
                  "options": {
                    "one": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " night"
                      }]
                    },
                    "other": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " nights"
                      }]
                    }
                  },
                  "offset": 0,
                  "pluralType": "cardinal"
                }, {
                  "type": 0,
                  "value": " for bookings"
                }],
                values: {
                  nights: selectedDates.length
                }
              })
            })]
          })
        })]
      })]
    })]
  });
};