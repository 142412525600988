import { isSameDay } from 'date-fns';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { getDateFromTimestamp, getDateInterval } from '../../utils/date';
import { $NULL_AS_UNDEFINED } from '../../utils/types';
import { useCalendarAsideContext } from './Aside/context';
import { useCalendarSelectionContext } from './context';
import { getEventsWithinRange } from './utils';
export function useDateRangeInfo() {
  const {
    dateConfigurations,
    events,
    minNights
  } = useCalendarAsideContext();
  const {
    selectedDates
  } = useCalendarSelectionContext();

  // as everything in the date configs (apart from date/groupedDates) is the same
  // We can just use the new one, as it should behave he same,
  // as long as our hosts has not yet grouped any dates
  const selectedRangeConfiguration = useMemo(() => (dateConfigurations || []).filter(config => {
    return (selectedDates || []).includes(config.groupedDates.first);
  }), [dateConfigurations, selectedDates]);
  const selectedRangeEvents = useMemo(() => getEventsWithinRange(events, selectedDates[0], selectedDates.slice(-1)[0]), [selectedDates, events]);
  const flattenedEventDates = selectedRangeEvents.flatMap(e => {
    return getDateInterval(e.dateRange.first, e.dateRange.last);
  });
  const isPartiallyBlocked = uniq(flattenedEventDates).length !== selectedRangeConfiguration.length;
  const hasCustomAvailability = useMemo(() => selectedRangeConfiguration.some(({
    customAvailability
  }) => typeof customAvailability === 'boolean'), [selectedRangeConfiguration]);
  const hasCustomMinNights = useMemo(() => selectedRangeConfiguration.some(({
    minNights: _minNights
  }) => _minNights && _minNights !== minNights), [selectedRangeConfiguration]);
  const hasCustomPricing = useMemo(() => selectedRangeConfiguration.some(({
    hasCustomPrice
  }) => hasCustomPrice), [selectedRangeConfiguration]);
  const groups = useMemo(() => selectedRangeConfiguration.map(config => {
    if (config.groupedDates) {
      return !isSameDay(getDateFromTimestamp(config.groupedDates.first), getDateFromTimestamp(config.groupedDates.last));
    }
    return false;
  }).filter(Boolean), [selectedRangeConfiguration]);
  const singleGroup = useMemo(() => selectedRangeConfiguration.length === 1 && groups.length === 1, [selectedRangeConfiguration, groups]);
  const nonGroups = useMemo(() => selectedRangeConfiguration.map(config => {
    if (config.groupedDates) {
      return isSameDay(getDateFromTimestamp(config?.groupedDates.first), getDateFromTimestamp(config?.groupedDates.last));
    }
    return false;
  }).filter(Boolean), [selectedRangeConfiguration]);
  const mixedGroupsAndNonGroups = useMemo(() => groups.length > 0 && nonGroups.length > 0, [nonGroups, groups]);
  const hasInstantBookable = useMemo(() => selectedRangeConfiguration.some(({
    instantBookable
  }) => instantBookable), [selectedRangeConfiguration]);
  const hasMixedInstantBookable = useMemo(() => uniq(selectedRangeConfiguration.map(config => config.instantBookable)).length > 1, [selectedRangeConfiguration]);
  const hasCustomSettings = useMemo(() => hasCustomAvailability || hasCustomMinNights || hasCustomPricing || singleGroup || hasInstantBookable, [hasCustomAvailability, hasCustomMinNights, hasCustomPricing, singleGroup, hasInstantBookable]);
  const hasOnlyCustomPricing = useMemo(() => hasCustomPricing ? uniq((selectedRangeConfiguration || []).filter(({
    hasCustomPrice
  }) => hasCustomPrice)).length === selectedRangeConfiguration.length : false, [hasCustomPricing, selectedRangeConfiguration]);
  const hasMixedCustomPrice = useMemo(() => uniq(selectedRangeConfiguration.map(({
    hasCustomPrice,
    price
  }) => hasCustomPrice ? price?.majorAmount : undefined)).length > 1, [selectedRangeConfiguration]);
  const basePricing = uniq(selectedRangeConfiguration.map(({
    standardPrice
  }) => standardPrice?.majorAmount));
  const hasMixedBasePrice = basePricing.length > 1;
  const hasMixedMinNights = useMemo(() => uniq(selectedRangeConfiguration.map(config => config.minNights)).length > 1, [selectedRangeConfiguration]);
  const notBlocked = useMemo(() => {
    return selectedRangeConfiguration.map(config => {
      if (config.date) {
        return !flattenedEventDates.includes(config.date);
      }
      return false;
    });
  }, [selectedRangeConfiguration, flattenedEventDates]);
  const hasMixedAvailability = useMemo(() => uniq(selectedRangeConfiguration.map(config => {
    const configuredAvailability = config.available || config.customAvailability ||
    // Null as the value for availability means that the availability is set to default
    config.available === $NULL_AS_UNDEFINED;
    return configuredAvailability && notBlocked;
  })).length > 1, [selectedRangeConfiguration]);
  const blockedReasons = useMemo(() => {
    const blockReasons = selectedRangeEvents.map(event => event.reason);
    return blockReasons;
  }, [selectedRangeEvents]);
  const hasMixedBlockedReasons = useMemo(() => uniq(blockedReasons).length > 0 || isPartiallyBlocked, [blockedReasons]);
  const blockedByBookings = useMemo(() => blockedReasons.some(reason => reason === 'BOOKED'), [blockedReasons]);
  const blockedExternally = useMemo(() => blockedReasons.includes('EXTERNALLY_BOOKED'), [blockedReasons]);
  const blockedByPreperation = useMemo(() => blockedReasons.includes('PREPARATION'), [blockedReasons]);
  const blockedByCleaning = useMemo(() => blockedReasons.includes('CLEANING'), [blockedReasons]);
  const blockedByAdvanceNotice = useMemo(() => blockedReasons.includes('ADVANCE_NOTICE'), [blockedReasons]);
  const onlyBlockedByMinNights = useMemo(() => blockedReasons.includes('MIN_NIGHTS') && uniq(blockedReasons).length === 1, [blockedReasons]);
  const hasNoGroups = useMemo(() => groups.length === 0, [groups]);
  const hasMultipleGroups = useMemo(() => groups.length > 1, [groups]);
  return {
    /**
     * Boolean representing if any configuration within the selected date range has custom pricing.
     */
    hasCustomPricing,
    /**
     * Boolean representing whether any of the selected range configurations have
     * custom availability, minimum nights, or pricing.
     */
    hasCustomSettings,
    /**
     * Boolean representing if all configurations within the selected date range have custom pricing.
     */
    hasOnlyCustomPricing,
    /**
     * Boolean indicating if there are different custom prices within the selected date range.
     */
    hasMixedCustomPrice,
    /**
     * Array of (unique) base prices from the selected range configurations.
     */
    basePricing,
    /**
     * Boolean indicating if the base pricing varies within the selected date range.
     */
    hasMixedBasePrice,
    /**
     * Boolean indicating if the minimum nights required for booking varies within the selected date range.
     */
    hasMixedMinNights,
    /**
     * Boolean indicating if the availability varies within the selected date range.
     */
    hasMixedAvailability,
    /**
     * Boolean indicating if the instant bookable configuration varies within the selected date range.
     */
    hasMixedInstantBookable,
    /**
     * Array of blocked reasons from the selected range events.
     */
    blockedReasons,
    /**
     * Boolean representing if there are multiple reasons for blocking within the selected date range.
     */
    hasMixedBlockedReasons,
    /**
     * Boolean representing if the selected date range is blocked due to bookings.
     */
    blockedByBookings,
    /**
     * Boolean indicating if any part of the selected date range is blocked by external bookings.
     */
    blockedExternally,
    /**
     * Boolean representing if any part of the selected date range is blocked due to 'PREPARATION'.
     */
    blockedByPreperation,
    /**
     * Boolean representing if any part of the selected date range is blocked due to 'CLEANING'.
     */
    blockedByCleaning,
    /**
     * Boolean representing if any part of the selected date range is blocked due to 'ADVANCE_NOTICE'.
     */
    blockedByAdvanceNotice,
    /**
     * Boolean representing if the selected date range is blocked due to 'MIN_NIGHTS'.
     */
    onlyBlockedByMinNights,
    /**
     * Boolean representing consits of only one group.
     */
    singleGroup,
    /**
     * Boolean representing if the selected date range has no groups.
     */
    hasNoGroups,
    /**
     *  Boolean representing if the selected date range has multiple groups.
     */
    hasMultipleGroups,
    /**
     * Boolean representing if the selected date range has groups and non-groups.
     */
    mixedGroupsAndNonGroups
  };
}