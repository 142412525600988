function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ReactNode } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1p6eldl",
  styles: "margin-bottom:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "10j88w4-Shadows",
  styles: "margin-bottom:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1cmnu9e",
  styles: "margin-bottom:2rem;max-width:65ch"
} : {
  name: "69dyby-Shadows",
  styles: "margin-bottom:2rem;max-width:65ch;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "15r8e2h",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;}"
} : {
  name: "1oks3ym-Shadows",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;};label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1ahs8x6",
  styles: "height:16rem;max-width:16rem;border-radius:0.125rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 1px 0 rgba(2,32,33,0.56);--tw-shadow-colored:0.4px 0.8px 1px 0 var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
} : {
  name: "1yy0ehh-Shadows",
  styles: "height:16rem;max-width:16rem;border-radius:0.125rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 1px 0 rgba(2,32,33,0.56);--tw-shadow-colored:0.4px 0.8px 1px 0 var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "u955rp-Shadows",
  styles: "margin-top:1rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "3ck8if-Shadows",
  styles: "font-weight:600;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "zjik7",
  styles: "display:flex"
} : {
  name: "1vrafyu-Shadows",
  styles: "display:flex;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "uaob3j",
  styles: "margin-left:0.5rem"
} : {
  name: "u5q8ph-Shadows",
  styles: "margin-left:0.5rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "varz92",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "hxoy4z-Shadows",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "15r8e2h",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;}"
} : {
  name: "1oks3ym-Shadows",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;};label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "p8ayb0",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.13), 0.8px 1.6px 4px 0 rgba(2,32,33,0.13), 1.6px 3.2px 8px 0 rgba(2,32,33,0.13), 0 0 0 1px rgba(2,32,33,0.05);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
} : {
  name: "logcsi-Shadows",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.13), 0.8px 1.6px 4px 0 rgba(2,32,33,0.13), 1.6px 3.2px 8px 0 rgba(2,32,33,0.13), 0 0 0 1px rgba(2,32,33,0.05);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "u955rp-Shadows",
  styles: "margin-top:1rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "3ck8if-Shadows",
  styles: "font-weight:600;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "zjik7",
  styles: "display:flex"
} : {
  name: "1vrafyu-Shadows",
  styles: "display:flex;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "uaob3j",
  styles: "margin-left:0.5rem"
} : {
  name: "u5q8ph-Shadows",
  styles: "margin-left:0.5rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "varz92",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "hxoy4z-Shadows",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "15r8e2h",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;}"
} : {
  name: "1oks3ym-Shadows",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;};label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "10rcyht",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.08), 0.8px 1.6px 4px 0 rgba(2,32,33,0.08), 1.6px 3.2px 8px 0 rgba(2,32,33,0.08), 3.2px 6.4px 16px 0 rgba(2,32,33,0.08), 6.4px 12.8px 32px 0 rgba(2,32,33,0.08), 0 0 0 1px rgba(2,32,33,0.05);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 6.4px 12.8px 32px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
} : {
  name: "6gjav1-Shadows",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.08), 0.8px 1.6px 4px 0 rgba(2,32,33,0.08), 1.6px 3.2px 8px 0 rgba(2,32,33,0.08), 3.2px 6.4px 16px 0 rgba(2,32,33,0.08), 6.4px 12.8px 32px 0 rgba(2,32,33,0.08), 0 0 0 1px rgba(2,32,33,0.05);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 6.4px 12.8px 32px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "u955rp-Shadows",
  styles: "margin-top:1rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "3ck8if-Shadows",
  styles: "font-weight:600;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "zjik7",
  styles: "display:flex"
} : {
  name: "1vrafyu-Shadows",
  styles: "display:flex;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "uaob3j",
  styles: "margin-left:0.5rem"
} : {
  name: "u5q8ph-Shadows",
  styles: "margin-left:0.5rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref23 = process.env.NODE_ENV === "production" ? {
  name: "varz92",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "hxoy4z-Shadows",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref24 = process.env.NODE_ENV === "production" ? {
  name: "15r8e2h",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;}"
} : {
  name: "1oks3ym-Shadows",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;};label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref25 = process.env.NODE_ENV === "production" ? {
  name: "hs2b0w",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 6.4px 12.8px 32px 0 rgba(2,32,33,0.04), 12.8px 25.6px 64px 0 rgba(2,32,33,0.04), 25.6px 51.2px 128px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.05);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 6.4px 12.8px 32px 0 var(--tw-shadow-color), 12.8px 25.6px 64px 0 var(--tw-shadow-color), 25.6px 51.2px 128px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
} : {
  name: "3juwcf-Shadows",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 6.4px 12.8px 32px 0 rgba(2,32,33,0.04), 12.8px 25.6px 64px 0 rgba(2,32,33,0.04), 25.6px 51.2px 128px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.05);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 6.4px 12.8px 32px 0 var(--tw-shadow-color), 12.8px 25.6px 64px 0 var(--tw-shadow-color), 25.6px 51.2px 128px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref26 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "u955rp-Shadows",
  styles: "margin-top:1rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref27 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "3ck8if-Shadows",
  styles: "font-weight:600;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref28 = process.env.NODE_ENV === "production" ? {
  name: "zjik7",
  styles: "display:flex"
} : {
  name: "1vrafyu-Shadows",
  styles: "display:flex;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref29 = process.env.NODE_ENV === "production" ? {
  name: "uaob3j",
  styles: "margin-left:0.5rem"
} : {
  name: "u5q8ph-Shadows",
  styles: "margin-left:0.5rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref30 = process.env.NODE_ENV === "production" ? {
  name: "varz92",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "hxoy4z-Shadows",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref31 = process.env.NODE_ENV === "production" ? {
  name: "15r8e2h",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;}"
} : {
  name: "1oks3ym-Shadows",
  styles: "position:relative;@media (min-width: 64em){margin-top:0.5rem;}@media (max-width: 63.999em){margin-bottom:2rem;};label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref32 = process.env.NODE_ENV === "production" ? {
  name: "wq1wux",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
} : {
  name: "119g75p-Shadows",
  styles: "height:16rem;max-width:16rem;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref33 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "u955rp-Shadows",
  styles: "margin-top:1rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref34 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "3ck8if-Shadows",
  styles: "font-weight:600;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref35 = process.env.NODE_ENV === "production" ? {
  name: "zjik7",
  styles: "display:flex"
} : {
  name: "1vrafyu-Shadows",
  styles: "display:flex;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref36 = process.env.NODE_ENV === "production" ? {
  name: "uaob3j",
  styles: "margin-left:0.5rem"
} : {
  name: "u5q8ph-Shadows",
  styles: "margin-left:0.5rem;label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref37 = process.env.NODE_ENV === "production" ? {
  name: "varz92",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "hxoy4z-Shadows",
  styles: "margin-top:0.25rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Shadows;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function Shadows() {
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "Shadows"
    }), _jsx("p", {
      css: _ref2,
      children: "Shadows make elements stand out. We use a hierarchy of shadows to define the layers of a page. Shadows work well for elements that are always important or temporarily."
    }), _jsxs(Grid, {
      children: [_jsxs("div", {
        css: _ref3,
        children: [_jsx("div", {
          css: _ref4
        }), _jsxs("div", {
          css: _ref5,
          children: [_jsx("div", {
            css: _ref6,
            children: "Tiny"
          }), _jsxs("div", {
            css: _ref7,
            children: ["shadow-xs ", _jsx("div", {
              css: _ref8,
              children: "rounded-sm"
            })]
          }), _jsxs("div", {
            css: _ref9,
            children: ["Used with the ", _jsx("code", {
              children: "Alert"
            }), "-component"]
          })]
        })]
      }), _jsxs("div", {
        css: _ref10,
        children: [_jsx("div", {
          css: _ref11
        }), _jsxs("div", {
          css: _ref12,
          children: [_jsx("div", {
            css: _ref13,
            children: "Small"
          }), _jsxs("div", {
            css: _ref14,
            children: ["shadow-sm ", _jsx("div", {
              css: _ref15,
              children: "rounded"
            })]
          }), _jsx("div", {
            css: _ref16,
            children: "Used for floating elements"
          })]
        })]
      }), _jsxs("div", {
        css: _ref17,
        children: [_jsx("div", {
          css: _ref18
        }), _jsxs("div", {
          css: _ref19,
          children: [_jsx("div", {
            css: _ref20,
            children: "Medium"
          }), _jsxs("div", {
            css: _ref21,
            children: ["shadow-md ", _jsx("div", {
              css: _ref22,
              children: "(rounded)"
            })]
          }), _jsx("div", {
            css: _ref23,
            children: "Used for sticky navigation elements, e.g. tab bar on mobile"
          })]
        })]
      }), _jsxs("div", {
        css: _ref24,
        children: [_jsx("div", {
          css: _ref25
        }), _jsxs("div", {
          css: _ref26,
          children: [_jsx("div", {
            css: _ref27,
            children: "Large"
          }), _jsxs("div", {
            css: _ref28,
            children: ["shadow-lg ", _jsx("div", {
              css: _ref29,
              children: "rounded"
            })]
          }), _jsx("div", {
            css: _ref30,
            children: "Used for elements placed on top of everything else, e.g. modals"
          })]
        })]
      }), _jsxs("div", {
        css: _ref31,
        children: [_jsx("div", {
          css: _ref32
        }), _jsxs("div", {
          css: _ref33,
          children: [_jsx("div", {
            css: _ref34,
            children: "Widget"
          }), _jsxs("div", {
            css: _ref35,
            children: ["shadow-widget ", _jsx("div", {
              css: _ref36,
              children: "rounded"
            })]
          }), _jsx("div", {
            css: _ref37,
            children: "Used for elements that need subtle separation from other content, eg. host dashboard items"
          })]
        })]
      })]
    })]
  });
}
var _ref38 = process.env.NODE_ENV === "production" ? {
  name: "hklmll",
  styles: "margin-bottom:4rem;@media (min-width: 40em){display:grid;gap:2rem;}@media (min-width: 40em) and (max-width: 63.999em){grid-template-columns:repeat(2, minmax(0, 1fr));}@media (min-width: 64em){grid-template-columns:repeat(2, minmax(0, 1fr));}@media (min-width: 80em){grid-template-columns:repeat(4, minmax(0, 1fr));}"
} : {
  name: "5onrme-Grid",
  styles: "margin-bottom:4rem;@media (min-width: 40em){display:grid;gap:2rem;}@media (min-width: 40em) and (max-width: 63.999em){grid-template-columns:repeat(2, minmax(0, 1fr));}@media (min-width: 64em){grid-template-columns:repeat(2, minmax(0, 1fr));}@media (min-width: 80em){grid-template-columns:repeat(4, minmax(0, 1fr));};label:Grid;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const Grid = ({
  children,
  className
}: {
  children: ReactNode;
  className?: string;
}) => _jsx("div", {
  className: className,
  css: _ref38,
  children: children
});