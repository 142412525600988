function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import PillGroup from '@landfolk/shared/components/PillGroup';
import GalleryIcon from '@landfolk/shared/icons/Gallery.svg';
import HeartIcon from '@landfolk/shared/icons/Heart.svg';
import ListIcon from '@landfolk/shared/icons/List.svg';
import MapIcon from '@landfolk/shared/icons/Map.svg';
import { useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1p6eldl",
  styles: "margin-bottom:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "rfjq2n-PillGroupExample",
  styles: "margin-bottom:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:PillGroupExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1cmnu9e",
  styles: "margin-bottom:2rem;max-width:65ch"
} : {
  name: "pv2srg-PillGroupExample",
  styles: "margin-bottom:2rem;max-width:65ch;label:PillGroupExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "jg11g",
  styles: "margin-bottom:0.5rem;font-size:0.875rem"
} : {
  name: "5wijij-PillGroupExample",
  styles: "margin-bottom:0.5rem;font-size:0.875rem;label:PillGroupExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "y451un",
  styles: "margin-bottom:0.5rem;margin-top:2rem;font-size:0.875rem"
} : {
  name: "1vwxtmc-PillGroupExample",
  styles: "margin-bottom:0.5rem;margin-top:2rem;font-size:0.875rem;label:PillGroupExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "y451un",
  styles: "margin-bottom:0.5rem;margin-top:2rem;font-size:0.875rem"
} : {
  name: "1vwxtmc-PillGroupExample",
  styles: "margin-bottom:0.5rem;margin-top:2rem;font-size:0.875rem;label:PillGroupExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "1lqiub1-PillGroupExample",
  styles: "margin-top:1rem;label:PillGroupExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "8juts3-content",
  styles: "margin-right:0.35rem;label:content;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function PillGroupExample() {
  const [value1, setValue1] = useState('list');
  const [value2, setValue2] = useState('1st');
  const [value3, setValue3] = useState('list');
  const [value4, setValue4] = useState('1st');
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "PillGroup"
    }), _jsx("div", {
      css: _ref2,
      children: _jsx("p", {
        children: "PillGroups work similar to Pills in that they are a group of option that users can select. In contrast to Pills, the options in a PillGroup are always exclusive. Use as a tertiary navigation or as a more elaborate switch."
      })
    }), _jsx("h3", {
      css: _ref3,
      children: "Switch between 2"
    }), _jsx(PillGroup, {
      label: "Choose view type",
      value: value1,
      onChange: setValue1,
      options: [{
        value: 'list',
        content: _jsxs(_Fragment, {
          children: [_jsx(ListIcon, {
            css: _ref4
          }), "List"]
        })
      }, {
        value: 'map',
        content: _jsxs(_Fragment, {
          children: [_jsx(MapIcon, {
            css: _ref5
          }), "Map"]
        })
      }]
    }), _jsx("h3", {
      css: _ref6,
      children: "Switch between many"
    }), _jsx(PillGroup, {
      label: "Choose view type",
      value: value2,
      onChange: setValue2,
      options: [{
        value: '1st',
        content: _jsxs(_Fragment, {
          children: [_jsx(ListIcon, {
            css: _ref7
          }), "1st"]
        })
      }, {
        value: '2nd',
        content: _jsxs(_Fragment, {
          children: [_jsx(MapIcon, {
            css: _ref8
          }), "Second"]
        })
      }, {
        value: '3rd',
        content: _jsxs(_Fragment, {
          children: [_jsx(GalleryIcon, {
            css: _ref9
          }), "3rd, but with longer text"]
        })
      }, {
        value: '4th',
        content: _jsxs(_Fragment, {
          children: [_jsx(HeartIcon, {
            css: _ref10
          }), "4th"]
        })
      }]
    }), _jsx("h3", {
      css: _ref11,
      children: "Compact style"
    }), _jsx("div", {
      children: _jsx(PillGroup, {
        compact: true,
        label: "Choose view type",
        value: value3,
        onChange: setValue3,
        options: [{
          value: 'list',
          content: _jsxs(_Fragment, {
            children: [_jsx(ListIcon, {
              css: _ref12
            }), "List"]
          })
        }, {
          value: 'map',
          content: _jsxs(_Fragment, {
            children: [_jsx(MapIcon, {
              css: _ref13
            }), "Map"]
          })
        }]
      })
    }), _jsx("div", {
      css: _ref14,
      children: _jsx(PillGroup, {
        compact: true,
        label: "Choose view type",
        value: value4,
        onChange: setValue4,
        options: [{
          value: '1st',
          content: _jsxs(_Fragment, {
            children: [_jsx(ListIcon, {
              css: _ref15
            }), "1st"]
          })
        }, {
          value: '2nd',
          content: _jsxs(_Fragment, {
            children: [_jsx(MapIcon, {
              css: _ref16
            }), "Second"]
          })
        }, {
          value: '3rd',
          content: _jsxs(_Fragment, {
            children: [_jsx(GalleryIcon, {
              css: _ref17
            }), "3rd, but with longer text"]
          })
        }, {
          value: '4th',
          content: _jsxs(_Fragment, {
            children: [_jsx(HeartIcon, {
              css: _ref18
            }), "4th"]
          })
        }]
      })
    })]
  });
}