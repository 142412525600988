import { eachDayOfInterval, isSameDay } from 'date-fns';
import { uniq } from 'lodash';
import { ComponentPropsWithoutRef, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getDateFromTimestamp, getDateInterval } from '../../../utils/date';
import { getDateTimestamp } from '../../../utils/time';
import { $NULL_AS_UNDEFINED } from '../../../utils/types';
import { useCalendarSelectionContext } from '../context';
import { CalendarEvent, CalendarEventReason, DateTimestamp, ListingDateConfiguration } from '../types';
import { getEventsWithinRange } from '../utils';
import { useCalendarAsideContext } from './context';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type CalendarFormAvailability = CalendarEventReason | 'MIXED' | 'OPEN';
export type CalendarFormData = {
  dates: DateTimestamp[];
  minNights?: number;
  price?: number;
  availability: CalendarFormAvailability;
  clearPrice?: boolean;
  resetToDefaults?: boolean;
  grouped?: boolean;
  instantBookable?: boolean;
};
const availabilityOrderingScheme: CalendarFormAvailability[] = ['MIXED', 'BOOKED', 'EXTERNALLY_BOOKED', 'PREPARATION', 'MIN_NIGHTS', 'CLEANING', 'ADVANCE_NOTICE', 'BLOCKED', 'UNDECIDED', 'OPEN'];
export const getCalendarFormDefaults = (selected: ListingDateConfiguration[], events?: CalendarEvent[], standardMinNights?: number): CalendarFormData => {
  const eventsInSelection = getEventsWithinRange(events, selected[0]?.groupedDates.first, selected.slice(-1)[0]?.groupedDates.first);
  const flattenedEventDates = eventsInSelection.flatMap(e => {
    return getDateInterval(e.dateRange.first, e.dateRange.last);
  });
  const available = selected.map(config => {
    const configuredAvailability = config.available || config.customAvailability ||
    // Null as the value for available equals default availability
    config.available === $NULL_AS_UNDEFINED;
    const notBlocked = !flattenedEventDates.includes(config.groupedDates.first);
    return configuredAvailability && notBlocked;
  });
  const blockedReasons = eventsInSelection.map(event => event.reason).sort((a, b) => availabilityOrderingScheme.indexOf(b) - availabilityOrderingScheme.indexOf(a));
  const mixedAvailability = uniq(available).length > 1;
  const mixedBlockedReasons = uniq(blockedReasons).length > 1;
  let availability: CalendarFormData['availability'] = 'OPEN';
  if (mixedAvailability || mixedBlockedReasons) {
    availability = 'MIXED';
  } else {
    availability =
    // Set availability select when MIN_NIGHTS blocked to 'OPEN' (will dim)
    blockedReasons.filter(reason => reason !== 'MIN_NIGHTS')[0] ?? 'OPEN';
  }
  const minNights = selected.map(config => config.minNights);
  const mixedMinNights = uniq(minNights).length > 1;
  const groups = selected.map(config => !isSameDay(getDateFromTimestamp(config.groupedDates.first), getDateFromTimestamp(config.groupedDates.last))).filter(Boolean);
  const singleGroup = selected.length === 1 && groups.length === 1;
  const instantBookableValues = uniq(selected.map(config => config.instantBookable));
  const selectedDates = selected.flatMap(({
    groupedDates
  }) => eachDayOfInterval({
    start: getDateFromTimestamp(groupedDates.first),
    end: getDateFromTimestamp(groupedDates.last)
  }).map(date => getDateTimestamp(date)));
  const mixedPricing = uniq(selected.map(({
    hasCustomPrice,
    price
  }) => ({
    hasCustomPrice,
    majorAmount: hasCustomPrice ? price?.majorAmount : undefined
  })).map(config => JSON.stringify(config))).length > 1;
  return {
    availability,
    price: !mixedPricing && selected[0]?.price?.majorAmount || $NULL_AS_UNDEFINED,
    dates: selectedDates,
    minNights: !mixedMinNights ? selected[0]?.minNights ?? standardMinNights : $NULL_AS_UNDEFINED,
    ...(singleGroup ? {
      grouped: true
    } : {
      grouped: $NULL_AS_UNDEFINED
    }),
    instantBookable: instantBookableValues.length === 1 && instantBookableValues[0]
  };
};
export default function CalendarFormContainer(props: ComponentPropsWithoutRef<'form'>) {
  const {
    selectedDates
  } = useCalendarSelectionContext();
  const {
    dateConfigurations,
    events,
    minNights
  } = useCalendarAsideContext();
  const selectedRangeConfiguration = useMemo(() => (dateConfigurations || []).filter(config => {
    return (selectedDates || []).includes(config.groupedDates.first);
  }) || [], [dateConfigurations, selectedDates]);
  const methods = useForm<CalendarFormData>({
    defaultValues: getCalendarFormDefaults(selectedRangeConfiguration, events ?? [], minNights)
  });
  useEffect(() => {
    if (selectedRangeConfiguration) {
      methods.reset(getCalendarFormDefaults(selectedRangeConfiguration, events, minNights));
    }
  }, [selectedRangeConfiguration]);
  return _jsx(FormProvider, {
    ...methods,
    children: _jsx("form", {
      ...props
    })
  });
}