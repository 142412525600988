import theme from '@landfolk/styling/utils/theme';
import confetti from 'canvas-confetti';
import { useEffect, useRef } from 'react';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export type ConfettiProps = {
  options?: confetti.Options;
  onComplete?: (confettiKey?: number) => void;
};
export const Confetti = ({
  options,
  onComplete
}: ConfettiProps) => {
  const isMountedRef = useRef(true);
  const optionsRef = useRef(options);
  const onCompleteRef = useRef(onComplete);
  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    const baseOptions: confetti.Options = {
      colors: [theme.colors.honey, theme.colors.winter, theme.colors.spring, theme.colors.summer, theme.colors.fall],
      particleCount: 120,
      spread: 80,
      startVelocity: 70,
      zIndex: 1000,
      disableForReducedMotion: true
    };
    const shotsFired = optionsRef.current ? [confetti({
      ...baseOptions,
      ...optionsRef.current
    })] : [confetti({
      ...baseOptions,
      origin: {
        x: 0,
        y: 1
      },
      angle: 55
    }), confetti({
      ...baseOptions,
      origin: {
        x: 1,
        y: 1
      },
      angle: 125
    })];
    Promise.all(shotsFired ?? []).then(() => {
      if (isMountedRef.current) {
        onCompleteRef.current?.();
      }
    });
  }, []);
  return _jsx(_Fragment, {});
};