function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Badge from '@landfolk/shared/components/Badge';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "1e457bu-BadgeExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1ht7y8k",
  styles: "margin-bottom:1rem;max-width:65ch"
} : {
  name: "1yeeiqm-BadgeExample",
  styles: "margin-bottom:1rem;max-width:65ch;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1fttcpj",
  styles: "display:flex;flex-direction:column"
} : {
  name: "1jjq697-BadgeExample",
  styles: "display:flex;flex-direction:column;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1bxjxkf",
  styles: "margin-bottom:1rem;margin-top:1rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1uouh8u-BadgeExample",
  styles: "margin-bottom:1rem;margin-top:1rem;font-size:1.5rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "e3ndzb",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "1ycxcm-BadgeExample",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "nqx9k7",
  styles: "font-size:1rem;line-height:1.5rem"
} : {
  name: "y0qn5y-BadgeExample",
  styles: "font-size:1rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "e3ndzb",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "1ycxcm-BadgeExample",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "nqx9k7",
  styles: "font-size:1rem;line-height:1.5rem"
} : {
  name: "y0qn5y-BadgeExample",
  styles: "font-size:1rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "e3ndzb",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "1ycxcm-BadgeExample",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "nqx9k7",
  styles: "font-size:1rem;line-height:1.5rem"
} : {
  name: "y0qn5y-BadgeExample",
  styles: "font-size:1rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "e3ndzb",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "1ycxcm-BadgeExample",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "nqx9k7",
  styles: "font-size:1rem;line-height:1.5rem"
} : {
  name: "y0qn5y-BadgeExample",
  styles: "font-size:1rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "e3ndzb",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "1ycxcm-BadgeExample",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "nqx9k7",
  styles: "font-size:1rem;line-height:1.5rem"
} : {
  name: "y0qn5y-BadgeExample",
  styles: "font-size:1rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "e3ndzb",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "1ycxcm-BadgeExample",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "nqx9k7",
  styles: "font-size:1rem;line-height:1.5rem"
} : {
  name: "y0qn5y-BadgeExample",
  styles: "font-size:1rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "e3ndzb",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "1ycxcm-BadgeExample",
  styles: "margin-top:0.75rem;margin-bottom:0.75rem;display:flex;align-items:center;gap:0.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "nqx9k7",
  styles: "font-size:1rem;line-height:1.5rem"
} : {
  name: "y0qn5y-BadgeExample",
  styles: "font-size:1rem;line-height:1.5rem;label:BadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function BadgeExample() {
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "Badge"
    }), _jsx("div", {
      css: _ref2,
      children: _jsx("p", {
        children: "Badges work similarly to CountBadges, but instead of numbers, it highlights using labels. They alert users to certain elements, but also highlight the state of an element."
      })
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsx("h2", {
        css: _ref4,
        children: "Default"
      }), _jsxs("div", {
        css: _ref5,
        children: [_jsx(Badge, {
          children: "Default"
        }), _jsx("p", {
          css: _ref6,
          children: "\u2013 use for neutral labels to highlight an element"
        })]
      }), _jsxs("div", {
        css: _ref7,
        children: [_jsx(Badge, {
          type: "muted",
          children: "Muted"
        }), _jsx("p", {
          css: _ref8,
          children: "\u2013 use for saved states to provide feedback for an action"
        })]
      }), _jsxs("div", {
        css: _ref9,
        children: [_jsx(Badge, {
          type: "success",
          children: "Success"
        }), _jsx("p", {
          css: _ref10,
          children: "\u2013 use to highlight that an element is active"
        })]
      }), _jsxs("div", {
        css: _ref11,
        children: [_jsx(Badge, {
          type: "warning",
          children: "Warning"
        }), _jsx("p", {
          css: _ref12,
          children: "\u2013 use to highlight important things that require an action"
        })]
      }), _jsxs("div", {
        css: _ref13,
        children: [_jsx(Badge, {
          type: "error",
          children: "Error"
        }), _jsx("p", {
          css: _ref14,
          children: "\u2013 use to communicate an error"
        })]
      }), _jsxs("div", {
        css: _ref15,
        children: [_jsx(Badge, {
          type: "alert",
          children: "Alert"
        }), _jsx("p", {
          css: _ref16,
          children: "\u2013 use as a secondary warning"
        })]
      }), _jsxs("div", {
        css: _ref17,
        children: [_jsx(Badge, {
          type: "dark",
          children: "Dark"
        }), _jsx("p", {
          css: _ref18,
          children: "\u2013 use for neutral labels to highlight an element"
        })]
      })]
    })]
  });
}