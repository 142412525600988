import { addDays, areIntervalsOverlapping, differenceInCalendarDays, eachDayOfInterval, endOfMonth as getEndOfMonth, endOfWeek as getEndOfWeek, formatISO, isAfter, isFirstDayOfMonth, isLastDayOfMonth, isSameDay, isSameMonth, max as maxDate, startOfMonth as getStartOfMonth, startOfWeek as getStartOfWeek } from 'date-fns';
import { difference, max, min } from 'lodash';
import { getDateFromTimestamp } from '../../utils/date';
import { getDateTimestamp } from '../../utils/time';
import type { Booking, CalendarConfig, CalendarEvent, DateTimestamp, EventConfig, ExternalEventConfig, ListingDateConfiguration, NightConfig, WeekStartsOn } from './types';
export const TIMESTAMP_TODAY = formatISO(new Date(), {
  representation: 'date'
});
export const getDaysLeftInPeriod = (date: Date, weekStartsOn: WeekStartsOn) => {
  const endOfWeek = getEndOfWeek(date, {
    weekStartsOn
  });
  const endOfMonth = getEndOfMonth(date);
  const daysLeftInWeek = differenceInCalendarDays(endOfWeek, date);
  const daysLeftInMonth = differenceInCalendarDays(endOfMonth, date);
  return Math.min(daysLeftInMonth, daysLeftInWeek) + 1;
};
export const getStartOfPeriod = (date: Date, weekStartsOn: WeekStartsOn) => {
  if (isFirstDayOfMonth(date)) {
    return date;
  }
  const startOfWeek = getStartOfWeek(date, {
    weekStartsOn
  });
  const startOfMonth = getStartOfMonth(date);
  const sameMonth = isSameMonth(startOfMonth, startOfWeek);
  if (sameMonth) {
    return startOfWeek;
  } else {
    return startOfMonth;
  }
};
export const getEndOfPeriod = (date: Date, weekStartsOn: WeekStartsOn) => {
  if (isLastDayOfMonth(date)) {
    return date;
  }
  const endOfWeek = getEndOfWeek(date, {
    weekStartsOn
  });
  const endOfMonth = getEndOfMonth(date);
  const sameMonth = isSameMonth(endOfMonth, endOfWeek);
  if (sameMonth) {
    return endOfWeek;
  } else {
    return endOfMonth;
  }
};
export const isEndOfPeriod = (date: Date, weekStartsOn: WeekStartsOn) => {
  const endOfPeriod = getEndOfPeriod(date, weekStartsOn);
  return isSameDay(date, endOfPeriod);
};
export const isEventConfig = (config: EventConfig | NightConfig): config is EventConfig => (config as EventConfig)?.event !== undefined;
export const isExternalEventConfig = (config: EventConfig | NightConfig): config is ExternalEventConfig => (config as ExternalEventConfig)?.event?.__typename === 'ExternalListingCalendarEvent';
export const isNightConfig = (config: EventConfig | NightConfig): config is NightConfig => (config as NightConfig)?.available !== undefined;
export const createCalendarConfig = ({
  configs,
  events,
  bookings,
  weekStartsOn,
  minNights,
  preparationDays,
  maxLeadMonths,
  advanceNoticeDays
}: {
  configs: ListingDateConfiguration[];
  events: CalendarEvent[];
  bookings: Booking[];
  weekStartsOn: WeekStartsOn;
  minNights?: number;
  preparationDays?: number;
  maxLeadMonths?: number;
  advanceNoticeDays?: number;
}) => {
  const config: CalendarConfig = new Map();
  const eventOrderCache: Map<string, number> = new Map();
  const getEventTrackOrder = (eventId: string, existingConfig: NonNullable<ReturnType<CalendarConfig['get']>>) => {
    // If we already have an existing track order cached, return that.
    // Keeps long events spanning multiple days or weeks on the same
    // track all the way through.
    const existingOrder = eventOrderCache.get(eventId);
    if (existingOrder) {
      return existingOrder;
    }

    // Get all events from existing config (we don't care about nights, since
    // they are always present)
    const eventConfigs = existingConfig.filter(isEventConfig);

    // Get all track orders currently occupied by event sausages
    const ordersInUse = eventConfigs.filter(config => !isExternalEventConfig(config)).map(config => config.trackOrder);

    // Get track order for next track in line if we were to append one
    const hightestTrackOrder = max(eventConfigs.map(config => config.trackOrder + 1)) ?? 0;

    // Get lowest track order not in use
    const smallestUnusedTrackOrder = min(difference([...Array(hightestTrackOrder || 1)].map((_, index) => index), ordersInUse));

    // Set order as smallest possible (fill any unused track) - if not use
    // hightest possible and add a new "track"
    const fallbackOrder = smallestUnusedTrackOrder ?? hightestTrackOrder;
    eventOrderCache.set(eventId, fallbackOrder);
    return fallbackOrder;
  };
  configs.forEach(nightConfig => {
    const numberOfNights = differenceInCalendarDays(getDateFromTimestamp(nightConfig.groupedDates?.first), getDateFromTimestamp(nightConfig.groupedDates?.last)) + 1;
    const {
      groupedDates
    } = nightConfig;
    const date = getDateFromTimestamp(groupedDates.first);
    const lastDate = getDateFromTimestamp(groupedDates.last);
    if (date && lastDate) {
      const interval = eachDayOfInterval({
        start: date,
        // Subtract one from duration so that the end of this interval and
        // the start of the next don't overlap
        end: lastDate
      });
      interval.forEach(d => {
        const timestamp = getDateTimestamp(d);
        const isLastDayInPeriod = isSameDay(getEndOfPeriod(d, weekStartsOn), d);
        const endsBeyondPeriod = isAfter(lastDate, getEndOfPeriod(d, weekStartsOn)) || isSameDay(lastDate, getEndOfPeriod(d, weekStartsOn));
        const nightsLeft = differenceInCalendarDays(lastDate, d) + 1;
        const duration = Math.min(d ? getDaysLeftInPeriod(d, weekStartsOn) : 0, nightsLeft);
        const prevDuration = Math.min(d ? getDaysLeftInPeriod(addDays(d, 1), weekStartsOn) : 0, nightsLeft);
        let position: EventConfig['position'] = 'MIDDLE';
        if (isLastDayInPeriod || endsBeyondPeriod) {
          position = 'END';
        }
        config.set(timestamp, [{
          ...nightConfig,
          date: timestamp,
          groupedDates: {
            first: getDateTimestamp(date),
            last: getDateTimestamp(lastDate)
          },
          standardMinNights: minNights,
          hasCustomMinNights: Boolean(nightConfig.minNights && nightConfig.minNights !== minNights),
          position,
          duration,
          prevDuration,
          nightsLeft,
          numberOfNights
        }]);
      });
    }
  });
  events.map(e => {
    const event: CalendarEvent & {
      numberOfNights: number;
    } = {
      ...e,
      numberOfNights: 0
    };
    if (event.ref) {
      event.booking = bookings.find(b => b.shortId === event.ref);
    }
    if (event.dateRange.first && event.dateRange.last) {
      event.numberOfNights = differenceInCalendarDays(getDateFromTimestamp(event.dateRange.last), getDateFromTimestamp(event.dateRange.first)) + 1;
    }
    return event;
  }).sort((a, b) => {
    return (
      // sort events by date, and if multiple on one date prioritize the events
      // with most nights. This generally results in longer event sausages using
      // the upper tracks and shorter ones filling up below.
      a.dateRange?.first?.localeCompare(b.dateRange?.first || '') || b.numberOfNights - a.numberOfNights
    );
  }).forEach(event => {
    const {
      dateRange,
      numberOfNights
    } = event;
    let date = dateRange.first ? getDateFromTimestamp(dateRange.first) : undefined;
    let nightsLeft = numberOfNights;
    let duration = Math.min(date ? getDaysLeftInPeriod(date, weekStartsOn) : 0, nightsLeft);
    while (nightsLeft > 0 && date) {
      const interval = eachDayOfInterval({
        start: date,
        // Subtract one from duration so that the end of this interval and
        // the start of the next don't overlap
        end: addDays(date, duration - 1)
      });
      interval.forEach((d, ii) => {
        const timestamp = getDateTimestamp(d);
        const existingConfig = config.get(timestamp) || [];
        let position: EventConfig['position'] = ii === 0 ? 'START' : 'MIDDLE';
        if (interval.length - 1 === ii && interval.length !== 1) {
          position = 'END';
        }
        let maxMinNights: number | undefined;
        if (event.reason === 'MIN_NIGHTS') {
          // Calculate the maximum configured min nights for MIN_NIGHTS events only
          // (it's used in the sausage label)
          maxMinNights = minNights && Math.max(...interval.map(d => {
            const currConfig = config.get(getDateTimestamp(d))?.find(isNightConfig);
            return currConfig?.minNights ?? minNights;
          }).filter(v => typeof v === 'number'));
        }
        let standardPreparationDays: number | undefined;
        if (event.reason === 'PREPARATION') {
          standardPreparationDays = preparationDays;
        }
        let standardMaxLeadMonths: number | undefined;
        if (event.reason === 'UNDECIDED') {
          standardMaxLeadMonths = maxLeadMonths;
        }
        let standardAdvanceNoticeDays: number | undefined;
        if (event.reason === 'ADVANCE_NOTICE') {
          standardAdvanceNoticeDays = advanceNoticeDays;
        }
        config.set(timestamp, [...existingConfig, {
          date: timestamp,
          duration,
          nightsLeft: nightsLeft - ii,
          position,
          trackOrder: getEventTrackOrder(event.id, existingConfig),
          event,
          ...(maxMinNights ? {
            maxMinNights
          } : {}),
          ...(typeof standardPreparationDays !== 'undefined' ? {
            standardPreparationDays
          } : {}),
          ...(typeof standardMaxLeadMonths !== 'undefined' ? {
            standardMaxLeadMonths
          } : {}),
          ...(typeof standardAdvanceNoticeDays !== 'undefined' ? {
            standardAdvanceNoticeDays
          } : {})
        }]);
      });
      nightsLeft = nightsLeft - duration;
      date = getStartOfPeriod(addDays(date, duration), weekStartsOn);
      duration = Math.min(getDaysLeftInPeriod(date, weekStartsOn), nightsLeft);
    }
  });
  return config;
};

// A night range will always be at least two days, since it spans across date lines.
// This function converts a regular date range to a range of nights
export const convertDateRangeToNightRange = (from?: DateTimestamp, to?: DateTimestamp) => {
  const fromDate = from ? new Date(from) : undefined;
  const toDate = to ? new Date(to) : undefined;
  const dateRange = {
    from: fromDate,
    to: fromDate ? toDate ?? fromDate : undefined
  };
  if (dateRange.to) {
    dateRange.to = addDays(dateRange.to, 1);
    if (!dateRange.from) {
      dateRange.from = addDays(dateRange.to, -1);
    }
  }
  return {
    from: dateRange.from ? getDateTimestamp(dateRange.from) : undefined,
    fromDate: dateRange.from,
    to: dateRange.to ? getDateTimestamp(dateRange.to) : undefined,
    toDate: dateRange.to
  };
};

// A night range will always be at least two days
export const convertNightRangeToDateRange = (from?: DateTimestamp, to?: DateTimestamp) => {
  const fromDate = from ? new Date(from) : undefined;
  const toDate = to ? new Date(to) : undefined;
  const dateRange = {
    from: fromDate,
    to: fromDate ? toDate ?? fromDate : undefined
  };
  if (dateRange.to) {
    dateRange.to = dateRange.from ? maxDate([dateRange.from, addDays(dateRange.to, -1)]) : addDays(dateRange.to, -1);
  }
  return {
    from: dateRange.from ? getDateTimestamp(dateRange.from) : undefined,
    fromDate: dateRange.from,
    to: dateRange.to ? getDateTimestamp(dateRange.to) : undefined,
    toDate: dateRange.to
  };
};
export const getEventsWithinRange = (events?: CalendarEvent[], start?: DateTimestamp, end?: DateTimestamp) => (events ?? []).filter(event => {
  const eventStart = event.dateRange.first ? getDateFromTimestamp(event.dateRange.first) : undefined;
  const eventEnd = event.dateRange.last ? getDateFromTimestamp(event.dateRange.last) : undefined;
  if (!eventStart || !eventEnd) {
    return false;
  }
  const selection = {
    first: start,
    last: end ?? start
  };
  const selectStart = selection.first ? getDateFromTimestamp(selection.first) : undefined;
  const selectEnd = selection.last ? getDateFromTimestamp(selection.last) : undefined;
  if (!selectStart || !selectEnd) {
    return false;
  }
  return areIntervalsOverlapping({
    start: selectStart,
    end: selectEnd
  }, {
    start: eventStart,
    end: eventEnd
  }, {
    inclusive: true
  });
});