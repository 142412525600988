function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Play from '../../../icons/Play.svg';
import { EditButton } from '../../EditButton';
import LinkButton from '../../LinkButton';
import { useCalendarAsideContext } from './context';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
type CalendarDetailsProps = ComponentPropsWithoutRef<'div'>;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1abwlhx",
  styles: "margin-bottom:1rem;font-size:1.5rem;line-height:1.5rem;font-weight:600"
} : {
  name: "16eano7-CalendarDetails",
  styles: "margin-bottom:1rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;label:CalendarDetails;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1glwpwj-CalendarDetails",
  styles: "margin-bottom:1rem;label:CalendarDetails;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "hnz2w3-CalendarDetails",
  styles: "font-weight:600;label:CalendarDetails;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "qjho7n",
  styles: "display:flex;align-items:flex-start;text-align:left"
} : {
  name: "157flkv-CalendarDetails",
  styles: "display:flex;align-items:flex-start;text-align:left;label:CalendarDetails;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1uh59ow",
  styles: "margin-right:0.5rem;margin-top:0.25rem;height:1rem;width:1rem"
} : {
  name: "15wf6hh-CalendarDetails",
  styles: "margin-right:0.5rem;margin-top:0.25rem;height:1rem;width:1rem;label:CalendarDetails;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "v6tde3",
  styles: "margin-top:2rem;border-bottom-width:1px;--tw-border-opacity:1;border-bottom-color:rgb(204 210 210 / var(--tw-border-opacity));padding-bottom:1.5rem;font-size:1.5rem;line-height:1.5rem;font-weight:600"
} : {
  name: "41htsm-CalendarDetails",
  styles: "margin-top:2rem;border-bottom-width:1px;--tw-border-opacity:1;border-bottom-color:rgb(204 210 210 / var(--tw-border-opacity));padding-bottom:1.5rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;label:CalendarDetails;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function CalendarDetails(props: CalendarDetailsProps) {
  const {
    onClickCalendarHelp
  } = useCalendarAsideContext();
  return _jsxs("div", {
    ...props,
    children: [_jsx("h2", {
      css: _ref,
      children: _jsx(FormattedMessage, {
        id: "Qz9Z4tiQqL",
        defaultMessage: [{
          "type": 0,
          "value": "Quick start"
        }]
      })
    }), _jsx("p", {
      css: _ref2,
      children: _jsx(FormattedMessage, {
        id: "NmV+99zxxk",
        defaultMessage: [{
          "type": 0,
          "value": "Select a date or period to get started. To select a period, click on the first and last date of the period."
        }]
      })
    }), onClickCalendarHelp && _jsxs(_Fragment, {
      children: [_jsx("h3", {
        css: _ref3,
        children: _jsx(FormattedMessage, {
          id: "m9FnwJkQYQ",
          defaultMessage: [{
            "type": 0,
            "value": "Unsure how to use the calendar?"
          }]
        })
      }), _jsx("p", {
        children: _jsxs(LinkButton, {
          css: _ref4,
          type: "button",
          onClick: onClickCalendarHelp,
          children: [_jsx(Play, {
            css: _ref5
          }), _jsx(FormattedMessage, {
            id: "8ZWvgSatyG",
            defaultMessage: [{
              "type": 0,
              "value": "Learn how the calendar works"
            }]
          })]
        })
      })]
    }), _jsx("h2", {
      css: _ref6,
      children: _jsx(FormattedMessage, {
        id: "RnnLfTXsWK",
        defaultMessage: [{
          "type": 0,
          "value": "Settings"
        }]
      })
    }), _jsx(CalendarSettingItems, {})]
  });
}
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1r8jpvc",
  styles: "display:flex;width:100%;align-items:center;justify-content:space-between;border-bottom-width:1px;--tw-border-opacity:1;border-bottom-color:rgb(204 210 210 / var(--tw-border-opacity));padding-top:1.5rem;padding-bottom:1.5rem"
} : {
  name: "1i6tn91-CalendarSetting",
  styles: "display:flex;width:100%;align-items:center;justify-content:space-between;border-bottom-width:1px;--tw-border-opacity:1;border-bottom-color:rgb(204 210 210 / var(--tw-border-opacity));padding-top:1.5rem;padding-bottom:1.5rem;label:CalendarSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1fttcpj",
  styles: "display:flex;flex-direction:column"
} : {
  name: "kd56e4-CalendarSetting",
  styles: "display:flex;flex-direction:column;label:CalendarSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "ypidu8-CalendarSetting",
  styles: "font-weight:600;label:CalendarSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const CalendarSetting = ({
  title,
  subtitle,
  link
}: {
  title: ReactNode;
  subtitle: ReactNode;
  link?: string;
}) => _jsxs("li", {
  css: _ref7,
  children: [_jsxs("div", {
    css: _ref8,
    children: [_jsx("span", {
      css: _ref9,
      children: title
    }), _jsx("span", {
      children: subtitle
    })]
  }), link && _jsx(EditButton, {
    href: link
  })]
});
function CalendarSettingItems() {
  const {
    maxLeadMonths,
    links,
    advancedNoticeDays,
    preparationDays,
    externalCalendars,
    instantBookingAsDefault
  } = useCalendarAsideContext();
  const calendarSettingItems = [{
    title: _jsx(FormattedMessage, {
      id: "kxo1RSXCIw",
      defaultMessage: [{
        "type": 0,
        "value": "Instant booking"
      }]
    }),
    subtitle: instantBookingAsDefault ? _jsx(FormattedMessage, {
      id: "an49r8428F",
      defaultMessage: [{
        "type": 0,
        "value": "Always on"
      }]
    }) : _jsx(FormattedMessage, {
      id: "dErLYQ38II",
      defaultMessage: [{
        "type": 0,
        "value": "Only selected periods"
      }]
    }),
    link: links?.instantBooking && links.instantBooking
  }, {
    title: _jsx(FormattedMessage, {
      id: "v5H5MqeS0j",
      defaultMessage: [{
        "type": 0,
        "value": "Preparation time"
      }]
    }),
    subtitle: _jsx(FormattedMessage, {
      id: "mfphdKNQOH",
      defaultMessage: [{
        "type": 6,
        "value": "value",
        "options": {
          "one": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " night"
            }]
          },
          "other": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " nights"
            }]
          }
        },
        "offset": 0,
        "pluralType": "cardinal"
      }],
      values: {
        value: preparationDays
      }
    }),
    link: links?.availability && links.availability
  }, {
    title: _jsx(FormattedMessage, {
      id: "A8GijSE5hD",
      defaultMessage: [{
        "type": 0,
        "value": "Advance notice"
      }]
    }),
    subtitle: _jsx(FormattedMessage, {
      id: "f4E1EgyCx5",
      defaultMessage: [{
        "type": 6,
        "value": "value",
        "options": {
          "one": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " night"
            }]
          },
          "other": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " nights"
            }]
          }
        },
        "offset": 0,
        "pluralType": "cardinal"
      }],
      values: {
        value: advancedNoticeDays
      }
    }),
    link: links?.availability && links.availability
  }, {
    title: _jsx(FormattedMessage, {
      id: "6D63RS1Djv",
      defaultMessage: [{
        "type": 0,
        "value": "Availability window"
      }]
    }),
    subtitle: _jsx(FormattedMessage, {
      id: "0OVrFiSKph",
      defaultMessage: [{
        "type": 6,
        "value": "value",
        "options": {
          "one": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " month"
            }]
          },
          "other": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " months"
            }]
          }
        },
        "offset": 0,
        "pluralType": "cardinal"
      }],
      values: {
        value: maxLeadMonths
      }
    }),
    link: links?.availability && links.availability
  }, {
    title: _jsx(FormattedMessage, {
      id: "42cDewcN5E",
      defaultMessage: [{
        "type": 0,
        "value": "Synchronised calendars"
      }]
    }),
    subtitle: externalCalendars?.length === 0 ? _jsx(FormattedMessage, {
      id: "YsZ/k/IZJT",
      defaultMessage: [{
        "type": 0,
        "value": "None"
      }]
    }) : _jsx(FormattedMessage, {
      id: "ut4TXVjMbn",
      defaultMessage: [{
        "type": 6,
        "value": "count",
        "options": {
          "one": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " calendar"
            }]
          },
          "other": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " calendars"
            }]
          }
        },
        "offset": 0,
        "pluralType": "cardinal"
      }],
      values: {
        count: externalCalendars?.length
      }
    }),
    link: links?.availability && links.availability + '#calendar-synchronisation'
  }];
  return _jsx("ul", {
    children: calendarSettingItems.map((item, i) => _jsx(CalendarSetting, {
      title: item.title,
      subtitle: item.subtitle,
      link: item.link
    }, i))
  });
}