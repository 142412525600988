import { useCallback, useEffect, useRef } from 'react';
import { $ANY, $NULL } from './types';
export function useScrollCallbackOnce(callback: () => void, dependencies: $ANY[]) {
  const callbackRef = useRef(callback);
  const scrollHandlerRef = useRef<(() => void) | $NULL>($NULL);

  // Update callback ref if dependencies change
  useEffect(() => {
    callbackRef.current = callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...dependencies]);

  // Define scroll event listener
  const setScrollHandler = useCallback(() => {
    if (scrollHandlerRef.current) {
      window.removeEventListener('scroll', scrollHandlerRef.current);
    }
    const handleScroll = () => {
      if (callbackRef.current) {
        callbackRef.current();
      }
      // Remove the event listener after the callback has been called
      window.removeEventListener('scroll', handleScroll);
    };
    scrollHandlerRef.current = handleScroll;
    window.addEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    // Re-attach the scroll event listener when dependencies change
    setScrollHandler();

    // Cleanup function to remove the event listener
    return () => {
      if (scrollHandlerRef.current) {
        window.removeEventListener('scroll', scrollHandlerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies]);

  // Ensure that when the hook unmounts, the event listener is removed
  useEffect(() => {
    return () => {
      if (scrollHandlerRef.current) {
        window.removeEventListener('scroll', scrollHandlerRef.current);
      }
    };
  }, []);
}