function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Pill from '@landfolk/shared/components/Pill';
import Popover from '@landfolk/shared/components/Popover';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "17vrlm1-PopoverExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1xpypnc-PopoverExample",
  styles: "max-width:65ch;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1z0jrqp-PopoverExample",
  styles: "margin-bottom:1rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "4tdjc6-PopoverExample",
  styles: "margin-bottom:2rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "hltamm",
  styles: "margin-top:2rem"
} : {
  name: "g7icky-PopoverExample",
  styles: "margin-top:2rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "i7l02c",
  styles: "max-width:45rem;@media (max-width: 39.999em){width:100vw;}@media (min-width: 64em){min-width:32rem;}"
} : {
  name: "1ohcrdc-PopoverExample",
  styles: "max-width:45rem;@media (max-width: 39.999em){width:100vw;}@media (min-width: 64em){min-width:32rem;};label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1e4sizb",
  styles: "margin-top:0.5rem"
} : {
  name: "zcebo-PopoverExample",
  styles: "margin-top:0.5rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1e4sizb",
  styles: "margin-top:0.5rem"
} : {
  name: "zcebo-PopoverExample",
  styles: "margin-top:0.5rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1e4sizb",
  styles: "margin-top:0.5rem"
} : {
  name: "zcebo-PopoverExample",
  styles: "margin-top:0.5rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "1e4sizb",
  styles: "margin-top:0.5rem"
} : {
  name: "zcebo-PopoverExample",
  styles: "margin-top:0.5rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1wk9v1j",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem"
} : {
  name: "1q8e5ly-PopoverExample",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "iyyzlo",
  styles: "margin-top:4rem"
} : {
  name: "15zwafd-PopoverExample",
  styles: "margin-top:4rem;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "1kpbd8q",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;}"
} : {
  name: "95szcj-PopoverExample",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;};label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "1vjxmwf",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc"
} : {
  name: "1g0yhnj-PopoverExample",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc;label:PopoverExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function PopoverExample() {
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "Popover"
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "The popover component will launch a small anchored popup on larger devices and a full screen takeover on smaller devices. It is primarily used for filters, dialogs and wizards."
      }), _jsx("p", {
        css: _ref4,
        children: "Popovers can contain most components."
      }), _jsx("div", {
        css: _ref5,
        children: _jsx(Popover, {
          css: _ref6,
          title: "This is a simple popover",
          content: _jsxs(_Fragment, {
            children: [_jsx("p", {
              children: "It can have content of various kinds. Here is some text for instance:"
            }), _jsx("p", {
              css: _ref7,
              children: "Landfolk is one of Denmark's most ambitious startups. Our mission is to help people get out of town. Closer to nature and each other."
            }), _jsx("p", {
              css: _ref8,
              children: "We do that by finding and handpicking the most wonderful and unique holiday homes every day. We believe aesthetics, quality, scenic locations and personality are important parts of any vacation and getaway."
            }), _jsx("p", {
              css: _ref9,
              children: "Not only are our holiday homes places you would not otherwise have access to. Our ambition is also to make it the most delightful experience to find and book them on our platform."
            }), _jsx("p", {
              css: _ref10,
              children: "At the same time, we create a community for our hosts, where we make it easy, fun and inspiring to be a holiday home owner."
            })]
          }),
          children: _jsx(Pill, {
            css: _ref11,
            type: "button",
            children: "Click to activate"
          })
        })
      }), _jsxs("section", {
        css: _ref12,
        children: [_jsx("h3", {
          css: _ref13,
          children: "Best practices"
        }), _jsxs("ul", {
          css: _ref14,
          children: [_jsx("li", {
            children: "Do not cover information with the popover that is important for interacting with the popover"
          }), _jsx("li", {
            children: "Avoid packing so much information into the popover that users need to scroll on large devices, unless the content is user-generated"
          }), _jsx("li", {
            children: "Include one main action, or group of actions, per popover"
          }), _jsx("li", {
            children: "Split the dialog into a wizard if the user needs to take more actions"
          }), _jsx("li", {
            children: "Add confirmation once the user has completed the action"
          })]
        })]
      })]
    })]
  });
}