import { ChangeEvent, ComponentProps, FocusEvent, MutableRefObject, Ref, useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useMergeRefs } from 'rooks';
import { $ANY, $NULL } from './types';
type Field = 'checkbox' | 'email' | 'hidden' | 'number' | 'password' | 'radio' | 'text' | 'date' | 'tel' | 'time';
type useMergedFieldRefProps = {
  type?: Field;
  name?: string;
  setValueAs?: (value: $ANY) => $ANY;
  register?: UseFormRegister<$ANY>;
  fieldRef?: Ref<HTMLInputElement>;
  required?: ComponentProps<'input'>['required'];
  validation?: {
    pattern: {
      value: RegExp;
      message: string;
    };
  };
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
} | {
  type?: 'textarea';
  name?: string;
  setValueAs?: (value: $ANY) => $ANY;
  register?: UseFormRegister<$ANY>;
  fieldRef?: Ref<HTMLTextAreaElement>;
  required?: ComponentProps<'textarea'>['required'];
  validation?: {
    pattern: {
      value: RegExp;
      message: string;
    };
  };
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
};
export function useMergedFieldRef({
  setValueAs,
  register,
  required,
  validation,
  fieldRef,
  type,
  name,
  onChange,
  onBlur
}: useMergedFieldRefProps) {
  const {
    ref: reactHookFormRef,
    ...reactHookFormRegister
  } = register && name ? register(name, {
    required,
    ...validation,
    onChange,
    onBlur,
    setValueAs: setValueAs ?? getSetValueAsForType(type)
  }) : {
    ref: undefined
  };
  const localFieldRef = (useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>);
  const mergedRef = useMergeRefs(localFieldRef, (fieldRef as MutableRefObject<HTMLInputElement>), reactHookFormRef ? reactHookFormRef : () => undefined);
  return {
    reactHookFormRegister,
    mergedRef,
    localFieldRef
  };
}
function getSetValueAsForType(type: useMergedFieldRefProps['type']) {
  switch (type) {
    case 'date':
      return (val: string | $NULL) => {
        if (val === $NULL) {
          return $NULL;
        }
        if (val && val.length) {
          return new Date(val);
        }
        return undefined;
      };
    case 'number':
      return (val: string | number | $NULL) => {
        if (typeof val === 'number' || val === $NULL) {
          return val ?? $NULL;
        }
        if (typeof val === 'string') {
          if (val.length === 0) {
            return $NULL;
          } else {
            return Number(val);
          }
        }
        return undefined;
      };
    default:
      return undefined;
  }
}