import { addDays, isBefore, max as maxDate } from 'date-fns';
import { intersection, max, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { getDateFromTimestamp, getDateInterval } from '../../utils/date';
import { getDateTimestamp } from '../../utils/time';
import { DateTimestamp } from './types';
export type DateAvailabilityInfo = {
  available: boolean;
  date: string;
  instantBookable: boolean;
  minNights: number;
  group?: {
    first?: DateTimestamp;
    last?: DateTimestamp;
  };
};
export type DateAvailability = DateAvailabilityInfo[];
export type DateData = {
  available: boolean;
  date: Date;
  instantBookable: boolean;
  minNights?: number;
  group?: DateAvailabilityInfo['group'];
  blocked: boolean;
  checkinDisabled: boolean;
  checkoutDisabled: boolean;
};
const MAX_MIN_NIGHTS = 7;
export function useAvailability(availability?: DateAvailability, listingMinNights?: number) {
  const _dates = useMemo(() => {
    if (!availability) return undefined;

    // Calculate actual minNights based on adjusting as needed for groups & minNights constraints
    const calculateMinNights = (startIndex: number, minNightsFromConfig: number | undefined, accumulatedMinNights = 0, maxLookahead = 21): number => {
      const baseMinNights = minNightsFromConfig ?? listingMinNights ?? 1;
      const currentMinNights = Math.max(baseMinNights, accumulatedMinNights);

      // Respect the maximum lookahead limit based on the current minimum nights and known constraints
      const endIndex = Math.min(availability.length, startIndex + currentMinNights, startIndex + maxLookahead);
      const dateRange = availability.slice(startIndex + 1, endIndex);
      for (let i = 0; i < dateRange.length; ++i) {
        const nextDateInfo = dateRange[i];
        if (nextDateInfo?.group?.last) {
          const extendedMinNights = availability.findIndex(d => d.date === nextDateInfo?.group?.last) - startIndex + 1;
          if (extendedMinNights > currentMinNights) {
            // Recursively calculate min nights with the extended range, based on group last date or a larger minNights value
            return calculateMinNights(startIndex, undefined, extendedMinNights, maxLookahead);
          }
        } else if (nextDateInfo?.minNights && nextDateInfo.minNights > currentMinNights) {
          // Recursively calculate min nights with the new larger minNights value
          return calculateMinNights(startIndex, undefined, nextDateInfo.minNights, maxLookahead);
        }
      }
      return currentMinNights;
    };
    const datesList = availability.map((d, index, arr) => {
      const date = getDateFromTimestamp(d.date);
      const prevD = arr[index - 1];
      const isGroupCheckin = Boolean(d.group && d.group.first === d.date);
      const isGroupCheckout = Boolean(prevD?.group && prevD.group.last === prevD.date);

      // Set base checkin is disabled based on availability
      let checkinDisabled = !d.available;
      // Then take groups or min nights into consideration
      if (!checkinDisabled) {
        if (d.group) {
          // Determine if checkin is disabled based on grouping
          checkinDisabled = !isGroupCheckin || !d.available;
        } else {
          // Determine if checkin is disabled based on calculated min nights
          // ahead in time
          const calculatedMinNights = calculateMinNights(index, d.minNights);
          for (let i = 1; i < calculatedMinNights; ++i) {
            if (index + i >= availability.length || !availability[index + i]?.available) {
              checkinDisabled = true;
              break;
            }
          }
        }
      }

      // Consider date blocked if it is not available - and if previous wasn't either
      // (all to allow for checkout days _not_ to be blocked entirely)
      const blocked = Boolean(!d.available && !prevD?.available);

      // Disable checkout if date is blocked
      let checkoutDisabled = blocked;
      // Then check if in group and disallow checkout on all days that aren't
      // group checkout days
      if (!checkoutDisabled && d?.group) {
        checkoutDisabled = !isGroupCheckout && !isGroupCheckin;
      }
      return {
        index,
        timestamp: d.date,
        date,
        available: d.available,
        minNights: !d.group ? d.minNights : undefined,
        instantBookable: d.instantBookable,
        group: d.group,
        blocked,
        checkinDisabled,
        checkoutDisabled
      };
    });
    return datesList;
  }, [availability, listingMinNights]);
  const data = useMemo(() => {
    if (!_dates) return undefined;
    return _dates.reduce((obj, d) => {
      obj[d.timestamp] = (pick(d, ['date', 'available', 'minNights', 'instantBookable', 'group', 'blocked', 'checkinDisabled', 'checkoutDisabled']) as DateData);
      return obj;
    }, ({} as {
      [k: DateTimestamp]: DateData;
    }));
  }, [_dates]);
  const unblockedDates = useMemo(() => {
    if (!_dates) return undefined;
    return _dates.filter(d => d.available).map(d => d.timestamp);
  }, [_dates]);

  /**
   * Returns availability data for the specified timestamp
   */
  const getAvailabilityFor = useCallback((timestamp: DateTimestamp) => {
    if (!data) return undefined;
    const date = data[timestamp];
    if (!date) return undefined;
    return date;
  }, [data]);

  /**
   * Returns availability data for all available dates into the future from
   * given checkin timestamp
   */
  const getAvailableRangeFromCheckin = useCallback((timestamp: DateTimestamp) => {
    // Return nothing if availability isn't loaded
    if (!_dates) return undefined;
    return takeWhileInclusive(
    // Do not include days before checkin
    _dates.filter(d => !isBefore(d.date, getDateFromTimestamp(timestamp))),
    // Include all available days in future (up to, and including, first unavailable date)
    d => d.available);
  }, [_dates]);

  /**
   * Check availability for an interval of dates
   */
  const checkIntervalAvailability = useCallback((start: DateTimestamp, end: DateTimestamp) => {
    // If no availability is configured, just assume interval is available
    if (!_dates) {
      return true;
    }
    const interval = getDateInterval(start, end);

    // If availability is configured check that no dates in interval are blocked
    // (except from last date in interval - checkout is always allowed)
    return intersection(interval.slice(0, -1), unblockedDates).length === interval.length - 1;
  }, [_dates, unblockedDates]);
  const checkIntervalIsValid = useCallback((start?: DateTimestamp, end?: DateTimestamp) => {
    // Use min nights on checkin date as fallback. If no date is available, use 1
    const minNightsFallback = start ? getAvailabilityFor(start)?.minNights ?? listingMinNights ?? 1 : 1;

    // If no availability is configured, just assume interval is available
    if (!_dates || !start || !end) {
      !_dates && console.warn('`checkIntervalIsValid` ran without availability data. Defaulting to true. Avoid false positives by only running `checkIntervalIsValid` when isAvailabilityReady is true.');
      const selectionLength = start && end ? Math.max(0, getDateInterval(start, end).length - 1) : 0;
      return {
        valid: selectionLength ? selectionLength >= minNightsFallback : true,
        minNights: minNightsFallback
      };
    }
    const lastNight = getDateTimestamp(addDays(getDateFromTimestamp(end), -1));
    const interval = getDateInterval(start, lastNight);
    const startInfo = getAvailabilityFor(start);
    const lastNightInfo = getAvailabilityFor(lastNight); // Get info for the night before end (last booked night)
    let valid = true;

    // Validate if start or end is within a group range, and if so, whether the entire group is selected
    if (startInfo?.group?.first && startInfo.group.last) {
      const groupFirst = startInfo.group.first;
      const groupLast = startInfo.group.last;
      valid = interval.includes(groupFirst) && interval.includes(groupLast);
    }

    // If the end night is part of a group, ensure we're not ending the stay in the middle of a group
    if (lastNightInfo?.group?.last) {
      valid = valid && interval.includes(lastNightInfo.group.last);
    }

    // If not part of a group range, check minNights
    if (valid && (!startInfo?.group || !lastNightInfo?.group)) {
      const minNightsRequired = max(interval.map(t => getAvailabilityFor(t)?.minNights ?? 1)) ?? 1;
      valid = interval.length >= minNightsRequired;
    }
    return {
      valid,
      minNights: valid ? 1 : listingMinNights || 1
    };
  }, [_dates, getAvailabilityFor, listingMinNights]);

  /**
   * Returns earliest allowed checkout date based on checkin (and optional checkout)
   *
   * Recursively evaluates all min night settings from checkin to checkout to get
   * the earliest allowed date (can of course be _after_ optional checkout if the
   * range is invalid)
   */
  const getEarliestCheckoutHint = useCallback((checkin: DateTimestamp, optionalCheckout?: DateTimestamp): Date | undefined => {
    const checkinDate = getDateFromTimestamp(checkin);

    // This Function checks if the interval from the checkin up to a certain date is valid
    // and recursively updates the hint date until it satisfies the minNights requirements.
    const findHintDate = (hintDate: Date, iterationsLeft: number = MAX_MIN_NIGHTS): Date | undefined => {
      if (iterationsLeft <= 0) return undefined; // Max iterations reached

      const lastNightToCheck = getDateTimestamp(addDays(hintDate, -1));
      const interval = getDateInterval(checkin, lastNightToCheck);
      if (interval.length === 0) {
        return undefined; // Invalid range
      }

      const lastNightInfo = getAvailabilityFor(lastNightToCheck);
      const lastNightHintDate = lastNightInfo?.group?.last ? addDays(getDateFromTimestamp(lastNightInfo.group.last), 1) : checkinDate;
      const maxMinNights = max(interval.map(t => getAvailabilityFor(t)?.minNights));

      // Get the hint date farthest into the future, either via min nights or
      // grouped nights
      const newHintDate = maxDate([addDays(checkinDate, maxMinNights ?? listingMinNights ?? 0), lastNightHintDate]);
      if (getDateTimestamp(newHintDate) === getDateTimestamp(hintDate)) {
        // If the hint date hasn't changed, it has stabilized and we can return it.
        return hintDate;
      }

      // Check if the entire new range up to the new hint date is available,
      // and if not, return undefined to indicate no valid checkout date found.
      if (checkIntervalAvailability(checkin, getDateTimestamp(newHintDate))) {
        // We found a valid range, but we need to confirm it doesn't change with further recursion.
        return findHintDate(newHintDate, iterationsLeft - 1);
      }
      return undefined;
    };

    // Calculate the initial hintDate based on the checkin date, or use the provided optionalCheckout date.
    const initialHintDate = optionalCheckout ? maxDate([getDateFromTimestamp(optionalCheckout), addDays(checkinDate, 1) // Always start at least at night after checkout
    ]) : addDays(checkinDate, getAvailabilityFor(checkin)?.minNights ?? 1);
    return findHintDate(initialHintDate); // Start the recursion with the initial hintDate.
  }, [getAvailabilityFor, listingMinNights, checkIntervalAvailability]);
  return {
    isAvailabilityReady: !!availability && !!data,
    data,
    checkIntervalAvailability,
    checkIntervalIsValid,
    getAvailabilityFor,
    getAvailableRangeFromCheckin,
    getEarliestCheckoutHint
  };
}

/**
 * Like lodash `takeWhile`, but includes the last element that triggered
 * the predicate callback.
 */
function takeWhileInclusive<T>(array: T[], predicate: (value: T, index: number, array: T[]) => boolean): T[] {
  const result: T[] = [];
  let lastIndex = -1;
  for (let i = 0; i < array.length; i++) {
    if (!predicate((array[i] as T), i, array)) {
      lastIndex = i;
      break;
    }
    result.push((array[i] as T));
  }
  if (lastIndex >= 0 && lastIndex < array.length) {
    result.push((array[lastIndex] as T));
  }
  return result;
}