function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrowLeft from '../../../../../icons/ArrowLeft.svg';
import { $NULL_AS_UNDEFINED } from '../../../../../utils/types';
import { Checked, CHECKED_INDETERMINATE } from '../../../../forms/CheckRadioField';
import NumberField from '../../../../forms/NumberField';
import Notification from '../../../../Notification';
import { TextSwitch } from '../../../../TextSwitch';
import { useCalendarSelectionContext } from '../../../context';
import { useDateRangeInfo } from '../../../useDateRangeInfo';
import { useCalendarAsideContext } from '../../context';
import { ViewHeading } from '../../shared';
import { DateConfigurationViews } from '..';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1iz4oz4",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem"
} : {
  name: "dfnpf2-Pricing",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem;label:Pricing;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "185o5ba",
  styles: "text-decoration-line:underline"
} : {
  name: "rt9c8-Pricing",
  styles: "text-decoration-line:underline;label:Pricing;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "xvw0zl",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem"
} : {
  name: "h6n484-Pricing",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem;label:Pricing;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1e4sizb",
  styles: "margin-top:0.5rem"
} : {
  name: "ub4g4s-Pricing",
  styles: "margin-top:0.5rem;label:Pricing;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "11acf2g",
  styles: "border-bottom-width:1px;--tw-border-opacity:1;border-bottom-color:rgb(204 210 210 / var(--tw-border-opacity));padding-bottom:1.5rem"
} : {
  name: "174zq27-Pricing",
  styles: "border-bottom-width:1px;--tw-border-opacity:1;border-bottom-color:rgb(204 210 210 / var(--tw-border-opacity));padding-bottom:1.5rem;label:Pricing;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "m138fg",
  styles: "border-bottom-width:1px"
} : {
  name: "1ke383d-Pricing",
  styles: "border-bottom-width:1px;label:Pricing;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "5ghe2w",
  styles: "margin-bottom:1rem;margin-top:0.5rem"
} : {
  name: "12288qj-Pricing",
  styles: "margin-bottom:1rem;margin-top:0.5rem;label:Pricing;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const Pricing = ({
  setView,
  ...props
}: {
  setView: (view: DateConfigurationViews | undefined) => void;
} & ComponentPropsWithoutRef<'div'>) => {
  const {
    register,
    watch
  } = useFormContext();
  const {
    hasCustomPricing,
    hasOnlyCustomPricing,
    hasMixedBasePrice,
    hasMixedCustomPrice,
    singleGroup,
    mixedGroupsAndNonGroups,
    hasMultipleGroups
  } = useDateRangeInfo();
  const {
    dateConfigurations,
    pricing,
    onSubmitConfiguration
  } = useCalendarAsideContext();
  const {
    selectedDates
  } = useCalendarSelectionContext();
  const intl = useIntl();
  const selectedRangeConfiguration = useMemo(() => (dateConfigurations || []).filter(config => (selectedDates || []).includes(config.groupedDates.first)), [dateConfigurations, selectedDates]);
  const [isSeasonalPricingEnabled, setSeasonalPricing] = useState<Checked>(true);
  const watchedSeasonalPricing = watch('seasonalPricingEnabled');
  const watchedResetToDefaults = watch('resetToDefaults');
  useEffect(() => {
    if (watchedSeasonalPricing && watchedResetToDefaults) {
      setSeasonalPricing(true);
      return;
    }

    // Set seasonal pricing checkbox state based on selected range
    if (selectedRangeConfiguration) {
      if (hasCustomPricing) {
        // If range has custom pricing either mark as indeterminate
        // (if mixed) or unchecked (if entire range only has custom price).
        setSeasonalPricing(!hasOnlyCustomPricing ? CHECKED_INDETERMINATE : false);
      } else {
        // If nothing in the range has custom price set to checked.
        setSeasonalPricing(true);
      }
    }
  }, [selectedRangeConfiguration, hasOnlyCustomPricing, hasCustomPricing, watchedResetToDefaults]);
  const firstNight = selectedDates?.[0];
  const selectedConfig = (dateConfigurations || []).find(config => config.groupedDates.first === firstNight);
  return _jsxs("div", {
    ...props,
    children: [_jsxs("button", {
      css: _ref,
      type: "button",
      onClick: () => setView(undefined),
      children: [_jsx(ArrowLeft, {}), _jsx("p", {
        css: _ref2,
        children: _jsx(FormattedMessage, {
          id: "8AvlH37f7b",
          defaultMessage: [{
            "type": 0,
            "value": "Back"
          }]
        })
      })]
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsx(ViewHeading, {
        children: hasMultipleGroups && !mixedGroupsAndNonGroups ? _jsx(FormattedMessage, {
          id: "nZXwlcRXEH",
          defaultMessage: [{
            "type": 0,
            "value": "Price for each stay"
          }]
        }) : singleGroup ? _jsx(FormattedMessage, {
          id: "WPEJ46mwTG",
          defaultMessage: [{
            "type": 0,
            "value": "Price for this stay"
          }]
        }) : _jsx(FormattedMessage, {
          id: "YMhI8YHd8j",
          defaultMessage: [{
            "type": 0,
            "value": "Price per night"
          }]
        })
      }), _jsx("div", {
        children: pricing.scheme === 'SEASONAL' && _jsxs(_Fragment, {
          children: [_jsx(TextSwitch, {
            trueLabel: _jsx(FormattedMessage, {
              id: "HbZlPOvj8W",
              defaultMessage: [{
                "type": 0,
                "value": "Seasonal"
              }]
            }),
            falseLabel: _jsx(FormattedMessage, {
              id: "YDaIuWaKZ9",
              defaultMessage: [{
                "type": 0,
                "value": "Manual"
              }]
            }),
            name: "seasonalPricingEnabled",
            checked: isSeasonalPricingEnabled === true ? true : false,
            onChange: e => {
              const value = e.currentTarget.checked;
              if (value === true) {
                onSubmitConfiguration?.({
                  dates: selectedDates,
                  price: $NULL_AS_UNDEFINED
                });
              }
              setSeasonalPricing(value);
            }
          }), isSeasonalPricingEnabled === CHECKED_INDETERMINATE && _jsx(Notification, {
            type: "warning",
            size: "small",
            css: _ref4,
            children: _jsx(FormattedMessage, {
              id: "PkaCgw+wfr",
              defaultMessage: [{
                "type": 0,
                "value": "When you edit the price for these nights, the nights switch to manual pricing."
              }]
            })
          })]
        })
      }), _jsxs("div", {
        css: _ref5,
        children: [_jsx(NumberField, {
          name: "price",
          prefix: pricing.currency,
          css: _ref6,
          inputStyles: {
            "paddingLeft": "4rem"
          },
          placeholder: `${!hasMixedCustomPrice && !hasMixedBasePrice && selectedConfig?.standardPrice ? intl.formatNumber(selectedConfig?.standardPrice?.majorAmount, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            useGrouping: false
          }) : '-'}`,
          onBlur: e => {
            if (!e.currentTarget.value) return;
            onSubmitConfiguration?.({
              dates: selectedDates,
              price: Number(e.currentTarget.value)
            });
          },
          onKeyDown: e => {
            if (!e.currentTarget.value) return;
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              onSubmitConfiguration?.({
                dates: selectedDates,
                price: Number(e.currentTarget.value),
                ...(mixedGroupsAndNonGroups ? {
                  pricePerDay: true
                } : {})
              });
            }
          },
          register: register,
          disabled: pricing.scheme === 'SEASONAL' && isSeasonalPricingEnabled === true,
          hideLabel: true
        }), (hasCustomPricing && hasMixedCustomPrice || !hasCustomPricing && hasMixedBasePrice) && _jsx(Notification, {
          type: "warning",
          size: "small",
          css: _ref7,
          children: _jsx(FormattedMessage, {
            id: "j01RZBFetM",
            defaultMessage: [{
              "type": 0,
              "value": "Some of the nights have different prices. If you change the price, it will affect them all."
            }]
          })
        })]
      })]
    })]
  });
};