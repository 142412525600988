import { eachDayOfInterval, isAfter } from 'date-fns';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { getDateTimestamp, USER_TIME_ZONE } from '../utils/time';
const NO_DATES: [] = [];
export function getDateFromTimestamp(timestamp: string) {
  return zonedTimeToUtc(timestamp, USER_TIME_ZONE || '');
}
export const getDateFromTimestampWithTime = (timestamp: string, time?: string) => {
  if (!time) {
    return getDateFromTimestamp(timestamp);
  }
  return getDateFromTimestamp(`${timestamp} ${time}`);
};

/**
 * Get timezone aware date range from timestamps.
 *
 * We can't use a regular `new Date(from|to)` here. Why? Because timezones.
 *
 * Let's assume the following constants:
 * ```
 * const from = '2021-02-02'
 * const to = '2021-02-03'
 * ```
 *
 * Example 1:
 * A visitor from Denmark. Timezone: 'Europe/Copenhagen'
 * ```
 * new Date(from)   ->    Thu Feb 02 2023 01:00:00 GMT+0100 (CET)
 * new Date(to)     ->    Fri Feb 03 2023 01:00:00 GMT+0100 (CET)
 * interval         ->    ['2022-02-02', '2022-02-03']`
 * ```
 *
 * Yay! Everything works.
 *
 * Example 2:
 * A visitor from USA, New York. Timezone: 'America/New_York'
 * ```
 * new Date(from)   ->  Wed Feb 01 2022 19:00:00 GMT-0500 (EST)
 * new Date(to)     ->  Thu Feb 02 2022 19:00:00 GMT-0500 (EST)
 * interval         ->  ['2022-02-01', '2022-02-02']
 * ```
 *
 * What?! Everything shiftet a day back. But... from and to haven't changed?
 *
 * Why:
 * It's because `Date` assumes that you want whatever date you have passed in
 * to be returned in the users local time and because New York is in EST that
 * means it's the previous day. `eachDayOfInterval` then, correctly, returns
 * an interval starting from the previous day completely oblivious to the
 * timezone.
 *
 * Solution:
 * Use `zonedTimeToUtc`. It'll create a date from the ISO string and offset
 * the timezone difference that is between whatever `USER_TIME_ZONE` is set
 * to and the UTC date effectively normalizing the date to what we want.
 * `getDateInterval` will now correctly return an interval starting end ending
 * from whatever from/to you have entered.
 */
export function getDateInterval(from?: string, to?: string) {
  if (from && to) {
    const start = getDateFromTimestamp(from);
    const end = getDateFromTimestamp(to);
    if (isAfter(end, start)) {
      return eachDayOfInterval({
        start,
        end
      }).map(getDateTimestamp);
    }
  }
  if (from) {
    return [from];
  }
  return NO_DATES;
}