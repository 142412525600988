import { ComponentPropsWithoutRef } from 'react';
import { defineMessages, FormattedList, useIntl } from 'react-intl';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type IntlListFormatType = ComponentPropsWithoutRef<typeof FormattedList>['type'];
export type Participants = {
  adults?: number;
  children?: number;
  infants?: number;
  pets?: number;
};
export default function Attendees({
  participants: {
    adults = 0,
    children = 0,
    infants = 0,
    pets = 0
  },
  group,
  type = 'conjunction'
}: {
  participants: Participants;
  group?: (keyof Participants)[];
  type?: IntlListFormatType;
}) {
  const intl = useIntl();
  const attendees = [];
  const groupAdultsAsGuests = group && group.includes('adults');
  const groupChildrenAsGuests = group && group.includes('children');
  const groupInfantsAsGuests = group && group.includes('infants');
  const groupPetsAsGuests = group && group.includes('pets');
  let guests = 0;
  if (groupAdultsAsGuests) guests += adults;
  if (groupChildrenAsGuests) guests += children;
  if (groupInfantsAsGuests) guests += infants;
  if (groupPetsAsGuests) guests += pets;
  if (guests > 0) {
    attendees.push(intl.formatMessage(messages.guests, {
      value: guests
    }));
  }
  if (!groupAdultsAsGuests && adults > 0) attendees.push(intl.formatMessage(messages.adults, {
    value: adults
  }));
  if (!groupChildrenAsGuests && children > 0) attendees.push(intl.formatMessage(messages.children, {
    value: children
  }));
  if (!groupInfantsAsGuests && infants > 0) attendees.push(intl.formatMessage(messages.infants, {
    value: infants
  }));
  if (!groupPetsAsGuests && pets > 0) attendees.push(intl.formatMessage(messages.pets, {
    value: pets
  }));
  return _jsx(FormattedList, {
    value: attendees,
    type: type
  });
}
const messages = defineMessages({
  guests: {
    id: "loZFB8RGM4",
    defaultMessage: [{
      "type": 6,
      "value": "value",
      "options": {
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " guest"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " guests"
          }]
        }
      },
      "offset": 0,
      "pluralType": "cardinal"
    }]
  },
  adults: {
    id: "f3VQKdRZ6j",
    defaultMessage: [{
      "type": 6,
      "value": "value",
      "options": {
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " adult"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " adults"
          }]
        }
      },
      "offset": 0,
      "pluralType": "cardinal"
    }]
  },
  children: {
    id: "MbwgbwhQdx",
    defaultMessage: [{
      "type": 6,
      "value": "value",
      "options": {
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " child"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " children"
          }]
        }
      },
      "offset": 0,
      "pluralType": "cardinal"
    }]
  },
  infants: {
    id: "rVKmNde7gC",
    defaultMessage: [{
      "type": 6,
      "value": "value",
      "options": {
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " baby"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " babies"
          }]
        }
      },
      "offset": 0,
      "pluralType": "cardinal"
    }]
  },
  pets: {
    id: "nVocpPzboJ",
    defaultMessage: [{
      "type": 6,
      "value": "value",
      "options": {
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " pet"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " pets"
          }]
        }
      },
      "offset": 0,
      "pluralType": "cardinal"
    }]
  }
});