import { addDays, addMonths, differenceInCalendarMonths, eachWeekOfInterval, endOfMonth, getISOWeek, isSameMonth, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { NOW, USER_TIME_ZONE } from '../../utils/time';
import type { Week, WeekNumbers, Weeks, WeekStartsOn } from './types';
export type Month = ReturnType<typeof createMonth>;
export function useCalendar({
  from: fromMonth = NOW,
  to: toMonth,
  weekStartsOn = 1
}: {
  from: Date;
  to?: Date;
  weekStartsOn?: WeekStartsOn;
}) {
  const intl = useIntl();
  const months = useMemo(() => {
    const monthsToDisplay = toMonth ? Math.max(differenceInCalendarMonths(toMonth, fromMonth) + 1, 1) : 1;
    return [...Array(monthsToDisplay)].map((_, i) => {
      const monthStart = addMonths(fromMonth, i);
      return createMonth(monthStart, weekStartsOn, intl);
    });
  }, [fromMonth, toMonth, weekStartsOn, intl]);
  return {
    months
  };
}
function createMonth(date: Date, weekStartsOn: WeekStartsOn, intl: IntlShape) {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  const weeks: Weeks = [];
  const weekNumbers: WeekNumbers = [];

  // Fill out weeks within the month and their corresponding ISO week numbers
  eachWeekOfInterval({
    start,
    end
  }, {
    weekStartsOn
  }).forEach(weekStart => {
    // Fill 7-day week with dates, placing zero for days outside the month
    const week = ([...Array(7)].map((_, i) => {
      const day = addDays(weekStart, i);
      return isSameMonth(day, start) ? day.getDate() : 0;
    }) as Week);
    weeks.push(week);
    weekNumbers.push(getISOWeek(weekStart));
  });
  return {
    name: intl.formatDate(date, {
      year: 'numeric',
      month: 'long',
      timeZone: USER_TIME_ZONE
    }),
    date,
    weeks,
    weekNumbers,
    daysInMonth: end.getDate(),
    month: date.getMonth(),
    year: date.getFullYear()
  };
}