function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ListingFeatures } from '@landfolk/shared/components/ListingFeatures';
import { Affirmation } from '@landfolk/shared/components/ListingStats/Affirmations';
import { subYears } from 'date-fns';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "1hytxrm-AffirmationExamples",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1rniiwb-AffirmationExamples",
  styles: "max-width:65ch;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1sq3xgm-AffirmationExamples",
  styles: "margin-bottom:1rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "1y8kemt-AffirmationExamples",
  styles: "margin-top:1rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "atzfyf",
  styles: "margin-bottom:4rem;margin-top:1rem"
} : {
  name: "1dtc3nm-AffirmationExamples",
  styles: "margin-bottom:4rem;margin-top:1rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "dpwx8i",
  styles: "margin-bottom:1rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;}"
} : {
  name: "1n4o6vo-AffirmationExamples",
  styles: "margin-bottom:1rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;};label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "1gf5wpu-AffirmationExamples",
  styles: "font-weight:600;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1sq3xgm-AffirmationExamples",
  styles: "margin-bottom:1rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "1gf5wpu-AffirmationExamples",
  styles: "font-weight:600;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1sq3xgm-AffirmationExamples",
  styles: "margin-bottom:1rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "1gf5wpu-AffirmationExamples",
  styles: "font-weight:600;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1sq3xgm-AffirmationExamples",
  styles: "margin-bottom:1rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "1gf5wpu-AffirmationExamples",
  styles: "font-weight:600;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1sq3xgm-AffirmationExamples",
  styles: "margin-bottom:1rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "iyyzlo",
  styles: "margin-top:4rem"
} : {
  name: "1gyrn1m-AffirmationExamples",
  styles: "margin-top:4rem;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "1kpbd8q",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;}"
} : {
  name: "1kytcvi-AffirmationExamples",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;};label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "1vjxmwf",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc"
} : {
  name: "ssnxw8-AffirmationExamples",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc;label:AffirmationExamples;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function AffirmationExamples() {
  const props = {
    capacity: 4,
    idealCapacity: 3,
    completedBookings: 11,
    instantBookable: true,
    publishedLanfolkGuideSections: 4,
    listingPublishedAt: subYears(new Date(), 2).toISOString(),
    listingShortId: '1234',
    features: ({
      bicycles: {
        availability: 'AVAILABLE',
        label: 'Bicycles'
      },
      wifi: {
        availability: 'AVAILABLE',
        label: 'Wifi'
      },
      trampoline: {
        availability: 'AVAILABLE',
        label: 'Trampoline'
      },
      highChair: {
        availability: 'AVAILABLE',
        label: 'High Chair'
      },
      toys: {
        availability: 'AVAILABLE',
        label: 'Toys'
      },
      childBed: {
        availability: 'AVAILABLE',
        label: 'Child Bed'
      },
      spa: {
        availability: 'AVAILABLE',
        label: 'Spa'
      },
      sauna: {
        availability: 'AVAILABLE',
        label: 'Sauna'
      },
      bathtub: {
        availability: 'AVAILABLE',
        label: 'Bathtub'
      },
      linenAndTowels: {
        availability: 'AVAILABLE',
        label: 'Linen & Towels'
      },
      petsAllowed: {
        availability: 'AVAILABLE',
        label: 'Pets Allowed'
      }
    } as ListingFeatures)
  };
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "Affirmations"
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "An affirmation informs users about timely achievements and positive actions. If your message is neutral, negative or critical, use an alert instead."
      }), _jsx("section", {
        css: _ref4,
        children: _jsx(Affirmation, {
          ...props
        })
      }), _jsx("section", {
        css: _ref5,
        children: _jsx("p", {
          children: "Refresh page to view another example (randomly selected)."
        })
      }), _jsxs("section", {
        children: [_jsx("h3", {
          css: _ref6,
          children: "Structure"
        }), _jsx("p", {
          children: "Affirmations always consist of a title, a body, an icon and a background colour."
        }), _jsx("br", {}), _jsx("p", {
          css: _ref7,
          children: "Title"
        }), _jsx("p", {
          css: _ref8,
          children: "The title communicates the essence of the affirmation and should be able to stand on its own."
        }), _jsx("p", {
          css: _ref9,
          children: "Body"
        }), _jsx("p", {
          css: _ref10,
          children: "The body adds details and context to the title. It should not be necessary to read the body to understand the meaning of the affirmation."
        }), _jsx("p", {
          css: _ref11,
          children: "Icon"
        }), _jsx("p", {
          css: _ref12,
          children: "The icon supports the meaning of the title and the body. It adds a playful element to the affirmation."
        }), _jsx("p", {
          css: _ref13,
          children: "Background colour"
        }), _jsx("p", {
          css: _ref14,
          children: "The background colour is always spring 100."
        })]
      }), _jsxs("section", {
        css: _ref15,
        children: [_jsx("h3", {
          css: _ref16,
          children: "Best practices"
        }), _jsxs("ul", {
          css: _ref17,
          children: [_jsx("li", {
            children: "Use to appreciate users and lighten up a heavy page"
          }), _jsx("li", {
            children: "Use sparingly and for meaningful affirmations"
          }), _jsx("li", {
            children: "Only use one affirmation per page"
          }), _jsx("li", {
            children: "Do not use to upsell or for marketing"
          }), _jsx("li", {
            children: "Never include urgent or important information"
          })]
        })]
      })]
    })]
  });
}