import { $NULL } from './types';
export type OptionalTwStyle = TwStyle | OptionalTwStyle[] | false | $NULL | '' | 0 | undefined;

/**
 * Take in an arbitrary amount of optional `TwStyle` arguments and return as uniform array.
 *
 * Supports falsy values for ease of use in composing styles in `css` prop, like so:
 *
 * ```tsx
 * import { flattenStyles } from '@landfolk/shared/utils/style'
 *
 * <div
 *   css={flattenStyles(
 *     tw`mt-3`,
 *     someFalsyValue && [tw`mt-1`, tw`pb-3`],
 *   )}
 * >
 * </div>
 * ```
 */
export function flattenStyles(...args: OptionalTwStyle[]) {
  return args.flatMap(arg => {
    if (!arg) {
      return [];
    }
    if (Array.isArray(arg)) {
      // Filter out undefined elements in the array
      return arg.filter((item): item is TwStyle => item !== undefined);
    }
    return [arg];
  });
}