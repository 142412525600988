function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useMemo } from 'react';
import { useScreenSize } from '../../../utils/mediaQueries';
import { EventIcon } from './EventIcon';
import EventSausage, { EventSausageProps } from './EventSausage';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "s5xdrg",
  styles: "display:flex;align-items:center"
} : {
  name: "ffyor3-tooltipContent",
  styles: "display:flex;align-items:center;label:tooltipContent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function ExternalEventSausage(props: EventSausageProps) {
  const isNotLarge = useScreenSize('nl');
  const tooltipContent = useMemo(() => {
    if (isNotLarge) {
      return _jsxs("span", {
        css: _ref,
        children: [_jsx(EventIcon, {
          state: props.config?.event?.booking?.state ?? props.config.event.reason
        }), props.config.event.summary]
      });
    }
    return undefined;
  }, [props.config, isNotLarge]);
  return _jsx(EventSausage, {
    title: props.config.event.summary,
    tooltip: tooltipContent,
    ...props
  });
}