import { ComponentPropsWithRef, useCallback, useMemo, useRef, useState } from 'react';
import { BarChart } from 'recharts';
import { $NULL } from '../../utils/types';
const RECHART_CERTESIAN_AXIS_TICK_VALUE_SELECTOR = `.recharts-cartesian-axis-tick-value[orientation="left"],
.recharts-cartesian-axis-tick-value[orientation="right"]`;
type Props = {
  yAxisWidthModifier?: (number: number) => number;
};
type RechartRef = NonNullable<ComponentPropsWithRef<typeof BarChart>['ref']>;
type ReturnValues = {
  yAxisWidth: number | undefined;
  setChartRef: RechartRef;
  recalculate: () => void;
};

// Based on https://github.com/recharts/recharts/issues/2027#issuecomment-1282172412
export const useDynamicYAxisWidth = (props: void | Props): ReturnValues => {
  const {
    yAxisWidthModifier
  } = props || {};
  const [yAxisWidthState, setYAxisWidthState] = useState<number | undefined>(undefined);
  const chartContainer = useRef<HTMLElement>();
  const recalculateWidth = () => {
    if (!chartContainer.current) {
      return;
    }
    const tickValueElements = chartContainer.current.querySelectorAll(RECHART_CERTESIAN_AXIS_TICK_VALUE_SELECTOR);
    const highestWidth = [...tickValueElements].map(el => {
      const boundingRect = el.getBoundingClientRect();
      if (boundingRect != $NULL && boundingRect.width != $NULL) {
        return boundingRect.width;
      }
      return 0;
    }).reduce((accumulator, value) => {
      if (accumulator < value) {
        return value;
      }
      return accumulator;
    }, 0);
    setYAxisWidthState(highestWidth);
  };
  const _setChartRef: RechartRef = chartRef => {
    if (chartRef != $NULL && chartRef?.container != $NULL) {
      chartContainer.current = chartRef.container;
      recalculateWidth();
    }
  };
  const yAxisWidth = useMemo(() => {
    if (yAxisWidthModifier != $NULL && yAxisWidthState != $NULL) {
      return yAxisWidthModifier(yAxisWidthState);
    }
    return yAxisWidthState;
  }, [yAxisWidthModifier, yAxisWidthState]);

  // Separate definition with useCallback to circumvent RechartRef union type
  // difficulties. Defining it inline doesn't type well, basically.
  const setChartRef = useCallback(_setChartRef, []);
  const recalculate = useCallback(() => recalculateWidth(), []);
  return {
    yAxisWidth,
    setChartRef,
    recalculate
  };
};