import theme from '@landfolk/styling/utils/theme';
import { useEffect, useState } from 'react';
type MinMax = {
  min?: string;
  max?: string;
  raw?: string;
};
export type ScreenTypes = Extract<keyof typeof theme.screens, string>;
const mediaQueriesForCSS = ({} as Record<ScreenTypes, string>);
const mediaQueriesForJS = ({} as Record<ScreenTypes, string>);
for (const screen in theme.screens) {
  const {
    min,
    max,
    raw
  }: MinMax = theme.screens[(screen as ScreenTypes)];
  const arr = [];
  if (min) {
    arr.push(`(min-width: ${min})`);
  }
  if (max) {
    arr.push(`(max-width: ${max})`);
  }
  if (raw) {
    arr.push(raw);
  }
  mediaQueriesForJS[(screen as ScreenTypes)] = arr.join(' and ');
  mediaQueriesForCSS[(screen as ScreenTypes)] = '@media ' + arr.join(' and ');
}
export const mediaQueries = mediaQueriesForJS;
const memoizedMatch: Partial<Record<ScreenTypes, boolean>> = {
  sm: true
};
export function useScreenSize(screen: ScreenTypes, defaultValue = memoizedMatch[screen] || false) {
  const [matches, setMatches] = useState(defaultValue);
  const query = mediaQueriesForJS[screen];
  useEffect(() => {
    // NOTE: __IS_BROWSER__ fails in jest, so we check for window manually
    if (typeof window === 'undefined') {
      return;
    }
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
      memoizedMatch[screen] = media.matches;
    }
    const listener = () => {
      setMatches(media.matches);
      memoizedMatch[screen] = media.matches;
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, screen]);
  return matches;
}
export default mediaQueriesForCSS;