function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import CountBadge from '@landfolk/shared/components/CountBadge';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "912eas-CountBadgeExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "ia8sz4",
  styles: "display:flex;max-width:32rem;flex-direction:column"
} : {
  name: "1ip04r2-CountBadgeExample",
  styles: "display:flex;max-width:32rem;flex-direction:column;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "102hqub-CountBadgeExample",
  styles: "margin-top:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "11a3bfa-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1bxjxkf",
  styles: "margin-bottom:1rem;margin-top:1rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1kzfpdx-CountBadgeExample",
  styles: "margin-bottom:1rem;margin-top:1rem;font-size:1.5rem;line-height:1.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "ozibme",
  styles: "font-size:0.75rem;line-height:1rem"
} : {
  name: "1160qd9-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "ozibme",
  styles: "font-size:0.75rem;line-height:1rem"
} : {
  name: "1160qd9-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "ozibme",
  styles: "font-size:0.75rem;line-height:1rem"
} : {
  name: "1160qd9-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "ozibme",
  styles: "font-size:0.75rem;line-height:1rem"
} : {
  name: "1160qd9-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "ozibme",
  styles: "font-size:0.75rem;line-height:1rem"
} : {
  name: "1160qd9-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "180pqcn",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1519fko-CountBadgeExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "11a3bfa-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref23 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref24 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref25 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref26 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref27 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref28 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref29 = process.env.NODE_ENV === "production" ? {
  name: "wtemhz",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem"
} : {
  name: "11pv7kj-CountBadgeExample",
  styles: "margin-top:1rem;margin-bottom:1rem;display:flex;align-items:center;gap:0.5rem;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref30 = process.env.NODE_ENV === "production" ? {
  name: "1ide98v",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600"
} : {
  name: "k9ad5p-CountBadgeExample",
  styles: "font-size:0.75rem;line-height:1rem;font-weight:600;label:CountBadgeExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function CountBadgeExample() {
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "CountBadge"
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsx("p", {
        children: "CountBadges alert users to important notifications, tasks or other messages that the user should react on."
      }), _jsx("p", {
        css: _ref3,
        children: "Notifications, tasks or message that do not require an action, rarely need a CountBadge. In a few instances, ot can also be few instances, it can also be used to highlight new features."
      }), _jsx("p", {
        css: _ref4,
        children: "CountBadges are mostly shown next to a label or on an image."
      }), _jsx("h2", {
        css: _ref5,
        children: "Plain"
      }), _jsxs("div", {
        css: _ref6,
        children: [_jsx("p", {
          css: _ref7,
          children: "Info (default)"
        }), _jsx(CountBadge, {
          content: 5
        }), _jsx("p", {
          css: _ref8,
          children: "\u2013 use for neutral, important tasks"
        })]
      }), _jsxs("div", {
        css: _ref9,
        children: [_jsx("p", {
          css: _ref10,
          children: "Success"
        }), _jsx(CountBadge, {
          type: "success",
          content: 10
        }), _jsx("p", {
          css: _ref11,
          children: "\u2013 use for important successes"
        })]
      }), _jsxs("div", {
        css: _ref12,
        children: [_jsx("p", {
          css: _ref13,
          children: "Warning"
        }), _jsx(CountBadge, {
          type: "warning",
          content: 105
        }), _jsx("p", {
          css: _ref14,
          children: "\u2013 use for notifications, tasks, etc."
        })]
      }), _jsxs("div", {
        css: _ref15,
        children: [_jsx("p", {
          css: _ref16,
          children: "Error"
        }), _jsx(CountBadge, {
          type: "error",
          content: 39
        }), _jsx("p", {
          css: _ref17,
          children: "\u2013 use for important and urgent errors"
        })]
      }), _jsxs("div", {
        css: _ref18,
        children: [_jsx("p", {
          css: _ref19,
          children: "Text content"
        }), _jsx(CountBadge, {
          type: "success",
          content: "Great"
        }), _jsx("p", {
          css: _ref20,
          children: "\u2013 use sparingly"
        })]
      }), _jsx("h2", {
        css: _ref21,
        children: "With appear animation"
      }), _jsx("p", {
        css: _ref22,
        children: "CountBadges can also highlighted even more through an appear animation. It adds extra visibility and urgency to the counter."
      }), _jsx("p", {
        children: "Refresh to see the animations again."
      }), _jsxs("div", {
        css: _ref23,
        children: [_jsx("p", {
          css: _ref24,
          children: "Info (default)"
        }), _jsx(CountBadge, {
          appear: true,
          duration: 400,
          delay: 200,
          content: 0
        })]
      }), _jsxs("div", {
        css: _ref25,
        children: [_jsx("p", {
          css: _ref26,
          children: "Success"
        }), _jsx(CountBadge, {
          type: "success",
          appear: true,
          duration: 400,
          delay: 400,
          content: -4
        })]
      }), _jsxs("div", {
        css: _ref27,
        children: [_jsx("p", {
          css: _ref28,
          children: "Warning"
        }), _jsx(CountBadge, {
          type: "warning",
          appear: true,
          duration: 2500,
          delay: 600,
          content: 42
        })]
      }), _jsxs("div", {
        css: _ref29,
        children: [_jsx("p", {
          css: _ref30,
          children: "Error"
        }), _jsx(CountBadge, {
          type: "error",
          appear: true,
          duration: 500,
          delay: 800,
          content: 1034
        })]
      })]
    })]
  });
}