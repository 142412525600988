import { formatISO } from 'date-fns';
import { DateTimestamp } from '../components/Calendar/types';
export const NOW = new Date();
export const WEEK_STARTS_ON = 1;
export let USER_TIME_ZONE: string | undefined = undefined;

// Christmas 2023
// Remember to update affected copy when changing this interval
export const SLOW_RESPONSE_TIME_TOLERATED_WITHIN = {
  start: new Date(2023, 11, 23),
  end: new Date(2024, 0, 2, 8)
};
try {
  USER_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
} catch (_) {
  //
}
if ('__defaultTimeZone' in Intl.DateTimeFormat && USER_TIME_ZONE === 'UTC') {
  // NOTE: This isn't a proper fix. It only fixes it for those users who are in
  // a european time zone and with an old browser that haven't implemented DateTimeFormat.
  // ...Which is bascially everyone. Once Safari 14.4 and below have been phased out this
  // wont be a problem anymore.
  USER_TIME_ZONE = 'Europe/Copenhagen';
}
export const getDateTimestamp = (date: Date): DateTimestamp => formatISO(date, {
  representation: 'date'
});