import { defineMessage } from 'react-intl';
import { BookingState } from './types';
export type BookingStateOrProgress = BookingState | 'STARTED' | 'UNDERWAY';
export default function bookingStateLabel(state: BookingStateOrProgress) {
  switch (state) {
    case 'STARTED':
      return defineMessage({
        id: "NAKHF6pc6h",
        defaultMessage: [{
          "type": 0,
          "value": "Started"
        }]
      });
    case 'UNDERWAY':
      return defineMessage({
        id: "cyGsGdsuAH",
        defaultMessage: [{
          "type": 0,
          "value": "Underway"
        }]
      });
    case BookingState.Checkout:
      return defineMessage({
        id: "Uvh3rhB4T5",
        defaultMessage: [{
          "type": 0,
          "value": "Checkout"
        }]
      });
    case BookingState.Pending:
      return defineMessage({
        id: "uWUaZrSKgo",
        defaultMessage: [{
          "type": 0,
          "value": "Requested"
        }]
      });
    case BookingState.Declined:
      return defineMessage({
        id: "J7KUDOWVE+",
        defaultMessage: [{
          "type": 0,
          "value": "Rejected"
        }]
      });
    case BookingState.Withdrawn:
    case BookingState.Cancelled:
      return defineMessage({
        id: "JMXs7oEjNz",
        defaultMessage: [{
          "type": 0,
          "value": "Cancelled"
        }]
      });
    case BookingState.Confirmed:
      return defineMessage({
        id: "0KBtXme89q",
        defaultMessage: [{
          "type": 0,
          "value": "Confirmed"
        }]
      });
    case BookingState.Completed:
      return defineMessage({
        id: "TTpAJ4SkQ5",
        defaultMessage: [{
          "type": 0,
          "value": "Completed"
        }]
      });
    default:
      return undefined;
  }
}