function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { TabsList, TabsTrigger } from '@landfolk/shared/components/Tabs';
import Calendar from '@landfolk/shared/icons/Calendar.svg';
import House from '@landfolk/shared/icons/House.svg';
import Suitcase from '@landfolk/shared/icons/Suitcase.svg';
import { useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const tabs = [_jsxs(_Fragment, {
  children: [_jsx(House, {}), " Basics"]
}), _jsxs(_Fragment, {
  children: [_jsx(Calendar, {}), " Calendar"]
}), _jsxs(_Fragment, {
  children: [_jsx(Suitcase, {}), " Bookings"]
})];
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "145eg3a-TabsExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1hqwpit-TabsExample",
  styles: "max-width:65ch;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "bxb8jk-TabsExample",
  styles: "margin-bottom:1rem;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "y5857-TabsExample",
  styles: "margin-bottom:2rem;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function TabsExample() {
  const [active, setActive] = useState(0);
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "Tabs"
    }), _jsxs("section", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "Tabs are used to devide pages into topics that users can navigate between. A tab can contain secondary navigation and many subpages. Design for as little tab switching as possible by dividing the tabs into topics with little overlap."
      }), _jsx("p", {
        css: _ref4,
        children: "All content above the tabs stays the same when switching between tabs, while all content below changes."
      })]
    }), _jsx(TabsList, {
      border: true,
      children: tabs.map((tab, i) => _jsx(TabsTrigger, {
        as: "button",
        isActive: active === i,
        onClick: () => setActive(i),
        children: tab
      }, i))
    })]
  });
}