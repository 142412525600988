import theme from '@landfolk/styling/utils/theme';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const pastAreaPatternId = 'pastAreaPattern';
export const PastAreaPattern = () => {
  return _jsxs("pattern", {
    id: pastAreaPatternId,
    patternUnits: "userSpaceOnUse",
    width: "10",
    height: "10",
    children: [_jsx("line", {
      x1: "20.3536",
      y1: "-9.64645",
      x2: "0.353554",
      y2: "10.3536",
      strokeDasharray: "1 1",
      tw: "stroke-february-tertian lodpi:(stroke-february-secundo [stroke-dasharray: none])"
    }), _jsx("line", {
      x1: "10.3536",
      y1: "-9.64645",
      x2: "-9.64645",
      y2: "10.3536",
      stroke: theme.colors['february-secundo'],
      strokeDasharray: "1 1",
      tw: "stroke-february-tertian lodpi:(stroke-february-secundo [stroke-dasharray: none])"
    })]
  });
};