import { isBefore } from 'date-fns';
import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { getDateFromTimestamp, getDateInterval } from '../../utils/date';
import type { DateTimestamp, DateTimestampInterval, DateTimestampRange } from './types';
import { DateAvailability, useAvailability } from './useAvailability';

/**
 * Hook for date range selection logic
 *
 * Will usually pick start date and end date in alternating fashion
 *
 * Optionally provide an array of available timestamps to disallow selecting ranges containing unavailable dates
 */

type UseSelectDatesOptions = undefined | {
  initial?: DateTimestampInterval;
  availability?: DateAvailability;
  allowOneDaySelection?: boolean;
  allowSelectingBackwards?: boolean;
};
export function useSelectDates({
  initial = [],
  availability,
  allowOneDaySelection,
  allowSelectingBackwards
}: UseSelectDatesOptions = {}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition();
  const {
    checkIntervalAvailability
  } = useAvailability(availability);
  const [selectedDates, _setSelectedDates] = useState<DateTimestampInterval>(initial);
  const [hoveredDates, setHoveredDates] = useState<DateTimestampInterval>([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const isSelectingRef = useRef(false);
  const selectedDatesRef = useRef<DateTimestampInterval>(selectedDates);
  const startTimestamp = selectedDates[0];
  const endTimestamp = selectedDates.slice(-1)[0];
  const selectedFrom = useMemo(() => {
    if (!startTimestamp) {
      return undefined;
    }
    return {
      date: getDateFromTimestamp(startTimestamp),
      timestamp: startTimestamp
    };
  }, [startTimestamp]);
  const selectedTo = useMemo(() => {
    if (!endTimestamp) {
      return undefined;
    }
    return {
      date: getDateFromTimestamp(endTimestamp),
      timestamp: endTimestamp
    };
  }, [endTimestamp]);

  // This function contains all the logic for combining two ranges into a single
  // interval including rule based fallbacks.
  const expandSelection = useCallback((interval: DateTimestampInterval, secondRange: DateTimestampRange) => {
    // If interval is empty, return the second selection.
    if (interval.length === 0) return;
    const selectedFrom = (interval[0] as string);
    const selectedTo = (interval.slice(-1)[0] as string);

    // Check if second selection is earlier the first.
    const secondSelectionIsBefore = isBefore(getDateFromTimestamp(secondRange.first), getDateFromTimestamp(selectedFrom));

    // If selecting backwards is not allowed and second selection is before
    // the first selection, return.
    if (secondSelectionIsBefore && !allowSelectingBackwards) return;

    // Get a new range depending on which selection is earlier
    const newSelection = secondSelectionIsBefore ? getDateInterval(secondRange.first, selectedTo) : getDateInterval(selectedFrom, secondRange.last);

    // If applicable, check if the new range is available. If the range
    // conflicts with availability, return.
    const availabilityConflict = availability && !checkIntervalAvailability((newSelection[0] as string), (newSelection.slice(-1)[0] as string));
    if (availabilityConflict) return;
    return newSelection;
  }, [allowSelectingBackwards, availability, checkIntervalAvailability]);
  const handleClickDateGroup = useCallback((range: DateTimestampRange) => {
    // All the operations here cause a lot of re-renders that are ultimately
    // irrelevant if the user selects other dates. So we wrap the updates here
    // in `startTransition` to let react know to downprioritize them.
    startTransition(() => {
      setHoveredDates([]);
      if (isSelectingRef.current && selectedDatesRef.current) {
        const newSelection = expandSelection(selectedDatesRef.current, range);

        // If new selection is valid, set it as selected dates and stop
        // selecting. Otherwise, set the newly clicked range as selected dates
        // and start selecting anew.
        if (newSelection) {
          setIsSelecting(false);
          _setSelectedDates(newSelection);
        } else {
          _setSelectedDates(getDateInterval(range.first, range.last));
        }
      } else {
        setIsSelecting(true);
        _setSelectedDates(getDateInterval(range.first, range.last));
      }
    });
  }, [_setSelectedDates, setHoveredDates, setIsSelecting, expandSelection]);
  const handleClickDate = useCallback((date: DateTimestamp) => {
    // If clicking on the same date as the currently selected date and one day
    // selection is not allowed, do nothing.
    if (!allowOneDaySelection && selectedDatesRef.current.length === 1 && selectedDatesRef.current[0] === date) {
      return;
    }

    // Handle everything as a grouped selection with same first and last date.
    handleClickDateGroup({
      first: date,
      last: date
    });
  }, [handleClickDateGroup, allowOneDaySelection]);
  const handleHoverDateGroup = useCallback((range: DateTimestampRange) => {
    // If not currently selecting, do nothing.
    if (!isSelectingRef.current || !selectedDatesRef.current) {
      return;
    }
    const newSelection = expandSelection(selectedDatesRef.current, range);
    if (newSelection) {
      setHoveredDates(newSelection);
    } else {
      setHoveredDates(getDateInterval(range.first, range.last));
    }
  }, [expandSelection]);
  const handleHoverDate = useCallback((date?: string) => {
    if (!date) {
      setHoveredDates([]);
    } else {
      // Handle everything as a group being hovered with same first and last
      // date.
      handleHoverDateGroup({
        first: date,
        last: date
      });
    }
  }, [setHoveredDates, handleHoverDateGroup]);
  const setSelectedDates = (value: SetStateAction<DateTimestampInterval>) => {
    // Always reset isSelecting state when manipulating selectedDates directly

    startTransition(() => {
      setIsSelecting(false);
      _setSelectedDates(value);
    });
  };
  const stopSelecting = useCallback(() => {
    setHoveredDates([]);
    setIsSelecting(false);
  }, []);
  useEffect(() => {
    // Keep isSelecting state in ref also (to performance optimize callbacks)
    isSelectingRef.current = isSelecting;
  }, [isSelecting]);
  useEffect(() => {
    // Keep selected start date in ref also (to performance optimize callbacks)
    selectedDatesRef.current = selectedDates;
  }, [selectedDates]);
  return {
    /**
     * List of selected timestamps
     */
    selectedDates,
    /**
     * Set list of selected timestamps
     */
    setSelectedDates,
    /**
     * First date and timestamp (returns both) of the selected dates
     */
    selectedFrom,
    /**
     * Last date and timestamp (returns both) of the selected dates
     */
    selectedTo,
    /**
     * True if only checkin is selected (and next click will _usually_ select checkout)
     */
    isSelecting,
    /**
     * Like isSelecting, but as a ref, not state
     */
    isSelectingRef,
    /**
     * Stop selecting and make the next click start a new selection. Usually called when mouse leaves the calendar.
     */
    stopSelecting,
    /**
     * Handle what should happen when clicking a date
     *
     * Takes care of:
     * - selecting a date as a start date if it's the first date selected
     * - selecting a date as a end date if it's the second date selected
     * - selecting a date as a start date when trying to select a range containing unavailable dates (when applicable)
     */
    handleClickDate,
    /**
     * List of hovered timestamps
     */
    hoveredDates,
    /**
     * Handle what should happen when hovering a date
     *
     * Only has an effect while selecting (automatically, so can be called without worry always)
     */
    handleHoverDate,
    /**
     * Handle what should happen when clicking on a date group
     */
    handleClickDateGroup,
    /**
     *  Handle what should happen when hovering on a date group
     */
    handleHoverDateGroup
  };
}
export type SelectDates = ReturnType<typeof useSelectDates>;