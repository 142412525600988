import { useSnapshot } from 'valtio';
import { toastActions, ToastItem, toastStore } from '../../stores/toast';
let count = 0;
function generateSequentialId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER;
  return count.toString();
}
export const addToast = ({
  onOpenChange,
  ...props
}: Omit<ToastItem, 'id'>) => {
  const id = generateSequentialId();
  const dismiss = () => toastActions.dismiss(id);
  toastActions.add({
    ...props,
    id,
    open: true,
    onOpenChange: open => {
      if (!open) dismiss();
      onOpenChange?.(open);
    }
  });
  return {
    id: id,
    dismiss
  };
};

/**
 * Hook to trigger toasts from anywhere in the app.
 *
 * @example
 * ```tsx
 * const { addToast, dismissAllToasts } = useToast()
 *
 * const handleClick = () => {
 *   // Trigger a new toast
 *   const toast = addToast({
 *     headline: 'Hello World!',
 *     description: 'This is a toast.',
 *     type: 'info',
 *   })
 *
 *   // Later on, you can dismiss the toast
 *   setTimeout(() => {
 *     toast.dismiss()
 *   }, 1000)
 * }
 *
 * // Use global dismiss to dismiss all toasts at once
 * const handleRouteChange = () => {
 *   dismissAllToasts()
 * }
 * ```
 */
export function useToast() {
  const {
    toasts
  } = useSnapshot(toastStore);
  return {
    toasts,
    addToast: addToast,
    dismissAllToasts: () => toastActions.dismiss()
  };
}