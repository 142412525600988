import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import { useEffect, useState } from 'react';

// Heavily inspired from https://github.com/streamich/react-use/blob/master/src/useScrollbarWidth.ts
export function useScrollbarSize() {
  const [width, setWidth] = useState(scrollbarWidth());

  // this needed to ensure the scrollbar width in case hook called before the DOM is ready
  useEffect(() => {
    if (typeof width !== 'undefined') {
      return;
    }
    const raf = requestAnimationFrame(() => {
      setWidth(scrollbarWidth());
    });
    return () => cancelAnimationFrame(raf);
  }, []);
  return width;
}