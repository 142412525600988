import { FormatNumberOptions, FormattedMessage, FormattedNumber } from 'react-intl';
import Nothing from './Nothing';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export type NumberRangeProps = {
  from?: number;
  to?: number;
} & FormatNumberOptions;
export default function NumberRange({
  from,
  to,
  ...rest
}: NumberRangeProps) {
  if (to) {
    if (from && from === to) {
      return _jsx(FormattedNumber, {
        value: from,
        ...rest
      });
    }
    return _jsx(FormattedMessage, {
      id: "I4U2WxsWjl",
      defaultMessage: [{
        "type": 1,
        "value": "valueMin"
      }, {
        "type": 0,
        "value": "–"
      }, {
        "type": 1,
        "value": "valueMax"
      }],
      values: {
        valueMin: _jsx(FormattedNumber, {
          value: from || 0,
          ...rest
        }),
        valueMax: _jsx(FormattedNumber, {
          value: to,
          ...rest
        })
      }
    });
  }
  if (from) {
    return _jsx(FormattedNumber, {
      value: from,
      ...rest
    });
  }
  return _jsx(Nothing, {});
}