import { useRef, useState } from 'react';
import { useDebounce, useIsomorphicEffect, useWindowEventListener } from 'rooks';
type ViewportDimensions = {
  width: number;
  height: number;
};
function getDimensions(ssrFallback: ViewportDimensions): ViewportDimensions {
  if (__IS_BROWSER__) {
    return {
      height: window.innerHeight,
      width: window.innerWidth
    };
  } else {
    return ssrFallback;
  }
}

// Heavily inspired by https://react-hooks.org/docs/useWindowSize but with a debounce
export function useViewportSize(debounce = 200, defaultSize = {
  width: 414,
  height: 896
}): ViewportDimensions {
  const [viewportSize, setViewportSize] = useState<ViewportDimensions>(getDimensions(defaultSize));
  const setViewportSizeDebounced = useDebounce(() => {
    setViewportSize(getDimensions(defaultSize));
  }, debounce);

  // Set resize handler once on mount and clean before unmount
  useIsomorphicEffect(() => {
    function onResize() {
      setViewportSizeDebounced();
    }
    if (__IS_BROWSER__) {
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
    return undefined;
  }, []);
  return viewportSize;
}
export function useIsResizingViewport(debounce = 200) {
  const [isResizing, setIsResizing] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  useWindowEventListener('resize', function () {
    setIsResizing(true);
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsResizing(false);
    }, debounce);
  }, {
    passive: true
  });
  return isResizing;
}