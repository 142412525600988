import { useRef, useState } from 'react';
export type UseClipboardOptions = {
  successTimeout?: number;
};
export default function useClipboard({
  successTimeout = 7000
}: UseClipboardOptions = {}) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [success, setSuccess] = useState(false);
  const copy = async (text: string) => {
    setSuccess(false);
    timeoutRef.current && clearTimeout(timeoutRef.current);
    try {
      await navigator.clipboard.writeText(text);
      setSuccess(true);
      timeoutRef.current = setTimeout(() => {
        setSuccess(false);
      }, successTimeout);
      return;
    } catch (err) {
      console.error(err);
      setSuccess(false);
      throw err;
    }
  };
  return {
    copy,
    success
  };
}