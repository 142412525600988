/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-null/no-null */

/**
 * We don't use null in our codebase, but sometimes we're forced to do it.
 * See the types below for why and when they're used.
 *
 * Why don't we use null? That's a whole other description but it's basically one
 * less thing to worry about. The TypeScript team doesn't use it and it have worked
 * out fine. See links below.
 * https://basarat.gitbook.io/typescript/recap/null-undefined#final-thoughts
 * https://github.com/Microsoft/TypeScript/wiki/Coding-guidelines#null-and-undefined
 */

/**
 * We don't use null in our codebase. But sometimes we want to allow `null` as
 * a valid type if the external util or library we use uses `null` in their types.
 */
export type $NULL = null;

/**
 * We don't use null in our codebase. But sometimes we have to pass `null` as an
 * input to an external util (e.g. useRef). Use this type here.
 */
export const $NULL = null;

/**
 * We don't use null in our codebase. But sometimes we have to pass `null` as an
 * input to something in GraphQL where we actively want to clear a value.
 * However, this conflicts with our autogenerated GraphQL types where we have actively
 * removed `null` from the `Maybe` type. (see codegen.yml). This means `null` is
 * not a valid value. We therefore have this special `null` value, coerced as undefined
 * in those special cases.
 */
export const $NULL_AS_UNDEFINED = ((null as unknown) as undefined);

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type $ANY = any;
type AtLeastOne<T> = [T, ...T[]];

/**
 * Useful for creating an array that exactly contains the specified strings.
 *
 * Thanks to: https://stackoverflow.com/a/55266531
 */
export const exhaustiveStringTuple = <T extends string,>() => <L extends AtLeastOne<T>,>(...x: L extends $ANY ? Exclude<T, L[number]> extends never ? L : Exclude<T, L[number]>[] : never) => x;
export const assertNever = (k: 'never') => k;