function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ComponentPropsWithoutRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ArrowLeft from '../../../../../icons/ArrowLeft.svg';
import { useCalendarSelectionContext } from '../../../context';
import { useCalendarAsideContext } from '../../context';
import { ViewHeading } from '../../shared';
import { DateConfigurationViews } from '..';
import { CheckRadioBox } from './shared';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1iz4oz4",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem"
} : {
  name: "1jdvs1d-MixedInstantBooking",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem;label:MixedInstantBooking;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "185o5ba",
  styles: "text-decoration-line:underline"
} : {
  name: "1yjhhf-MixedInstantBooking",
  styles: "text-decoration-line:underline;label:MixedInstantBooking;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "xvw0zl",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem"
} : {
  name: "crhfc5-MixedInstantBooking",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem;label:MixedInstantBooking;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "riiewv",
  styles: "margin-top:1rem;display:flex;flex-direction:column;gap:1rem"
} : {
  name: "2fcq89-MixedInstantBooking",
  styles: "margin-top:1rem;display:flex;flex-direction:column;gap:1rem;label:MixedInstantBooking;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "79lrzh-MixedInstantBooking",
  styles: "font-weight:600;label:MixedInstantBooking;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "79lrzh-MixedInstantBooking",
  styles: "font-weight:600;label:MixedInstantBooking;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const MixedInstantBooking = ({
  setView,
  ...props
}: {
  setView: (view: DateConfigurationViews | undefined) => void;
} & ComponentPropsWithoutRef<'div'>) => {
  const [instantBookable, setInstantBookable] = useState<boolean>();
  const {
    selectedDates
  } = useCalendarSelectionContext();
  const {
    onSubmitConfiguration
  } = useCalendarAsideContext();
  return _jsxs("div", {
    ...props,
    children: [_jsxs("button", {
      css: _ref,
      type: "button",
      onClick: () => setView(undefined),
      children: [_jsx(ArrowLeft, {}), _jsx("p", {
        css: _ref2,
        children: _jsx(FormattedMessage, {
          id: "l+FAgQzKjV",
          defaultMessage: [{
            "type": 0,
            "value": "Back"
          }]
        })
      })]
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsx(ViewHeading, {
        children: _jsx(FormattedMessage, {
          id: "Jy/iEurPUe",
          defaultMessage: [{
            "type": 0,
            "value": "Edit Instant booking"
          }]
        })
      }), _jsx("p", {
        children: _jsx(FormattedMessage, {
          id: "qg3F2ku38p",
          defaultMessage: [{
            "type": 0,
            "value": "You have mixed settings for the selected nights."
          }]
        })
      }), _jsxs("fieldset", {
        role: "radiogroup",
        css: _ref4,
        children: [_jsx(CheckRadioBox, {
          type: "radio",
          onClick: () => {
            setInstantBookable(true);
            onSubmitConfiguration?.({
              dates: selectedDates,
              instantBookable: true
            });
          },
          checked: instantBookable === true,
          value: "ALL",
          label: _jsxs(_Fragment, {
            children: [_jsx("h4", {
              css: _ref5,
              children: _jsx(FormattedMessage, {
                id: "D/eTtuH/K8",
                defaultMessage: [{
                  "type": 0,
                  "value": "Pre-confirm all"
                }]
              })
            }), _jsx("p", {
              children: _jsx(FormattedMessage, {
                id: "j+Kire12BN",
                defaultMessage: [{
                  "type": 0,
                  "value": "Allow Instant booking for the selected "
                }, {
                  "type": 6,
                  "value": "nights",
                  "options": {
                    "one": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " night"
                      }]
                    },
                    "other": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " nights"
                      }]
                    }
                  },
                  "offset": 0,
                  "pluralType": "cardinal"
                }],
                values: {
                  nights: selectedDates.length
                }
              })
            })]
          })
        }), _jsx(CheckRadioBox, {
          type: "radio",
          onClick: () => {
            setInstantBookable(false);
            onSubmitConfiguration?.({
              dates: selectedDates,
              instantBookable: false
            });
          },
          checked: instantBookable === false,
          value: "NONE",
          label: _jsxs(_Fragment, {
            children: [_jsx("h4", {
              css: _ref6,
              children: _jsx(FormattedMessage, {
                id: "XAdM9oHlu/",
                defaultMessage: [{
                  "type": 0,
                  "value": "Pre-confirm none"
                }]
              })
            }), _jsx("p", {
              children: _jsx(FormattedMessage, {
                id: "om3nrfKGn1",
                defaultMessage: [{
                  "type": 0,
                  "value": "Don’t allow Instant booking for the selected "
                }, {
                  "type": 6,
                  "value": "nights",
                  "options": {
                    "one": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " night"
                      }]
                    },
                    "other": {
                      "value": [{
                        "type": 7
                      }, {
                        "type": 0,
                        "value": " nights"
                      }]
                    }
                  },
                  "offset": 0,
                  "pluralType": "cardinal"
                }],
                values: {
                  nights: selectedDates.length
                }
              })
            })]
          })
        })]
      })]
    })]
  });
};