import * as Sentry from '@sentry/nextjs';
import { proxy } from 'valtio';
import { ToastProps } from '../components/Toast';

/**
 * Limit the number of toasts shown at a time to avoid
 * performance overhead with too many toasts.
 */
const TOAST_LIMIT = 20;

/**
 * The max amount of time (ms) toast item state remain
 * in the store after being dismissed before it gets
 * cleaned up.
 */
const TOAST_DISMISSED_CLEANUP_DELAY = 100_000;
export type ToastItem = ToastProps & {
  id: string;
  delay?: number;
};
export type ToastStore = {
  toasts: ToastItem[];
};

/**
 * Keeps track of all toasts that are shown to the user.
 * The toasts are stored in the store in order of
 * their creation time.
 *
 * Inspired by shadcn/ui, but converted to use valtio.
 */
export const toastStore = proxy<ToastStore>({
  toasts: []
});
const cleanupTimeouts = new Map<string, NodeJS.Timeout>();
export const toastActions = {
  add(toast: ToastItem) {
    // If the toast is already open, we don't need to add it
    if (toastStore.toasts.some(t => t.id === toast.id && t.open)) {
      return;
    }
    const appendToast = () => {
      if (toast.notificationType === 'error') {
        Sentry.captureMessage(`Error toast: ${toast.headline} \n${toast.description}`);
      }
      toastStore.toasts = [toast, ...toastStore.toasts].slice(0, TOAST_LIMIT);
    };
    if (toast.delay && toast.delay > 0) {
      setTimeout(appendToast, toast.delay);
    } else {
      appendToast();
    }
  },
  update(toast: Partial<ToastItem>) {
    toastStore.toasts = toastStore.toasts.map(t => t.id === toast.id ? {
      ...t,
      ...toast
    } : t);
  },
  dismiss(toastId?: string) {
    const toastsToRemove = toastId ? [toastId] : toastStore.toasts.map(t => t.id);
    toastsToRemove.forEach(toastId => {
      if (cleanupTimeouts.has(toastId)) {
        return;
      }

      // Remove toast state from store with a timeout to clean
      // up toasts state after the toast has been closed
      const timeout = setTimeout(() => {
        cleanupTimeouts.delete(toastId);
        toastStore.toasts = toastStore.toasts.filter(t => t.id !== toastId);
      }, TOAST_DISMISSED_CLEANUP_DELAY);
      cleanupTimeouts.set(toastId, timeout);
    });
    toastStore.toasts = toastStore.toasts.map(toastItem => toastItem.id === toastId || toastId === undefined ? {
      ...toastItem,
      open: false
    } : toastItem);
  }
};