import { ComponentPropsWithoutRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { ListingStatsProps, ListingStatsSectionType } from '.';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type StatBoxProps = {
  section: ListingStatsSectionType;
  loading?: boolean;
} & Pick<ListingStatsProps, 'onStatsSectionViewed'> & ComponentPropsWithoutRef<'section'>;
export const StatBox = ({
  section,
  loading,
  onStatsSectionViewed,
  ...restProps
}: StatBoxProps) => {
  const [inViewRef] = useInView({
    threshold: 0.5,
    triggerOnce: true,
    onChange(inView) {
      if (inView) {
        onStatsSectionViewed?.({
          section
        });
      }
    },
    skip: loading
  });
  return _jsx("section", {
    ref: inViewRef,
    css: ["position:relative;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;}", loading && {
      "@keyframes pulse": {
        "50%": {
          "opacity": "0.5"
        },
        "0%, 100%": {
          "opacity": "1"
        }
      },
      "animation": "pulse 2s ease-in-out both alternate infinite"
    }, process.env.NODE_ENV === "production" ? "" : ";label:StatBox;", process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvcGFja2FnZXMvc2hhcmVkL2NvbXBvbmVudHMvTGlzdGluZ1N0YXRzL3NoYXJlZC50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBbUNNIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvcGFja2FnZXMvc2hhcmVkL2NvbXBvbmVudHMvTGlzdGluZ1N0YXRzL3NoYXJlZC50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBDb21wb25lbnRQcm9wc1dpdGhvdXRSZWYgfSBmcm9tICdyZWFjdCdcbmltcG9ydCB7IHVzZUluVmlldyB9IGZyb20gJ3JlYWN0LWludGVyc2VjdGlvbi1vYnNlcnZlcidcbmltcG9ydCB0dyBmcm9tICd0d2luLm1hY3JvJ1xuXG5pbXBvcnQgeyBMaXN0aW5nU3RhdHNQcm9wcywgTGlzdGluZ1N0YXRzU2VjdGlvblR5cGUgfSBmcm9tICcuJ1xuXG50eXBlIFN0YXRCb3hQcm9wcyA9IHtcbiAgc2VjdGlvbjogTGlzdGluZ1N0YXRzU2VjdGlvblR5cGVcbiAgbG9hZGluZz86IGJvb2xlYW5cbn0gJiBQaWNrPExpc3RpbmdTdGF0c1Byb3BzLCAnb25TdGF0c1NlY3Rpb25WaWV3ZWQnPiAmXG4gIENvbXBvbmVudFByb3BzV2l0aG91dFJlZjwnc2VjdGlvbic+XG5cbmV4cG9ydCBjb25zdCBTdGF0Qm94ID0gKHtcbiAgc2VjdGlvbixcbiAgbG9hZGluZyxcbiAgb25TdGF0c1NlY3Rpb25WaWV3ZWQsXG4gIC4uLnJlc3RQcm9wc1xufTogU3RhdEJveFByb3BzKSA9PiB7XG4gIGNvbnN0IFtpblZpZXdSZWZdID0gdXNlSW5WaWV3KHtcbiAgICB0aHJlc2hvbGQ6IDAuNSxcbiAgICB0cmlnZ2VyT25jZTogdHJ1ZSxcbiAgICBvbkNoYW5nZShpblZpZXcpIHtcbiAgICAgIGlmIChpblZpZXcpIHtcbiAgICAgICAgb25TdGF0c1NlY3Rpb25WaWV3ZWQ/Lih7XG4gICAgICAgICAgc2VjdGlvbixcbiAgICAgICAgfSlcbiAgICAgIH1cbiAgICB9LFxuICAgIHNraXA6IGxvYWRpbmcsXG4gIH0pXG5cbiAgcmV0dXJuIChcbiAgICA8c2VjdGlvblxuICAgICAgcmVmPXtpblZpZXdSZWZ9XG4gICAgICB0dz1cInJlbGF0aXZlIGJnLXdoaXRlIHNoYWRvdy13aWRnZXQgcm91bmRlZCBubDpwLTMgbGc6cC0zLjVcIlxuICAgICAgY3NzPXtsb2FkaW5nICYmIHR3YGFuaW1hdGUtcHVsc2VgfVxuICAgICAgey4uLnJlc3RQcm9wc31cbiAgICAvPlxuICApXG59XG4iXX0= */"],
    ...restProps
  });
};