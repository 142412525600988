import { CheckRadioFieldProps } from '../../../../forms/CheckRadioField';
import CheckRadioField from '../../../../forms/CheckRadioField';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CheckRadioBox = (props: CheckRadioFieldProps) => _jsx(CheckRadioField, {
  ...props,
  css: ["display:flex;cursor:pointer;align-items:flex-start;gap:1rem;border-radius:0.25rem;border-width:1px;--tw-border-opacity:1;border-color:rgb(204 210 210 / var(--tw-border-opacity));padding:2rem;:hover{--tw-border-opacity:1;border-color:rgb(54 103 82 / var(--tw-border-opacity));}@media (min-width: 40em){transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-timing-function:cubic-bezier(0.4, 0, 1, 1);transition-duration:100ms;}", props.checked && {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(54 103 82 / var(--tw-border-opacity))",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(235 245 236 / var(--tw-bg-opacity))"
  }, process.env.NODE_ENV === "production" ? "" : ";label:CheckRadioBox;", process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvcGFja2FnZXMvc2hhcmVkL2NvbXBvbmVudHMvQ2FsZW5kYXIvQXNpZGUvQ2FsZW5kYXJEYXRlQ29uZmlndXJhdGlvbi92aWV3cy9zaGFyZWQudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVNJIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvcGFja2FnZXMvc2hhcmVkL2NvbXBvbmVudHMvQ2FsZW5kYXIvQXNpZGUvQ2FsZW5kYXJEYXRlQ29uZmlndXJhdGlvbi92aWV3cy9zaGFyZWQudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3IGZyb20gJ3R3aW4ubWFjcm8nXG5cbmltcG9ydCB7IENoZWNrUmFkaW9GaWVsZFByb3BzIH0gZnJvbSAnLi4vLi4vLi4vLi4vZm9ybXMvQ2hlY2tSYWRpb0ZpZWxkJ1xuaW1wb3J0IENoZWNrUmFkaW9GaWVsZCBmcm9tICcuLi8uLi8uLi8uLi9mb3Jtcy9DaGVja1JhZGlvRmllbGQnXG5cbmV4cG9ydCBjb25zdCBDaGVja1JhZGlvQm94ID0gKHByb3BzOiBDaGVja1JhZGlvRmllbGRQcm9wcykgPT4gKFxuICA8Q2hlY2tSYWRpb0ZpZWxkXG4gICAgey4uLnByb3BzfVxuICAgIHR3PVwiZmxleCBpdGVtcy1zdGFydCBnYXAtMyBwLTQgYm9yZGVyIHJvdW5kZWQgYm9yZGVyLWZlYnJ1YXJ5LXRlcnRpYW4gY3Vyc29yLXBvaW50ZXIgbnM6KHRyYW5zaXRpb24gZHVyYXRpb24tMTAwIGVhc2UtaW4pIGhvdmVyOmJvcmRlci1zcHJpbmdcIlxuICAgIGNzcz17cHJvcHMuY2hlY2tlZCAmJiB0d2BiZy1tb3NzIGJvcmRlci1zcHJpbmdgfVxuICAvPlxuKVxuIl19 */"]
});