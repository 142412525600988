import { addDays, startOfWeek } from 'date-fns';
import { useIntl } from 'react-intl';
import { USER_TIME_ZONE } from '../../utils/time';
import type { WeekStartsOn } from './types';
const LANDFOLK_EPOCH = new Date(2021, 1);
export function useWeekdayNames(weekStartsOn: WeekStartsOn, format: Intl.DateTimeFormatOptions['weekday']) {
  const intl = useIntl();
  const start = startOfWeek(LANDFOLK_EPOCH, {
    weekStartsOn
  });
  const names: string[] = [];
  let i = 0;
  while (i < 7) {
    names.push(intl.formatDate(addDays(start, i++), {
      weekday: format,
      timeZone: USER_TIME_ZONE
    }));
  }
  return names;
}