function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Pill from '@landfolk/shared/components/Pill';
import Icon from '@landfolk/shared/icons/Calendar.svg';
import { Fragment } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "zfzvjb-PillExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1k8sf56-PillExample",
  styles: "max-width:65ch;label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "nyrrye-PillExample",
  styles: "margin-bottom:1rem;label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1b4nhva-PillExample",
  styles: "margin-bottom:2rem;label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "jg11g",
  styles: "margin-bottom:0.5rem;font-size:0.875rem"
} : {
  name: "fijsvo-PillExample",
  styles: "margin-bottom:0.5rem;font-size:0.875rem;label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "1ewdhoa",
  styles: "margin-bottom:2rem;display:flex;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));}white-space:nowrap"
} : {
  name: "6jo14l-PillExample",
  styles: "margin-bottom:2rem;display:flex;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));}white-space:nowrap;label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "1rmsf2e-PillExample",
  styles: "margin-right:0.35rem;label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "156h4kx",
  styles: "margin-right:0.35rem"
} : {
  name: "1rmsf2e-PillExample",
  styles: "margin-right:0.35rem;label:PillExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function PillExample() {
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "Pill"
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "Pills are a group of options that users can select, for example to filter a range of data. In most cases, they can select more than one option, but in some instances, an option is exclusive."
      }), _jsx("p", {
        css: _ref4,
        children: "The content of the pills should be as short as possible. Use icons to support the content and help with understanding."
      })]
    }), [false, true].map((large, i) => [false, true].map((borderless, j) => _jsxs(Fragment, {
      children: [_jsxs("h3", {
        css: _ref5,
        children: [large ? 'Large' : 'Default', borderless ? ' (no border)' : '']
      }), _jsxs("div", {
        css: _ref6,
        children: [_jsx(Pill, {
          large: large,
          borderless: borderless,
          children: "Default"
        }), _jsx(Pill, {
          active: true,
          large: large,
          borderless: borderless,
          children: "Active"
        }), _jsxs(Pill, {
          large: large,
          borderless: borderless,
          children: [_jsx(Icon, {
            css: _ref7
          }), " With an icon"]
        }), _jsxs(Pill, {
          active: true,
          large: large,
          borderless: borderless,
          children: [_jsx(Icon, {
            css: _ref8
          }), " Active with an icon"]
        })]
      })]
    }, `${i}${j}`)))]
  });
}