function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ComponentPropsWithoutRef } from 'react';
import DateConfigurationForm from './Form';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const dateConfigurationViews = (['mixedAvailability', 'mixedInstantBooking', 'minNights', 'pricing'] as const);
export type DateConfigurationViews = (typeof dateConfigurationViews)[number];
var _ref = process.env.NODE_ENV === "production" ? {
  name: "173h2hf",
  styles: "flex-shrink:1;flex-grow:1;padding:2rem"
} : {
  name: "jwrvp4-CalendarDateConfiguration",
  styles: "flex-shrink:1;flex-grow:1;padding:2rem;label:CalendarDateConfiguration;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "znht3n",
  styles: "padding-bottom:1rem"
} : {
  name: "fkn4s3-CalendarDateConfiguration",
  styles: "padding-bottom:1rem;label:CalendarDateConfiguration;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function CalendarDateConfiguration(props: ComponentPropsWithoutRef<'section'>) {
  return _jsx("div", {
    css: _ref,
    ...props,
    children: _jsx(DateConfigurationForm, {
      css: _ref2
    }, "dateConfigurationForm")
  });
}