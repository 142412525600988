function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import CheckRadioField from '@landfolk/shared/components/forms/CheckRadioField';
import DateField from '@landfolk/shared/components/forms/DateField';
import EmailField from '@landfolk/shared/components/forms/EmailField';
import NumberField from '@landfolk/shared/components/forms/NumberField';
import PasswordField from '@landfolk/shared/components/forms/PasswordField';
import TelField from '@landfolk/shared/components/forms/TelField';
import TextareaField from '@landfolk/shared/components/forms/TextareaField';
import TextField from '@landfolk/shared/components/forms/TextField';
import CheckOut from '@landfolk/shared/icons/Checkout.svg';
import { useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "j0f3y7-InputExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "wy896r",
  styles: "display:flex;flex-direction:column;@media (min-width: 64em){flex-direction:row;}"
} : {
  name: "39wah0-InputExample",
  styles: "display:flex;flex-direction:column;@media (min-width: 64em){flex-direction:row;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "m0wqu",
  styles: "max-width:32rem;@media (min-width: 64em){width:32rem;}"
} : {
  name: "1bvohir-InputExample",
  styles: "max-width:32rem;@media (min-width: 64em){width:32rem;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1sv85d9",
  styles: "max-width:32rem;@media (min-width: 64em){margin-left:4rem;width:32rem;}"
} : {
  name: "q1cyz3-InputExample",
  styles: "max-width:32rem;@media (min-width: 64em){margin-left:4rem;width:32rem;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "dng8x1",
  styles: "margin-bottom:2rem;font-size:1.25rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1.25rem;line-height:1.5rem;}"
} : {
  name: "1568r0c-InputExample",
  styles: "margin-bottom:2rem;font-size:1.25rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1.25rem;line-height:1.5rem;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "1vjxmwf",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc"
} : {
  name: "mhgfcd-InputExample",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "wy896r",
  styles: "display:flex;flex-direction:column;@media (min-width: 64em){flex-direction:row;}"
} : {
  name: "39wah0-InputExample",
  styles: "display:flex;flex-direction:column;@media (min-width: 64em){flex-direction:row;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "m0wqu",
  styles: "max-width:32rem;@media (min-width: 64em){width:32rem;}"
} : {
  name: "1bvohir-InputExample",
  styles: "max-width:32rem;@media (min-width: 64em){width:32rem;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "fdtzpt-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "nyfdx3",
  styles: "margin-bottom:3rem"
} : {
  name: "pk3hl5-InputExample",
  styles: "margin-bottom:3rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "fdtzpt-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "nyfdx3",
  styles: "margin-bottom:3rem"
} : {
  name: "pk3hl5-InputExample",
  styles: "margin-bottom:3rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "fdtzpt-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "nyfdx3",
  styles: "margin-bottom:3rem"
} : {
  name: "pk3hl5-InputExample",
  styles: "margin-bottom:3rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "fdtzpt-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "nyfdx3",
  styles: "margin-bottom:3rem"
} : {
  name: "pk3hl5-InputExample",
  styles: "margin-bottom:3rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "fdtzpt-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "1g866qf",
  styles: "position:relative;margin-bottom:1rem"
} : {
  name: "dzovak-InputExample",
  styles: "position:relative;margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "uzei5z",
  styles: "position:relative;margin-bottom:3rem"
} : {
  name: "70wkcj-InputExample",
  styles: "position:relative;margin-bottom:3rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "fdtzpt-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "1g866qf",
  styles: "position:relative;margin-bottom:1rem"
} : {
  name: "dzovak-InputExample",
  styles: "position:relative;margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "bjn8wh",
  styles: "position:relative"
} : {
  name: "ap1s0u-InputExample",
  styles: "position:relative;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref23 = process.env.NODE_ENV === "production" ? {
  name: "1sv85d9",
  styles: "max-width:32rem;@media (min-width: 64em){margin-left:4rem;width:32rem;}"
} : {
  name: "q1cyz3-InputExample",
  styles: "max-width:32rem;@media (min-width: 64em){margin-left:4rem;width:32rem;};label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref24 = process.env.NODE_ENV === "production" ? {
  name: "z3a8gt",
  styles: "margin-bottom:1rem;display:flex;flex-direction:column;gap:1rem"
} : {
  name: "imxe8h-InputExample",
  styles: "margin-bottom:1rem;display:flex;flex-direction:column;gap:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref25 = process.env.NODE_ENV === "production" ? {
  name: "180pqcn",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "147j9m7-InputExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref26 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1pcxfd6-InputExample",
  styles: "margin-bottom:2rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref27 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1pcxfd6-InputExample",
  styles: "margin-bottom:2rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref28 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1pcxfd6-InputExample",
  styles: "margin-bottom:2rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref29 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1pcxfd6-InputExample",
  styles: "margin-bottom:2rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref30 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1pcxfd6-InputExample",
  styles: "margin-bottom:2rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref31 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1pcxfd6-InputExample",
  styles: "margin-bottom:2rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref32 = process.env.NODE_ENV === "production" ? {
  name: "1mt598w",
  styles: "margin-bottom:1rem;margin-top:2rem;font-weight:600"
} : {
  name: "16yda3a-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-weight:600;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref33 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "ntmdhg-InputExample",
  styles: "margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref34 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "ntmdhg-InputExample",
  styles: "margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref35 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1pcxfd6-InputExample",
  styles: "margin-bottom:2rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref36 = process.env.NODE_ENV === "production" ? {
  name: "1mt598w",
  styles: "margin-bottom:1rem;margin-top:2rem;font-weight:600"
} : {
  name: "16yda3a-InputExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-weight:600;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref37 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "ntmdhg-InputExample",
  styles: "margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref38 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "ntmdhg-InputExample",
  styles: "margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref39 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "ntmdhg-InputExample",
  styles: "margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref40 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "ntmdhg-InputExample",
  styles: "margin-bottom:1rem;label:InputExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function InputExample() {
  const [setErrorVisibility, setSetErrorVisibility] = useState(false);
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "Input"
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsxs("div", {
        css: _ref3,
        children: [_jsx("p", {
          children: "Input fields allow users to submit different kinds of data. Input fields have three possible states that describe whether a user can, cannot or must fill out an input, helping the user navigate forms settings."
        }), _jsx("br", {}), _jsx("p", {
          children: "You can interact with all of the inputs."
        })]
      }), _jsxs("div", {
        css: _ref4,
        children: [_jsx("h4", {
          css: _ref5,
          children: "Best practices"
        }), _jsxs("ul", {
          css: _ref6,
          children: [_jsx("li", {
            children: "Guide as much as possible through labels, placeholders, etc."
          }), _jsx("li", {
            children: "Only use textfields when the input is not used as data"
          }), _jsx("li", {
            children: "Avoid too many input fields on one page"
          })]
        })]
      })]
    }), _jsxs("div", {
      css: _ref7,
      children: [_jsxs("div", {
        css: _ref8,
        children: [_jsx("h2", {
          css: _ref9,
          children: "Default"
        }), _jsx(TextField, {
          label: "Label",
          css: _ref10
        }), _jsx("h2", {
          css: _ref11,
          children: "Disabled"
        }), _jsx(TextField, {
          disabled: true,
          label: "Label",
          css: _ref12
        }), _jsx("h2", {
          css: _ref13,
          children: "Required"
        }), _jsx(TextField, {
          required: true,
          label: "Label",
          css: _ref14
        }), _jsx("h2", {
          css: _ref15,
          children: "With a custom placeholder"
        }), _jsx(TextField, {
          placeholder: "Please select...",
          label: "Label",
          css: _ref16
        }), _jsx("h2", {
          css: _ref17,
          children: "With a prefix"
        }), _jsx(TextField, {
          css: _ref18,
          label: "Label",
          placeholder: "0.00",
          prefix: "EUR"
        }), _jsx(TextField, {
          css: _ref19,
          label: "Label",
          placeholder: "0.00",
          disabled: true,
          prefix: "EUR"
        }), _jsx("h2", {
          css: _ref20,
          children: "With a suffix"
        }), _jsx(TextField, {
          css: _ref21,
          label: "Label",
          suffix: _jsx(CheckOut, {})
        }), _jsx(TextField, {
          css: _ref22,
          label: "Label",
          disabled: true,
          suffix: _jsx(CheckOut, {})
        })]
      }), _jsxs("div", {
        css: _ref23,
        children: [_jsxs("header", {
          css: _ref24,
          children: [_jsx("h2", {
            css: _ref25,
            children: "Variations"
          }), _jsx(CheckRadioField, {
            type: "checkbox",
            label: "Show error states",
            onClick: () => setSetErrorVisibility(prev => !prev)
          })]
        }), _jsx(DateField, {
          label: "Date input",
          css: _ref26,
          errorMessage: setErrorVisibility && 'Error message'
        }), _jsx(EmailField, {
          label: "Email input",
          css: _ref27,
          errorMessage: setErrorVisibility && 'Error message'
        }), _jsx(NumberField, {
          label: "Number input",
          css: _ref28,
          errorMessage: setErrorVisibility && 'Error message'
        }), _jsx(PasswordField, {
          label: "Password input",
          css: _ref29,
          errorMessage: setErrorVisibility && 'Error message'
        }), _jsx(TelField, {
          label: "Phone number input",
          css: _ref30,
          errorMessage: setErrorVisibility && 'Error message'
        }), _jsx(TextareaField, {
          label: "Textarea input",
          css: _ref31,
          errorMessage: setErrorVisibility && 'Error message'
        }), _jsx("p", {
          css: _ref32,
          children: "Checkboxes"
        }), _jsx(CheckRadioField, {
          type: "checkbox",
          label: "Unchecked checkbox",
          css: _ref33
        }), _jsx(CheckRadioField, {
          type: "checkbox",
          checked: true,
          label: "Checked checkbox",
          css: _ref34
        }), _jsx(CheckRadioField, {
          type: "checkbox",
          label: "Checkbox on multiple lines that where you can have a long description for the checkbox, especially useful for newsletter signup, privacy policy or other agreements.",
          css: _ref35
        }), _jsx("p", {
          css: _ref36,
          children: "Radio buttons"
        }), _jsx(CheckRadioField, {
          type: "radio",
          name: "radioGroup",
          label: "Unchecked radio button",
          css: _ref37
        }), _jsx(CheckRadioField, {
          type: "radio",
          name: "radioGroup",
          checked: true,
          label: "Checked radio button",
          css: _ref38
        }), _jsx(CheckRadioField, {
          type: "radio",
          name: "radioGroup",
          label: "Radio button on multiple lines where you can write a longer description for the radio option.",
          css: _ref39
        }), _jsx(CheckRadioField, {
          type: "radio",
          name: "radioGroup",
          checked: true,
          label: "Checked radio with support text",
          description: "Here you can add asterix information, support information or similar helpful information that adds context, but isn't necessary to read.",
          css: _ref40
        })]
      })]
    })]
  });
}