import { useIntl } from 'react-intl';
import { formatPossessive } from '../utils/intl';

/**
 * Get possessive form of any nown adapted to the rules of the language provided in `locale`
 */
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export default function FormattedPossessive({
  value
}: {
  value: string;
}) {
  const {
    locale
  } = useIntl();
  return _jsx(_Fragment, {
    children: formatPossessive(value, locale)
  });
}