function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Pill from '@landfolk/shared/components/Pill';
import Tooltip from '@landfolk/shared/components/Tooltip';
import AdvancedCloudAndFlag from '@landfolk/shared/icons/advanced/CloudAndFlag.svg';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "71pt0k-TooltipExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1vhfiv-TooltipExample",
  styles: "max-width:65ch;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1hwnxqs-TooltipExample",
  styles: "margin-bottom:2rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1kpbd8q",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;}"
} : {
  name: "1vn5wt8-TooltipExample",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;};label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1vjxmwf",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc"
} : {
  name: "7ukk8u-TooltipExample",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "180pqcn",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "153a9j-TooltipExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "1g8sw7e-TooltipExample",
  styles: "margin-top:1rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1wk9v1j",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem"
} : {
  name: "5ukfiz-TooltipExample",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "1g8sw7e-TooltipExample",
  styles: "margin-top:1rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "1wk9v1j",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem"
} : {
  name: "5ukfiz-TooltipExample",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "1g8sw7e-TooltipExample",
  styles: "margin-top:1rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "1wk9v1j",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem"
} : {
  name: "5ukfiz-TooltipExample",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "1g8sw7e-TooltipExample",
  styles: "margin-top:1rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "1wk9v1j",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem"
} : {
  name: "5ukfiz-TooltipExample",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "akdqam",
  styles: "margin-top:1rem"
} : {
  name: "1g8sw7e-TooltipExample",
  styles: "margin-top:1rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "1wk9v1j",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem"
} : {
  name: "5ukfiz-TooltipExample",
  styles: "margin-top:0.5rem;margin-bottom:0.5rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "frtw6l",
  styles: "margin-top:4rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "q8aeoy-TooltipExample",
  styles: "margin-top:4rem;font-size:1.5rem;line-height:1.5rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "cf77n8",
  styles: "display:flex;max-width:32rem;align-items:center;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1rem * var(--tw-space-x-reverse));margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)));}padding:1rem;font-size:0.75rem;line-height:1.5"
} : {
  name: "l7xndo-TooltipExample",
  styles: "display:flex;max-width:32rem;align-items:center;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1rem * var(--tw-space-x-reverse));margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)));}padding:1rem;font-size:0.75rem;line-height:1.5;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "1b4nml1",
  styles: "height:2rem;width:2rem;flex-shrink:0"
} : {
  name: "1i5vlcl-TooltipExample",
  styles: "height:2rem;width:2rem;flex-shrink:0;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "1gqp175",
  styles: "margin-top:2rem;margin-bottom:2rem"
} : {
  name: "11qz8ue-TooltipExample",
  styles: "margin-top:2rem;margin-bottom:2rem;label:TooltipExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function TooltipExample() {
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "Tooltip"
    }), _jsxs("section", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "Tooltips provide extra information to users to clarify content and add context. They can be added to any kind of content: buttons, icons, graphs, etc. In most instances, the tooltip is invisible, but if it adds context to a paragraph, always include a (?)."
      }), _jsx("h3", {
        css: _ref4,
        children: "Best practices"
      }), _jsxs("ul", {
        css: _ref5,
        children: [_jsx("li", {
          children: "Keep the content as short as possible"
        }), _jsx("li", {
          children: "Use to explain visual elements"
        }), _jsx("li", {
          children: "Use to provide context for what happens when using a button"
        }), _jsx("li", {
          children: "Never include urgent or important information"
        })]
      })]
    }), _jsx("h2", {
      css: _ref6,
      children: "Basic"
    }), _jsx("p", {
      css: _ref7,
      children: "Default"
    }), _jsx(Tooltip, {
      content: _jsx("p", {
        children: "This is a simple tooltip that shows on hover"
      }),
      children: _jsx(Pill, {
        css: _ref8,
        children: "Hover here for a while"
      })
    }), _jsx("p", {
      css: _ref9,
      children: "Info"
    }), _jsx(Tooltip, {
      type: "info",
      openDelay: 400,
      content: _jsx("p", {
        children: "This is coloured blue to signify information"
      }),
      children: _jsx(Pill, {
        css: _ref10,
        children: "Info on hover"
      })
    }), _jsx("p", {
      css: _ref11,
      children: "Warning (right)"
    }), _jsx(Tooltip, {
      type: "warning",
      openDelay: 400,
      positioning: {
        placement: 'right'
      },
      content: _jsx("p", {
        children: "This has a warning yellow shade"
      }),
      children: _jsx(Pill, {
        css: _ref12,
        children: "Warning on hover"
      })
    }), _jsx("p", {
      css: _ref13,
      children: "Confirmation (bottom + extra offset)"
    }), _jsx(Tooltip, {
      type: "confirmation",
      openDelay: 400,
      positioning: {
        placement: 'bottom',
        gutter: 12
      },
      content: _jsx("p", {
        children: "This has the mossy colour of confirmation"
      }),
      children: _jsx(Pill, {
        css: _ref14,
        children: "Confirmation on hover"
      })
    }), _jsx("p", {
      css: _ref15,
      children: "Force opened tooltip"
    }), _jsx(Tooltip, {
      content: _jsx("p", {
        children: "This is tooltip will stay open as long as its open prop is true"
      }),
      positioning: {
        placement: 'right-end',
        fitViewport: true,
        overflowPadding: 32
      },
      open: true,
      children: _jsx(Pill, {
        css: _ref16,
        children: "Permanent tooltip"
      })
    }), _jsx("h2", {
      css: _ref17,
      children: "Custom content"
    }), _jsx(Tooltip, {
      openDelay: 400,
      positioning: {
        placement: 'bottom-start',
        gutter: 8,
        shift: -32
      },
      content: _jsxs("div", {
        css: _ref18,
        children: [_jsx(AdvancedCloudAndFlag, {
          css: _ref19
        }), _jsx("p", {
          children: "Landfolk is 100% Danish-owned. We are located in the heart of Aarhus, and you are more than welcome to stop by and say hi!"
        })]
      }),
      children: _jsx(Pill, {
        css: _ref20,
        children: "Hover to get some info"
      })
    })]
  });
}