export function roundNumberLikeAHuman(int: number) {
  let roundToNearest = 1;
  const abs = Math.abs(int);
  if (abs >= 1000000) {
    roundToNearest = 100000;
  } else if (abs >= 100000) {
    roundToNearest = 10000;
  } else if (abs >= 10000) {
    roundToNearest = 1000;
  } else if (abs >= 100) {
    roundToNearest = 100;
  } else if (abs >= 10) {
    roundToNearest = 10;
  }
  return Math.ceil(int / roundToNearest) * roundToNearest;
}