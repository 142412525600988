function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Spinner from '@landfolk/shared/components/Spinner';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "3g5hao-SpinnerExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "ctf1wu-SpinnerExample",
  styles: "max-width:65ch;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "1yewk05-SpinnerExample",
  styles: "margin-bottom:1rem;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "jg11g",
  styles: "margin-bottom:0.5rem;font-size:0.875rem"
} : {
  name: "5gh1dd-SpinnerExample",
  styles: "margin-bottom:0.5rem;font-size:0.875rem;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "15qkrrl-SpinnerExample",
  styles: "margin-bottom:2rem;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "938vo",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity))"
} : {
  name: "7t7n9g-SpinnerExample",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity));label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "jg11g",
  styles: "margin-bottom:0.5rem;font-size:0.875rem"
} : {
  name: "5gh1dd-SpinnerExample",
  styles: "margin-bottom:0.5rem;font-size:0.875rem;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "15qkrrl-SpinnerExample",
  styles: "margin-bottom:2rem;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "938vo",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity))"
} : {
  name: "7t7n9g-SpinnerExample",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity));label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "jg11g",
  styles: "margin-bottom:0.5rem;font-size:0.875rem"
} : {
  name: "5gh1dd-SpinnerExample",
  styles: "margin-bottom:0.5rem;font-size:0.875rem;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "dqu15s",
  styles: "margin-bottom:2rem;display:flex;align-items:center;gap:2rem"
} : {
  name: "1edublu-SpinnerExample",
  styles: "margin-bottom:2rem;display:flex;align-items:center;gap:2rem;label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "938vo",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity))"
} : {
  name: "7t7n9g-SpinnerExample",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity));label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "938vo",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity))"
} : {
  name: "7t7n9g-SpinnerExample",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity));label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "938vo",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity))"
} : {
  name: "7t7n9g-SpinnerExample",
  styles: "--tw-text-opacity:1;color:rgb(54 103 82 / var(--tw-text-opacity));label:SpinnerExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function SpinnerExample() {
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "Spinner"
    }), _jsx("div", {
      css: _ref2,
      children: _jsx("p", {
        css: _ref3,
        children: "Spinners are used to indicate that an action is in progress. They can be normal, compact or a custom size. The line thickness can also be controlled."
      })
    }), _jsx("h3", {
      css: _ref4,
      children: "Default"
    }), _jsx("div", {
      css: _ref5,
      children: _jsx(Spinner, {
        css: _ref6
      })
    }), _jsx("h3", {
      css: _ref7,
      children: "Compact"
    }), _jsx("div", {
      css: _ref8,
      children: _jsx(Spinner, {
        compact: true,
        css: _ref9
      })
    }), _jsx("h3", {
      css: _ref10,
      children: "Custom size and line thickness"
    }), _jsxs("div", {
      css: _ref11,
      children: [_jsx(Spinner, {
        size: 12,
        thickness: 1,
        css: _ref12
      }), _jsx(Spinner, {
        size: 32,
        thickness: 2,
        css: _ref13
      }), _jsx(Spinner, {
        size: 48,
        thickness: 8,
        css: _ref14
      })]
    })]
  });
}