function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Button from '@landfolk/shared/components/Button';
import { Confetti } from '@landfolk/shared/components/Confetti';
import { ConfettiClick } from '@landfolk/shared/components/Confetti/Click';
import { ConfettiPop } from '@landfolk/shared/components/Confetti/Pop';
import { ConfettiRain } from '@landfolk/shared/components/Confetti/Rain';
import { useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "145eg3a-TabsExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1nrevy2",
  styles: "position:relative;display:inline-block"
} : {
  name: "1sct44p-TabsExample",
  styles: "position:relative;display:inline-block;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1hqwpit-TabsExample",
  styles: "max-width:65ch;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "bxb8jk-TabsExample",
  styles: "margin-bottom:1rem;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "bxb8jk-TabsExample",
  styles: "margin-bottom:1rem;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "y5857-TabsExample",
  styles: "margin-bottom:2rem;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "w1xf4t",
  styles: "display:flex;flex-wrap:wrap;gap:2rem"
} : {
  name: "ei6awm-TabsExample",
  styles: "display:flex;flex-wrap:wrap;gap:2rem;label:TabsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function TabsExample() {
  const [celebratoryConfetti, setCelebratoryConfetti] = useState<number[]>([]);
  const [popConfetti, setPopConfetti] = useState<number[]>([]);
  const [rainConfetti, setRainConfetti] = useState<number[]>([]);
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: _jsxs("span", {
        css: _ref2,
        children: ["Confetti \uD83C\uDF8A", popConfetti.map(key => _jsx(ConfettiPop, {}, key))]
      })
    }), _jsxs("section", {
      css: _ref3,
      children: [_jsx("p", {
        css: _ref4,
        children: "We like to celebrate our users. And is there a better way to celebrate than with confetti?"
      }), _jsx("p", {
        css: _ref5,
        children: "No."
      }), _jsx("p", {
        css: _ref6,
        children: "This is the way. \uD83C\uDF89"
      })]
    }), _jsxs("div", {
      css: _ref7,
      children: [_jsx(Button, {
        onClick: () => setCelebratoryConfetti(c => [...c, Date.now()]),
        children: "Cannons"
      }), _jsx(Button, {
        onClick: () => setPopConfetti(c => [...c, Date.now()]),
        children: "Pop"
      }), _jsx(ConfettiClick, {
        children: _jsx(Button, {
          children: "Click"
        })
      }), _jsx(Button, {
        onClick: () => setRainConfetti(c => [...c, Date.now()]),
        children: "Rain"
      })]
    }), celebratoryConfetti.map(key => _jsx(Confetti, {}, key)), rainConfetti.map(key => _jsx(ConfettiRain, {}, key))]
  });
}