import { ComponentPropsWithoutRef } from 'react';
import Calendar from '../../../icons/Calendar.svg';
import Checkmark from '../../../icons/Checkmark.svg';
import Clock from '../../../icons/Clock.svg';
import Filter from '../../../icons/Filter.svg';
import House from '../../../icons/House.svg';
import Person from '../../../icons/Person.svg';
import Nothing from '../../Nothing';
import type { BookingState, CalendarEventReason } from '../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EventIcon = ({
  state,
  ...restProps
}: {
  state: BookingState | CalendarEventReason | 'CURRENT';
} & ComponentPropsWithoutRef<'svg'>) => {
  const iconStyle = {
    "marginRight": "0.25rem",
    "display": "inline-block",
    "height": "0.625rem",
    "width": "0.625rem",
    "@media (min-width: 40em)": {
      "marginTop": "-0.1rem",
      "height": "0.75rem",
      "width": "0.75rem"
    }
  };
  switch (state) {
    case 'PENDING':
      return _jsx(Clock, {
        css: iconStyle,
        ...restProps
      });
    case 'CONFIRMED':
    case 'COMPLETED':
      return _jsx(Checkmark, {
        css: iconStyle,
        ...restProps
      });
    case 'CURRENT':
      return _jsx(House, {
        css: iconStyle,
        ...restProps
      });
    case 'EXTERNALLY_BOOKED':
      return _jsx(Calendar, {
        css: iconStyle,
        ...restProps
      });
    case 'BLOCKED':
      return _jsx(Person, {
        css: iconStyle,
        ...restProps
      });
    case 'UNDECIDED':
      return _jsx(Filter, {
        css: iconStyle,
        ...restProps
      });
    case 'CLEANING':
    case 'ADVANCE_NOTICE':
    case 'PREPARATION':
    case 'MIN_NIGHTS':
    default:
      return _jsx(Nothing, {});
  }
};