function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ComponentPropsWithoutRef, MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { Confetti } from '.';

/**
 * @description
 * `ConfettiPop` is inspired by `ConfettiClicks`, but slightly slower and lingers a bit longer.
 * It places itself at a random point inside the nearest relative parent.
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "b98nv4",
  styles: "position:absolute;inset:0"
} : {
  name: "wzzbol-ConfettiPop",
  styles: "position:absolute;inset:0;label:ConfettiPop;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const ConfettiPop = ({
  delay = 100,
  randomize = 0.2,
  ...restProps
}: {
  delay?: number;
  randomize?: number;
} & Pick<ComponentPropsWithoutRef<'div'>, 'tw' | 'css' | 'className'>) => {
  const [origin, setOrigin] = useState<{
    x: number;
    y: number;
  } | undefined>();
  const containerRef = (useRef() as MutableRefObject<HTMLDivElement>);
  const popTimeoutRef = useRef<NodeJS.Timeout>();
  useLayoutEffect(() => {
    if (!__IS_BROWSER__ || !containerRef.current) return;
    if (popTimeoutRef.current) {
      clearTimeout(popTimeoutRef.current);
    }
    popTimeoutRef.current = setTimeout(() => {
      if (!containerRef.current) return;
      const bounds = containerRef.current?.getBoundingClientRect();
      const randomX = 0.5 + (-randomize + Math.random() * randomize * 2);
      const randomY = 0.5 + (-randomize + Math.random() * randomize * 2);
      const randomInsideX = bounds.width * randomX;
      const randomInsideY = bounds.height * randomY;
      setOrigin({
        x: (bounds.x + randomInsideX) / window.innerWidth,
        y: (bounds.y + randomInsideY) / window.innerHeight
      });
    }, delay);
  }, [delay, randomize]);
  return _jsx("div", {
    ref: containerRef,
    css: _ref,
    ...restProps,
    children: origin && _jsx(Confetti, {
      options: {
        origin: {
          x: origin.x,
          y: origin.y - 0.015 // Adjust for gravity
        },

        spread: 360,
        startVelocity: 6,
        decay: 0.97,
        ticks: 100,
        gravity: 0.5,
        disableForReducedMotion: true
      }
    }, `${origin.x}-${origin.y}`)
  });
};