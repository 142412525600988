/**
 * Get possessive form of any nown adapted to the rules of the language provided in `locale`
 *
 * Heavily inspired by:
 * - https://github.com/aaronbeall/i18n-possessive/
 * - https://medium.com/@ssandler_35379/hi-aaron-1c7a2c11aa2d
 */

export function formatPossessive(noun: string, locale: string) {
  const n = noun.trimEnd();
  switch (locale) {
    case 'en':
      return `${n}’s`;
    case 'de':
      return n.match(/[sßxz]$/i) ? `${n}’` : `${n}s`;
    case 'da':
      return n.match(/[sxz]$/i) ? `${n}’` : `${n}s`;
    case 'no':
      return n.match(/[sxz]$/i) ? `${n}’` : `${n}s`;
    case 'sv':
      return n.match(/[sxz]$/i) ? `${n}` : `${n}s`;
    default:
      return `${n}’s`;
  }
}