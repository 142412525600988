export const getFileNames = (context: __WebpackModuleApi.RequireContext) => {
  const names = new Set<string>();
  context.keys().forEach((key: string) => {
    const p = key.split('/');
    const name = p[p.length - 1];
    if (name) {
      names.add(name.split('.')[0] || name);
    }
  });
  return names;
};