export const sausage = {
  "position": "relative",
  "left": "33.333333%",
  "zIndex": "10",
  "display": "block",
  "height": "100%",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "@media (max-width: 39.999em)": {
    "height": "1.5rem",
    "overflow": "hidden",
    "textAlign": "center",
    "fontSize": "0",
    "::after": {
      "content": "var(--tw-content)",
      "position": "absolute",
      "inset": "-0.25rem",
      "display": "block",
      "backgroundColor": "transparent",
      "--tw-content": "''"
    }
  },
  "@media (min-width: 40em)": {
    "height": "1.5rem",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "whiteSpace": "nowrap"
  }
};
export const leftCutSausage = {
  "left": "0",
  "borderTopLeftRadius": "0.125rem",
  "borderBottomLeftRadius": "0.125rem",
  "@media (max-width: 39.999em)": {
    "paddingLeft": "0.25rem"
  },
  "@media (min-width: 40em)": {
    "paddingLeft": "0.75rem"
  }
};
export const rightCutSausage = {
  "borderTopRightRadius": "0.125rem",
  "borderBottomRightRadius": "0.125rem",
  "paddingRight": "0",
  "@media (min-width: 40em)": {
    "paddingRight": "0.75rem"
  }
};