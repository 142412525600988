function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Button from '@landfolk/shared/components/Button';
import { ToastProps } from '@landfolk/shared/components/Toast';
import { ToastFeed } from '@landfolk/shared/components/Toast/Feed';
import { useToast } from '@landfolk/shared/components/Toast/useToast';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const examples: ToastProps[] = [{
  headline: 'Here is some info',
  description: 'Info toasts are not very common in the wild, but they are in our toolbox should you need them.',
  notificationType: 'info'
}, {
  description: 'Hello! This is a short info toast.',
  notificationType: 'info'
}, {
  headline: 'Be careful',
  description: 'This is a warning toast. 🥪',
  notificationType: 'warning'
}, {
  description: 'This is a short warning with no headline.',
  notificationType: 'warning'
}, {
  headline: 'An error occured',
  description: 'Of course we prefer users never run into a toast like this, but in case something goes wrong they should know.',
  notificationType: 'error'
}, {
  description: 'This is a longer error toast without a headline, but with a long description text that will wrap to multiple lines.',
  notificationType: 'error'
}, {
  headline: 'What a great success! 🎉',
  description: 'We celebrate you and your clicking of the button.',
  notificationType: 'success'
}, {
  description: 'This is just a short success toast.',
  notificationType: 'success'
}];
let lastRandomToastIndex: number | undefined;
const getRandomToastExample = () => {
  let newRandomToastIndex = Math.floor(Math.random() * examples.length);
  while (lastRandomToastIndex === newRandomToastIndex) {
    newRandomToastIndex = Math.floor(Math.random() * examples.length);
  }
  lastRandomToastIndex = newRandomToastIndex;
  return (examples[newRandomToastIndex] as ToastProps);
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "7lwva8-ToastExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:ToastExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1cmnu9e",
  styles: "margin-bottom:2rem;max-width:65ch"
} : {
  name: "u457us-ToastExample",
  styles: "margin-bottom:2rem;max-width:65ch;label:ToastExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "fn8w4h-ToastExample",
  styles: "margin-bottom:1rem;label:ToastExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "damvy5",
  styles: "display:flex;gap:1rem"
} : {
  name: "mai101-ToastExample",
  styles: "display:flex;gap:1rem;label:ToastExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function ToastExample() {
  const {
    addToast,
    dismissAllToasts
  } = useToast();
  const triggerToast = () => {
    addToast({
      ...getRandomToastExample(),
      // Dismiss the toast after 7 seconds
      duration: 7000
    });
  };
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "Toast"
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "We use toasts to provide feedback to the user about an action they have taken. They come in a variety of sizes and colours to help communicate a message to the user without interrupting their workflow."
      }), _jsx("p", {
        children: "This example demonstrates how to trigger and dismiss toasts using the `useToast` hook."
      })]
    }), _jsxs("div", {
      css: _ref4,
      children: [_jsx(Button, {
        onClick: triggerToast,
        children: "Trigger random toast"
      }), _jsx(Button, {
        onClick: dismissAllToasts,
        children: "Dismiss all toasts"
      })]
    }), _jsx(ToastFeed, {})]
  });
}