import { findLastIndex } from 'lodash';
import { useCallback } from 'react';
import { FormatNumberOptions, FormattedNumberParts, useIntl } from 'react-intl';
import { SetRequired } from 'type-fest';
import Nothing from './Nothing';

// Scandinavian countries use the same currency symbol "kr.", so we'll always just show currency
// 'code' for these languages
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CURRENCY_DISPLAY: Record<string, FormatNumberOptions['currencyDisplay']> = ({
  DKK: 'code',
  EUR: 'symbol',
  NOK: 'code',
  SEK: 'code'
} as const);
function getCurrencyDisplay(currency?: string): FormatNumberOptions['currencyDisplay'] {
  return CURRENCY_DISPLAY[currency ?? ''] || 'symbol';
}
export type MoneyProps = {
  __typename?: string;
  currency: string;
  majorAmount: number;
  showFractions?: boolean;
  numberSuffix?: string;
};
type FormatMoneyArgs = Omit<MoneyProps & FormatNumberOptions, 'maximumFractionDigits' | 'minimumFractionDigits'>;
export function useFormatMoney() {
  const intl = useIntl();
  const formatMoney = useCallback((props: FormatMoneyArgs): string => {
    const {
      currency,
      majorAmount,
      showFractions,
      numberSuffix,
      ...rest
    } = props;
    const parts = intl.formatNumberToParts(majorAmount, {
      style: 'currency',
      currency,
      currencyDisplay: getCurrencyDisplay(currency),
      maximumFractionDigits: showFractions ? 2 : 0,
      minimumFractionDigits: showFractions ? 2 : 0,
      ...rest
    });
    const lastIntegerIndex = findLastIndex(parts, part => part.type === 'integer');
    return parts.map((part, index) => {
      if (numberSuffix && index === lastIntegerIndex) {
        return `${part.value}${numberSuffix}`;
      }
      return part.value;
    }).join('').trim();
  }, [intl]);
  return formatMoney;
}
export default function FormattedMoney(props: FormatMoneyArgs) {
  const formatMoney = useFormatMoney();
  const formattedString = formatMoney(props);
  return _jsx(_Fragment, {
    children: formattedString
  });
}
export type FormattedMoneyRangeProps = {
  from?: Pick<MoneyProps, 'majorAmount'>;
  to?: Pick<MoneyProps, 'majorAmount'>;
} & SetRequired<FormatNumberOptions, 'currency'>;
export function FormattedMoneyRange({
  from,
  to,
  currency,
  ...rest
}: FormattedMoneyRangeProps) {
  const intl = useIntl();
  if (to) {
    if (typeof from?.majorAmount !== 'undefined' && from.majorAmount === to.majorAmount) {
      return _jsx(FormattedMoney, {
        currency: currency,
        majorAmount: from.majorAmount,
        ...rest
      });
    }
    return (
      // Dummy formatted number, where we replace the integer `1` with the range.
      // We do this to only have 1 currency symbol/display for the range as if the
      // range was just a single number.
      _jsx(FormattedNumberParts, {
        style: "currency",
        currency: currency,
        currencyDisplay: getCurrencyDisplay(currency),
        value: 1,
        ...rest,
        // Force no fractional digits. Since the value `1` is just a dummy we replace
        // with actual numbers from the range (which keep their fractions).
        maximumFractionDigits: 0,
        children: parts => {
          return _jsx(_Fragment, {
            children: parts.map(part => {
              if (part.type === 'integer') {
                // Replace the single integer part with our range of multiple numbers
                return intl.formatMessage({
                  id: "I4U2WxsWjl",
                  defaultMessage: [{
                    "type": 1,
                    "value": "valueMin"
                  }, {
                    "type": 0,
                    "value": "–"
                  }, {
                    "type": 1,
                    "value": "valueMax"
                  }]
                }, {
                  valueMin: intl.formatNumber(from?.majorAmount || 0, {
                    ...rest
                  }),
                  valueMax: intl.formatNumber(to.majorAmount, {
                    ...rest
                  })
                });
              }

              // Keep the rest of the parts to leave currency symbol etc. in place
              return part.value;
            }).join('').trim()
          });
        }
      })
    );
  }
  if (from) {
    return (
      // TODO: Appending "+" to the end of the formatted money might not be ideal
      // for all future languages. We might need something more advanced in the
      // future, but for now this is good enough.
      _jsx(_Fragment, {
        children: _jsx(FormattedMoney, {
          currency: currency,
          majorAmount: from.majorAmount,
          numberSuffix: "+",
          ...rest
        })
      })
    );
  }
  return _jsx(Nothing, {});
}