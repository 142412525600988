function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import NumberField from '@landfolk/shared/components/forms/NumberField';
import Slider from '@landfolk/shared/components/Slider';
import { useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "1am2ou0-SliderExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1rkgmoz-SliderExample",
  styles: "max-width:65ch;label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1jbl5n7",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;}"
} : {
  name: "4ribzo-SliderExample",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1v1rorg",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem"
} : {
  name: "m8e96y-SliderExample",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem;label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "nh1a1o",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;}"
} : {
  name: "164avjr-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "uyiz4o",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:1 / -1;}"
} : {
  name: "1eog7bs-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:1 / -1;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1jbl5n7",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;}"
} : {
  name: "4ribzo-SliderExample",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1v1rorg",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem"
} : {
  name: "m8e96y-SliderExample",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem;label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "nh1a1o",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;}"
} : {
  name: "164avjr-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "uyiz4o",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:1 / -1;}"
} : {
  name: "1eog7bs-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:1 / -1;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1jbl5n7",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;}"
} : {
  name: "4ribzo-SliderExample",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "1v1rorg",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem"
} : {
  name: "m8e96y-SliderExample",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem;label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "nh1a1o",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;}"
} : {
  name: "164avjr-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "zcza8q",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 6 / span 6;}"
} : {
  name: "1dow18l-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 6 / span 6;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "zcza8q",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 6 / span 6;}"
} : {
  name: "1dow18l-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 6 / span 6;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "1jbl5n7",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;}"
} : {
  name: "4ribzo-SliderExample",
  styles: "display:grid;grid-template-columns:repeat(12, minmax(0, 1fr));align-items:center;gap:1rem;@media (min-width: 64em){margin-top:2rem;}@media (max-width: 63.999em){margin-top:4rem;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "1v1rorg",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem"
} : {
  name: "m8e96y-SliderExample",
  styles: "grid-column:1 / -1;font-size:1rem;line-height:1.5rem;label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "nh1a1o",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;}"
} : {
  name: "164avjr-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 8 / span 8;}@media (max-width: 63.999em){grid-column:1 / -1;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "lmdw8q",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;}"
} : {
  name: "1y5xkrh-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "lmdw8q",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;}"
} : {
  name: "1y5xkrh-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "lmdw8q",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;}"
} : {
  name: "1y5xkrh-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "lmdw8q",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;}"
} : {
  name: "1y5xkrh-SliderExample",
  styles: "@media (min-width: 64em){grid-column:span 1 / span 1;}@media (max-width: 63.999em){grid-column:span 3 / span 3;};label:SliderExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function SliderExample() {
  const [singleValue, setSingleValue] = useState([25]);
  const [nonLinearValue, setNonLinearValue] = useState([25]);
  const [rangeValue, setRangeValue] = useState([25, 75]);
  const [multiValue, setMultiValue] = useState([20, 30, 50, 80]);
  const nonLinearScale = {
    transform: (v: number) => {
      return v * v / 100;
    },
    inverse: (v: number) => {
      return Math.sqrt(v) * 10;
    }
  };
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "Slider"
    }), _jsx("div", {
      css: _ref2,
      children: _jsx("p", {
        children: "Sliders creates a visual way for users to select a number within a predefined range. In most cases, users cannot select a specific number, as the slider jumps in intervals. User a number input instead to allow selecting a specific number."
      })
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsx("h2", {
        css: _ref4,
        children: "Single value"
      }), _jsx(Slider, {
        value: singleValue,
        min: 0,
        max: 100,
        step: 5,
        onValueChange: setSingleValue,
        css: _ref5
      }), _jsx(NumberField, {
        value: singleValue[0],
        onChange: e => setSingleValue([e.target.valueAsNumber]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref6
      })]
    }), _jsxs("div", {
      css: _ref7,
      children: [_jsx("h2", {
        css: _ref8,
        children: "Non-linear scale"
      }), _jsx(Slider, {
        value: nonLinearValue,
        min: 0,
        max: 100,
        step: 5,
        scale: nonLinearScale,
        onValueChange: setNonLinearValue,
        css: _ref9
      }), _jsx(NumberField, {
        value: Math.round((nonLinearValue[0] as number)),
        onChange: e => setNonLinearValue([e.target.valueAsNumber]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref10
      })]
    }), _jsxs("div", {
      css: _ref11,
      children: [_jsx("h2", {
        css: _ref12,
        children: "Range"
      }), _jsx(Slider, {
        value: rangeValue,
        min: 0,
        max: 100,
        step: 5,
        minStepsBetweenThumbs: 1,
        onValueChange: setRangeValue,
        css: _ref13
      }), _jsx(NumberField, {
        value: rangeValue[0],
        onChange: e => setRangeValue([e.target.valueAsNumber, (rangeValue[1] as number)]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref14
      }), _jsx(NumberField, {
        value: rangeValue[1],
        onChange: e => setRangeValue([(rangeValue[0] as number), e.target.valueAsNumber]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref15
      })]
    }), _jsxs("div", {
      css: _ref16,
      children: [_jsx("h2", {
        css: _ref17,
        children: "Multiple values"
      }), _jsx(Slider, {
        value: multiValue,
        min: 0,
        max: 100,
        step: 5,
        minStepsBetweenThumbs: 1,
        onValueChange: setMultiValue,
        css: _ref18
      }), _jsx(NumberField, {
        value: multiValue[0],
        onChange: e => setMultiValue([e.target.valueAsNumber, ...multiValue.slice(1)]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref19
      }), _jsx(NumberField, {
        value: multiValue[1],
        onChange: e => setMultiValue([...multiValue.slice(0, 1), e.target.valueAsNumber, ...multiValue.slice(2)]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref20
      }), _jsx(NumberField, {
        value: multiValue[2],
        onChange: e => setMultiValue([...multiValue.slice(0, 2), e.target.valueAsNumber, ...multiValue.slice(3)]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref21
      }), _jsx(NumberField, {
        value: multiValue[3],
        onChange: e => setMultiValue([...multiValue.slice(0, 3), e.target.valueAsNumber]),
        min: 0,
        max: 100,
        step: 5,
        css: _ref22
      })]
    })]
  });
}