import { eachMinuteOfInterval, lightFormat } from 'date-fns';
import { useMemo } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
type Time = `${number}${number}:${number}${number}`;
type TimeOptionsProps = {
  start: Time;
  end: Time;
  step?: number;
};
export default function TimeOptions({
  start,
  end,
  step = 30
}: TimeOptionsProps) {
  const intervals = useMemo(() => eachMinuteOfInterval({
    start: new Date(`2021-02-01T${start}`),
    end: new Date(`2021-02-01T${end}`)
  }, {
    step
  }).map((date, i) => _jsx("option", {
    value: lightFormat(date, 'HH:mm'),
    children: lightFormat(date, 'HH:mm')
  }, i)), [start, end, step]);
  return _jsx(_Fragment, {
    children: intervals
  });
}