function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { differenceInCalendarDays, isWithinInterval } from 'date-fns';
import { ComponentPropsWithoutRef, ReactNode, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import ArrowLeft from '../../../../../icons/ArrowLeft.svg';
import Notification from '../../../../Notification';
import Slider from '../../../../Slider';
import { useCalendarSelectionContext } from '../../../context';
import { useDateRangeInfo } from '../../../useDateRangeInfo';
import { useCalendarAsideContext } from '../../context';
import { ViewHeading } from '../../shared';
import { DateConfigurationViews } from '..';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1iz4oz4",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem"
} : {
  name: "1l8ynym-MinNights",
  styles: "display:flex;align-items:center;gap:0.25rem;font-size:0.75rem;line-height:1rem;label:MinNights;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "185o5ba",
  styles: "text-decoration-line:underline"
} : {
  name: "nmjfgt-MinNights",
  styles: "text-decoration-line:underline;label:MinNights;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "xvw0zl",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem"
} : {
  name: "xn9sgj-MinNights",
  styles: "margin-top:0.5rem;display:flex;flex-direction:column;gap:1rem;label:MinNights;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1om8jgn",
  styles: "margin-bottom:1rem;width:100%;@media (max-width: 39.999em){margin-left:1rem;margin-right:1rem;margin-top:1rem;width:calc(100% - 2rem) !important;}"
} : {
  name: "8qevlz-MinNights",
  styles: "margin-bottom:1rem;width:100%;@media (max-width: 39.999em){margin-left:1rem;margin-right:1rem;margin-top:1rem;width:calc(100% - 2rem) !important;};label:MinNights;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "a8kxe9-MinNights",
  styles: "font-weight:600;label:MinNights;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "1e4sizb",
  styles: "margin-top:0.5rem"
} : {
  name: "1cwqkwz-MinNights",
  styles: "margin-top:0.5rem;label:MinNights;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "1biulnr-b",
  styles: "font-weight:600;label:b;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1e4sizb",
  styles: "margin-top:0.5rem"
} : {
  name: "1cwqkwz-MinNights",
  styles: "margin-top:0.5rem;label:MinNights;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const MinNights = ({
  setView,
  ...props
}: {
  setView: (view: DateConfigurationViews | undefined) => void;
} & ComponentPropsWithoutRef<'div'>) => {
  const {
    watch
  } = useFormContext();
  const watchedMinimumNights = watch('minNights') || 1;
  const [value, setValue] = useState<number>();
  const {
    onSubmitConfiguration,
    events
  } = useCalendarAsideContext();
  const {
    hasMixedMinNights
  } = useDateRangeInfo();
  const {
    selectedDates
  } = useCalendarSelectionContext();
  const minNightsEvent = useMemo(() => {
    return events?.find(event => event.dateRange.last && event.dateRange.first && selectedDates[0] && isWithinInterval(new Date(selectedDates[0]), {
      start: new Date(event.dateRange.first),
      end: new Date(event.dateRange.last)
    }) && event.reason === 'MIN_NIGHTS');
  }, [events, selectedDates]);
  const minNightsTillOpen = useMemo(() => {
    if (!minNightsEvent) return;
    return differenceInCalendarDays(new Date(minNightsEvent.dateRange?.last || ''), new Date(minNightsEvent.dateRange?.first || '')) + 1;
  }, [minNightsEvent]);
  return _jsxs("div", {
    ...props,
    children: [_jsxs("button", {
      css: _ref,
      type: "button",
      onClick: () => setView(undefined),
      children: [_jsx(ArrowLeft, {}), _jsx("p", {
        css: _ref2,
        children: _jsx(FormattedMessage, {
          id: "8AvlH37f7b",
          defaultMessage: [{
            "type": 0,
            "value": "Back"
          }]
        })
      })]
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsxs("div", {
        children: [_jsx(ViewHeading, {
          children: _jsx(FormattedMessage, {
            id: "pambZVTJtb",
            defaultMessage: [{
              "type": 0,
              "value": "Minimum nights"
            }]
          })
        }), _jsx("p", {
          children: _jsx(FormattedMessage, {
            id: "1Bjgrg3N98",
            defaultMessage: [{
              "type": 0,
              "value": "How many nights must guests book?"
            }]
          })
        })]
      }), _jsxs("div", {
        children: [_jsx(Slider, {
          name: "minNights",
          css: _ref4,
          value: [value ?? watchedMinimumNights],
          onInteractionEnd: () => onSubmitConfiguration?.({
            dates: selectedDates,
            minNights: value
          }),
          onValueChange: value => {
            setValue(value[0]);
          },
          min: 1,
          max: 7
        }), _jsx("label", {
          htmlFor: "minNights",
          css: _ref5,
          children: _jsx(FormattedMessage, {
            id: "wvcomZJrKG",
            defaultMessage: [{
              "type": 6,
              "value": "nights",
              "options": {
                "one": {
                  "value": [{
                    "type": 7
                  }, {
                    "type": 0,
                    "value": " night"
                  }]
                },
                "other": {
                  "value": [{
                    "type": 7
                  }, {
                    "type": 0,
                    "value": " nights"
                  }]
                }
              },
              "offset": 0,
              "pluralType": "cardinal"
            }],
            values: {
              nights: value ?? watchedMinimumNights
            }
          })
        }), minNightsEvent && _jsx(Notification, {
          size: "small",
          type: "warning",
          css: _ref6,
          children: _jsx(FormattedMessage, {
            id: "wMUfAXydjY",
            defaultMessage: [{
              "type": 0,
              "value": "To open the nights for booking requests, set your minimum nights to "
            }, {
              "type": 8,
              "value": "b",
              "children": [{
                "type": 6,
                "value": "minNightsTillOpen",
                "options": {
                  "one": {
                    "value": [{
                      "type": 7
                    }, {
                      "type": 0,
                      "value": " night"
                    }]
                  },
                  "other": {
                    "value": [{
                      "type": 7
                    }, {
                      "type": 0,
                      "value": " nights"
                    }]
                  }
                },
                "offset": 0,
                "pluralType": "cardinal"
              }]
            }, {
              "type": 0,
              "value": " or less."
            }],
            values: {
              minNightsTillOpen,
              b: (chunks: ReactNode) => _jsx("span", {
                css: _ref7,
                children: chunks
              })
            }
          })
        }), hasMixedMinNights && _jsx(Notification, {
          size: "small",
          type: "warning",
          css: _ref8,
          children: _jsx(FormattedMessage, {
            id: "NC0i59eoxe",
            defaultMessage: [{
              "type": 0,
              "value": "Some of the nights have different minimum nights configured. Changes here will affect them all."
            }]
          })
        })]
      })]
    })]
  });
};