function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import * as RadixToast from '@radix-ui/react-toast';
import { ComponentPropsWithoutRef } from 'react';
import Notification, { NotificationClose, NotificationProps } from '../Notification';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export type ToastProps = Pick<ComponentPropsWithoutRef<typeof RadixToast.Root>, 'type' | 'duration' | 'defaultOpen' | 'open' | 'onOpenChange' | 'onEscapeKeyDown' | 'onPause' | 'onResume' | 'onSwipeStart' | 'onSwipeMove' | 'onSwipeEnd' | 'onSwipeCancel' | 'forceMount'> & {
  notificationType?: NotificationProps['type'];
  headline?: string;
  description?: string;
  actionLabel?: string;
  onAction?: NotificationProps['onAction'];
  size?: NotificationProps['size'];
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1ehwkn5",
  styles: "--tw-translate-x:1rem;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.25, 0, 0.25, 1);transition-duration:150ms;&[data-swipe=end][data-state=closed]{--tw-translate-x:calc(var(--radix-toast-swipe-end-x) + 5rem);transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}&[data-state=open]{--tw-translate-x:0;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));animation:fade-in 0.4s cubic-bezier(0, 0, 0.2, 1) forwards;}&[data-swipe=cancel]{--tw-translate-x:0;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}&[data-swipe=end]{--tw-translate-x:var(--radix-toast-swipe-end-x);transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}&[data-swipe=move]{--tw-translate-x:var(--radix-toast-swipe-move-x);transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:none;}@keyframes fade-out{0%{opacity:1;}100%{opacity:0;}}&[data-state=closed]{animation:fade-out 0.3s cubic-bezier(0, 0, 0.2, 1) forwards;}@keyframes fade-in{0%{opacity:0;}100%{opacity:1;}}"
} : {
  name: "1poi3ds-Toast",
  styles: "--tw-translate-x:1rem;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.25, 0, 0.25, 1);transition-duration:150ms;&[data-swipe=end][data-state=closed]{--tw-translate-x:calc(var(--radix-toast-swipe-end-x) + 5rem);transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}&[data-state=open]{--tw-translate-x:0;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));animation:fade-in 0.4s cubic-bezier(0, 0, 0.2, 1) forwards;}&[data-swipe=cancel]{--tw-translate-x:0;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}&[data-swipe=end]{--tw-translate-x:var(--radix-toast-swipe-end-x);transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}&[data-swipe=move]{--tw-translate-x:var(--radix-toast-swipe-move-x);transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:none;}@keyframes fade-out{0%{opacity:1;}100%{opacity:0;}}&[data-state=closed]{animation:fade-out 0.3s cubic-bezier(0, 0, 0.2, 1) forwards;}@keyframes fade-in{0%{opacity:0;}100%{opacity:1;}};label:Toast;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1089mxj",
  styles: "white-space:pre-wrap"
} : {
  name: "1dvx8lf-Toast",
  styles: "white-space:pre-wrap;label:Toast;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const Toast = ({
  notificationType,
  headline,
  description,
  actionLabel,
  onAction,
  size = 'small',
  duration = 10000,
  ...restProps
}: ToastProps) => {
  return _jsx(RadixToast.Root, {
    css: _ref,
    duration: duration,
    ...restProps,
    children: _jsx(Notification, {
      floating: true,
      type: notificationType,
      size: size,
      headline: headline,
      actionLabel: actionLabel,
      onAction: onAction,
      closeContent: _jsx(RadixToast.Close, {
        asChild: true,
        children: _jsx(NotificationClose, {
          size: "small"
        })
      }),
      children: _jsx("p", {
        css: _ref2,
        children: description
      })
    })
  });
};