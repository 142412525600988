export class PseudoRandomGenerator {
  private seed: number;
  constructor(seed: number | string) {
    if (typeof seed === 'string') seed = hashCode(seed);
    this.seed = seed;
  }
  next(): number {
    // LCG parameters
    // https://en.wikipedia.org/wiki/Linear_congruential_generator#Parameters_in_common_use
    const a = 1664525;
    const c = 1013904223;
    const m = 2 ** 32;

    // Linear congruential generator (LCG) formula
    this.seed = (a * this.seed + c) % m;
    return this.seed / m;
  }
}
export function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }

  return Math.abs(hash); // Ensure the hash is always positive
}