function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Notification from '@landfolk/shared/components/Notification';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "xk16r5-NotificationExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "v30hbo",
  styles: "display:flex;max-width:65ch;flex-direction:column"
} : {
  name: "1i5053t-NotificationExample",
  styles: "display:flex;max-width:65ch;flex-direction:column;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "v30hbo",
  styles: "display:flex;max-width:65ch;flex-direction:column"
} : {
  name: "1i5053t-NotificationExample",
  styles: "display:flex;max-width:65ch;flex-direction:column;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "v30hbo",
  styles: "display:flex;max-width:65ch;flex-direction:column"
} : {
  name: "1i5053t-NotificationExample",
  styles: "display:flex;max-width:65ch;flex-direction:column;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "v30hbo",
  styles: "display:flex;max-width:65ch;flex-direction:column"
} : {
  name: "1i5053t-NotificationExample",
  styles: "display:flex;max-width:65ch;flex-direction:column;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "41oqpv",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600"
} : {
  name: "14uvwoq-NotificationExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "41oqpv",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600"
} : {
  name: "14uvwoq-NotificationExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "lxty81",
  styles: "margin-top:1rem;font-weight:600"
} : {
  name: "1nmte24-NotificationExample",
  styles: "margin-top:1rem;font-weight:600;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "1gflsc4",
  styles: "margin-bottom:0.5rem;margin-top:4rem;font-size:1.25rem;line-height:1.5rem"
} : {
  name: "vj6ap2-NotificationExample",
  styles: "margin-bottom:0.5rem;margin-top:4rem;font-size:1.25rem;line-height:1.5rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "ia8sz4",
  styles: "display:flex;max-width:32rem;flex-direction:column"
} : {
  name: "1rfid37-NotificationExample",
  styles: "display:flex;max-width:32rem;flex-direction:column;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref23 = process.env.NODE_ENV === "production" ? {
  name: "180pqcn",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1c6f2ae-NotificationExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref24 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref25 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref26 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref27 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref28 = process.env.NODE_ENV === "production" ? {
  name: "180pqcn",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1c6f2ae-NotificationExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref29 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref30 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref31 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref32 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref33 = process.env.NODE_ENV === "production" ? {
  name: "180pqcn",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1c6f2ae-NotificationExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref34 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref35 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref36 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref37 = process.env.NODE_ENV === "production" ? {
  name: "180pqcn",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1c6f2ae-NotificationExample",
  styles: "margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref38 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref39 = process.env.NODE_ENV === "production" ? {
  name: "dfesk7",
  styles: "margin-top:1rem;margin-bottom:1rem"
} : {
  name: "j39qap-NotificationExample",
  styles: "margin-top:1rem;margin-bottom:1rem;label:NotificationExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function NotificationExample() {
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "Notification"
    }), _jsx("div", {
      css: _ref2,
      children: _jsx("p", {
        children: "Notifications inform the user about something important, timely and useful. They appear as a result of a user action. Notifications come in four types: Info, Success, Error and Warning, and in three styles: Compact, Small and Large."
      })
    }), _jsx("div", {
      css: _ref3,
      children: _jsx(Notification, {
        css: _ref4,
        headline: "Landfolk",
        size: "small",
        onClose: () => console.info('close'),
        children: _jsx("p", {
          children: "Landfolk is 100% Danish-owned. We are located in the heart of Aarhus, and you are more than welcome to come by and say hi!"
        })
      })
    }), _jsx("div", {
      css: _ref5,
      children: _jsx("p", {
        children: "Always place the notification close to the element it refers to, preferrably below the element."
      })
    }), _jsxs("div", {
      css: _ref6,
      children: [_jsx("p", {
        css: _ref7,
        children: "Types"
      }), _jsx("p", {
        css: _ref8,
        children: "Info"
      }), _jsx("p", {
        children: "Adds useful information, such as tips, recommendations and context. Users can ignore this type of notification."
      }), _jsx("p", {
        css: _ref9,
        children: "Success"
      }), _jsx("p", {
        children: "Informs the user that their action was successful. Use this to give users positive feedback when the success of an action might otherwise be unclear."
      }), _jsx("p", {
        css: _ref10,
        children: "Error"
      }), _jsx("p", {
        children: "Alerts that an action was unsuccessful and how to fix the issue. Users need to take action before they can continue."
      }), _jsx("p", {
        css: _ref11,
        children: "Warning"
      }), _jsx("p", {
        children: "Warns the user about the consequences of their action. Use this when the user risks losing data or progress."
      }), _jsx("p", {
        css: _ref12,
        children: "Styles"
      }), _jsx("p", {
        css: _ref13,
        children: "Compact"
      }), _jsx("p", {
        children: "Use for unimportant and non-urgent notifications."
      }), _jsx("p", {
        css: _ref14,
        children: "Small"
      }), _jsx("p", {
        children: "Use for important, but non-urgent notifications."
      }), _jsx("p", {
        css: _ref15,
        children: "Large"
      }), _jsx("p", {
        children: "Use for important and urgent notifications."
      }), _jsx("p", {
        css: _ref16,
        children: "Floating"
      }), _jsx("p", {
        children: "Add to highlight the notification either due to importance or a noise background"
      }), _jsx("h2", {
        css: _ref17,
        children: "Large"
      }), _jsx("p", {
        children: "Info (default)"
      })]
    }), _jsxs("div", {
      css: _ref18,
      children: [_jsx(Notification, {
        css: _ref19,
        size: "compact",
        onClose: () => console.info('close'),
        children: _jsx("p", {
          children: "Landfolk is 100% Danish-owned. We are located in the heart of Aarhus, and you are more than welcome to stop by and say hi!"
        })
      }), _jsx("p", {
        children: "Success"
      }), _jsx(Notification, {
        css: _ref20,
        size: "compact",
        onClose: () => console.info('close'),
        type: "success",
        children: _jsx("p", {
          children: "Your changes have been saved successfully."
        })
      }), _jsx("p", {
        children: "Error"
      }), _jsx(Notification, {
        css: _ref21,
        size: "compact",
        onClose: () => console.info('close'),
        type: "error",
        children: _jsx("p", {
          children: "An error occurred while processing your request. Please try again later."
        })
      }), _jsx("p", {
        children: "Warning"
      }), _jsx(Notification, {
        css: _ref22,
        size: "compact",
        onClose: () => console.info('close'),
        type: "warning",
        headline: "",
        children: _jsx("p", {
          children: "This action cannot be undone. Are you sure you want to proceed?"
        })
      }), _jsx("h2", {
        css: _ref23,
        children: "Small"
      }), _jsx("p", {
        children: "Info (default)"
      }), _jsx(Notification, {
        css: _ref24,
        headline: "Landfolk",
        size: "small",
        onClose: () => console.info('close'),
        children: _jsx("p", {
          children: "Landfolk is 100% Danish-owned. We are located in the heart of Aarhus, and you are more than welcome to come by and say hi!"
        })
      }), _jsx("p", {
        children: "Success"
      }), _jsx(Notification, {
        css: _ref25,
        size: "small",
        onClose: () => console.info('close'),
        type: "success",
        headline: "Success",
        children: _jsx("p", {
          children: "Your changes have been saved."
        })
      }), _jsx("p", {
        children: "Error"
      }), _jsx(Notification, {
        css: _ref26,
        size: "small",
        onClose: () => console.info('close'),
        type: "error",
        headline: "Error",
        children: _jsx("p", {
          children: "An error occurred while processing your request."
        })
      }), _jsx("p", {
        children: "Warning"
      }), _jsx(Notification, {
        css: _ref27,
        size: "small",
        onClose: () => console.info('close'),
        type: "warning",
        headline: "Warning",
        children: _jsx("p", {
          children: "This is a warning message."
        })
      }), _jsx("h2", {
        css: _ref28,
        children: "Large"
      }), _jsx("p", {
        children: "Info (default)"
      }), _jsx(Notification, {
        css: _ref29,
        size: "large",
        onClose: () => console.info('close'),
        type: "info",
        headline: "Info",
        children: _jsx("p", {
          children: "Here is some additional information."
        })
      }), _jsx("p", {
        children: "Success"
      }), _jsx(Notification, {
        css: _ref30,
        size: "large",
        onClose: () => console.info('close'),
        type: "success",
        headline: "Success",
        children: _jsx("p", {
          children: "Your changes have been saved."
        })
      }), _jsx("p", {
        children: "Error"
      }), _jsx(Notification, {
        css: _ref31,
        size: "large",
        onClose: () => console.info('close'),
        type: "error",
        headline: "Error",
        children: _jsx("p", {
          children: "An error occurred while processing your request."
        })
      }), _jsx("p", {
        children: "Warning"
      }), _jsx(Notification, {
        css: _ref32,
        size: "large",
        onClose: () => console.info('close'),
        type: "warning",
        headline: "Warning",
        children: _jsx("p", {
          children: "This is a warning message."
        })
      }), _jsx("h2", {
        css: _ref33,
        children: "Floating"
      }), _jsx("p", {
        children: "Large"
      }), _jsx(Notification, {
        css: _ref34,
        size: "large",
        onClose: () => console.info('close'),
        type: "info",
        headline: "Info",
        floating: true,
        children: _jsx("p", {
          children: "Here is some additional information."
        })
      }), _jsx("p", {
        children: "Small"
      }), _jsx(Notification, {
        css: _ref35,
        headline: "Landfolk",
        size: "small",
        onClose: () => console.info('close'),
        floating: true,
        children: _jsx("p", {
          children: "Landfolk is 100% Danish-owned. We are located in the heart of Aarhus, and you are more than welcome to come by and say hi!"
        })
      }), _jsx("p", {
        children: "Compact"
      }), _jsx(Notification, {
        css: _ref36,
        size: "compact",
        onClose: () => console.info('close'),
        floating: true,
        children: _jsx("p", {
          children: "Here is some additional information."
        })
      }), _jsx("h2", {
        css: _ref37,
        children: "With action"
      }), _jsx("p", {
        children: "Large"
      }), _jsx(Notification, {
        css: _ref38,
        size: "large",
        onClose: () => console.info('close'),
        type: "info",
        headline: "Info",
        floating: true,
        actionLabel: "Some action",
        onAction: () => console.info('action'),
        children: _jsx("p", {
          children: "Here is some additional information."
        })
      }), _jsx("p", {
        children: "Small"
      }), _jsx(Notification, {
        css: _ref39,
        headline: "Landfolk",
        size: "small",
        onClose: () => console.info('close'),
        floating: true,
        actionLabel: "Some action",
        onAction: () => console.info('action'),
        children: _jsx("p", {
          children: "Landfolk is 100% Danish-owned. We are located in the heart of Aarhus, and you are more than welcome to come by and say hi!"
        })
      })]
    })]
  });
}