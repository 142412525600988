export const february = {
  0: {
    value: '#ffffff',
    category: 'february',
    cmyk: '0/0/0/0',
    description: 'White'
  },
  100: {
    value: '#F2F4F4',
    category: 'february',
    cmyk: 'February 5%',
    description: 'February Primo. Used as light background'
  },
  200: {
    value: '#E6E9E9',
    category: 'february',
    cmyk: 'February 10%',
    description: 'February Secundo. Used for e.g. labels and borders on floating elements'
  },
  300: {
    value: '#CCD2D2',
    category: 'february',
    cmyk: 'February 20%',
    description: 'February Tertian. Used for e.g. spacers and inputs'
  },
  400: {
    value: '#A7B1B2',
    category: 'february',
    cmyk: 'February 35%'
  },
  500: {
    value: '#889697',
    category: 'february',
    cmyk: 'February 47%',
    description: 'February Input. Only used for form fields'
  },
  600: {
    value: '#67797A',
    category: 'february',
    cmyk: 'February 60%',
    description: 'February Muted'
  },
  700: {
    value: '#4E6364',
    cmyk: 'February 70%',
    category: 'february'
  },
  800: {
    value: '#354D4D',
    cmyk: 'February 80%',
    category: 'february'
  },
  900: {
    value: '#022021',
    category: 'february',
    cmyk: '93/60/63/77'
  }
};
export const honey = {
  100: {
    value: '#FFF8E8',
    category: 'honey',
    cmyk: '0/3/12/0',
    description: 'Daylight, muted'
  },
  200: {
    value: '#FFEBB9',
    category: 'honey'
  },
  300: {
    value: '#FBDA86',
    category: 'honey'
  },
  500: {
    value: '#E2BF59',
    category: 'honey',
    cmyk: '13/23/73/2',
    description: 'Primary, saturated'
  },
  600: {
    value: '#B78C0E',
    category: 'honey'
  },
  700: {
    value: '#8D6D0F',
    category: 'honey'
  },
  800: {
    value: '#5B4912',
    category: 'honey'
  }
};
export const winter = {
  100: {
    value: '#F3FAFD',
    category: 'winter',
    cmyk: '6/0/1/0',
    description: 'Air, muted'
  },
  300: {
    value: '#D2E9EC',
    category: 'winter'
  },
  400: {
    value: '#ACCFD1',
    category: 'winter'
  },
  500: {
    value: '#86AEB2',
    category: 'winter',
    cmyk: '52/19/29/2',
    description: 'Primary, saturated'
  },
  700: {
    value: '#426E73',
    category: 'winter'
  },
  800: {
    value: '#1B4145',
    category: 'winter'
  }
};
export const spring = {
  100: {
    value: '#EBF5EC',
    category: 'spring',
    cmyk: '10/0/10/0',
    description: 'Moss, muted'
  },
  300: {
    value: '#BCE4CE',
    category: 'spring'
  },
  500: {
    value: '#7EB798',
    category: 'spring'
  },
  600: {
    value: '#548F73',
    category: 'spring'
  },
  700: {
    value: '#366752',
    category: 'spring',
    cmyk: '78/37/67/29',
    description: 'Primary, saturated'
  },
  800: {
    value: '#1A4636',
    category: 'spring'
  }
};
export const summer = {
  100: {
    value: '#FFF1EF',
    category: 'summer',
    description: 'Error, muted'
  },
  200: {
    value: '#FBDFD0',
    category: 'summer'
  },
  400: {
    value: '#E5A988',
    category: 'summer'
  },
  500: {
    value: '#C55417',
    category: 'summer',
    cmyk: '17/75/100/7',
    description: 'Primary, saturated'
  },
  600: {
    value: '#A74109',
    category: 'summer'
  },
  700: {
    value: '#8C3216',
    category: 'summer',
    description: 'Error, saturated'
  },
  800: {
    value: '#641E07',
    category: 'summer'
  }
};
export const fall = {
  100: {
    value: '#F7F2E9',
    category: 'fall',
    cmyk: '4/5/11/0',
    description: 'Beach, muted'
  },
  200: {
    value: '#ECE5D4',
    category: 'fall'
  },
  400: {
    value: '#B4AB98',
    category: 'fall'
  },
  500: {
    value: '#7D7564',
    category: 'fall'
  },
  600: {
    value: '#60594A',
    category: 'fall',
    cmyk: '53/47/60/44',
    description: 'Primary, saturated'
  },
  800: {
    value: '#343324',
    category: 'fall'
  }
};
const aliases = {
  february: february[900],
  honey: honey[500],
  white: february[0],
  winter: winter[500],
  spring: spring[700],
  summer: summer[500],
  fall: fall[600],
  air: winter[100],
  moss: spring[100],
  daylight: honey[100],
  beach: fall[100],
  'february-primo': february[100],
  'february-secundo': february[200],
  'february-tertian': february[300],
  'february-muted': february[600],
  'february-muted-hover': february[700],
  error: summer[700],
  'error-muted': summer[100]
};
export const colors = {
  ...flattenAndPrepend(february, 'february'),
  ...flattenAndPrepend(winter, 'winter'),
  ...flattenAndPrepend(spring, 'spring'),
  ...flattenAndPrepend(summer, 'summer'),
  ...flattenAndPrepend(fall, 'fall'),
  ...flattenAndPrepend(honey, 'honey'),
  ...flattenAndPrepend(february, 'february'),
  ...aliases
};
type ColorMetadata = {
  value: string;
  category: string;
  cmyk?: string;
  description?: string;
};
function flattenAndPrepend<T extends {
  [key: number]: ColorMetadata;
}, P extends string>(color: T, prefix: P) {
  const flattened = ({} as Record<`${P}-${keyof T & (string | number)}`, ColorMetadata>);
  Object.entries(color).forEach(([key, val]) => {
    flattened[(`${prefix}-${key}` as keyof typeof flattened)] = val;
  });
  return flattened;
}