function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Stepper from '@landfolk/shared/components/forms/Stepper';
import { useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1khlee7-StepperExample",
  styles: "max-width:65ch;label:StepperExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "x3ej71-StepperExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:StepperExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "19an0k4-StepperExample",
  styles: "margin-bottom:1rem;label:StepperExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "27st2m-StepperExample",
  styles: "margin-bottom:2rem;label:StepperExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "5dtcix",
  styles: "max-width:32rem"
} : {
  name: "8uvijd-StepperExample",
  styles: "max-width:32rem;label:StepperExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function StepperExample() {
  const [count, setCount] = useState(0);
  return _jsxs("div", {
    css: _ref,
    children: [_jsx("h1", {
      css: _ref2,
      children: "Stepper"
    }), _jsx("p", {
      css: _ref3,
      children: "Steppers are a controlled way for users to select a number within a specified range. The limits are marked by disabling the buttons."
    }), _jsx("p", {
      css: _ref4,
      children: "Steppers work best for cases where users are likely to select a small number \u2013 otherwise, selecting a number becomes a tidious task. It is also possible to enter a number instead of clicking, but this option is less intuitive and therefore not recommended."
    }), _jsx("div", {
      css: _ref5,
      children: _jsx(Stepper, {
        count: count,
        onChange: setCount,
        min: 1,
        max: 2000,
        label: "Count",
        description: "also focusable"
      })
    })]
  });
}