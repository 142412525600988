function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Calendar from '@landfolk/shared/components/Calendar';
import { CalendarAside, CalendarAsideProps } from '@landfolk/shared/components/Calendar/Aside';
import { Booking, BookingState, CalendarEvent, ListingDateConfiguration } from '@landfolk/shared/components/Calendar/types';
import { getDateTimestamp, NOW } from '@landfolk/shared/utils/time';
import { addDays, addMonths, clamp, endOfMonth, isFriday, isSaturday, startOfMonth } from 'date-fns';
import { omit, times } from 'lodash';
import { useRouter } from 'next/router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const EVENTS: CalendarEvent[] = [{
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, -6)),
    last: getDateTimestamp(addDays(NOW, -6 + 4 - 1))
  },
  id: '1',
  reason: 'BOOKED',
  ref: '3cksa2kd',
  numberOfNights: 4
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 0)),
    last: getDateTimestamp(addDays(NOW, 0 + 2 - 1))
  },
  id: '0',
  reason: 'ADVANCE_NOTICE',
  numberOfNights: 4
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 5)),
    last: getDateTimestamp(addDays(NOW, 5 + 7 - 1))
  },
  id: '2',
  reason: 'BOOKED',
  ref: 'mc2ksa23',
  numberOfNights: 7
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 12)),
    last: getDateTimestamp(addDays(NOW, 12 + 3 - 1))
  },
  id: '3',
  reason: 'BOOKED',
  ref: 'l4fb2kng',
  numberOfNights: 3
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 17)),
    last: getDateTimestamp(addDays(NOW, 17 + 3 - 1))
  },
  id: '4',
  reason: 'BOOKED',
  ref: 'vkx23mzk',
  numberOfNights: 3
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 13)),
    last: getDateTimestamp(addDays(NOW, 13 + 5 - 1))
  },
  id: '5',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 5,
  summary: 'Externally blocked'
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 24)),
    last: getDateTimestamp(addDays(NOW, 24 + 4 - 1))
  },
  id: '6',
  reason: 'BLOCKED',
  numberOfNights: 4
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 31)),
    last: getDateTimestamp(addDays(NOW, 31 + 8 - 1))
  },
  id: '7',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 8,
  summary: 'Externally blocked'
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 39)),
    last: getDateTimestamp(addDays(NOW, 39 + 1 - 1))
  },
  id: '8',
  reason: 'MIN_NIGHTS',
  numberOfNights: 1
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 40)),
    last: getDateTimestamp(addDays(NOW, 40 + 6 - 1))
  },
  id: '9',
  reason: 'BLOCKED',
  numberOfNights: 6
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 52)),
    last: getDateTimestamp(addDays(NOW, 52 + 3 - 1))
  },
  id: '10',
  reason: 'BLOCKED',
  numberOfNights: 3
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 54)),
    last: getDateTimestamp(addDays(NOW, 54 + 4 - 1))
  },
  id: '11',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 4,
  summary: 'Externally blocked'
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 68)),
    last: getDateTimestamp(addDays(NOW, 68 + 8 - 1))
  },
  id: '12',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 8,
  summary: 'Externally blocked'
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 71)),
    last: getDateTimestamp(addDays(NOW, 71 + 2 - 1))
  },
  id: '13',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 2,
  summary: 'Externally blocked'
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 72)),
    last: getDateTimestamp(addDays(NOW, 72 + 3 - 1))
  },
  id: '14',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 3,
  summary: 'Externally blocked'
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 74)),
    last: getDateTimestamp(addDays(NOW, 74 + 3 - 1))
  },
  id: '15',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 3,
  summary: 'Externally blocked'
}, {
  __typename: ('HostListingCalendarEvent' as const),
  dateRange: {
    first: getDateTimestamp(addDays(NOW, 76)),
    last: getDateTimestamp(addDays(NOW, 76 + 3 - 1))
  },
  id: '16',
  reason: 'EXTERNALLY_BOOKED',
  numberOfNights: 3,
  summary: 'Externally blocked'
}];
const BOOKINGS: Booking[] = [{
  __typename: ('BookingAsHost' as const),
  id: '1',
  checkinOn: getDateTimestamp(addDays(NOW, -6)),
  checkoutOn: getDateTimestamp(addDays(NOW, -6 + 4 - 1)),
  numberOfNights: 4,
  participants: {
    adults: 3,
    children: 2
  },
  reference: '3CKSA2KD',
  shortId: '3cksa2kd',
  state: BookingState.Completed,
  invoice: {
    __typename: 'HostInvoice',
    total: {
      __typename: 'Money',
      majorAmount: 1234,
      currency: 'EUR'
    }
  },
  booker: {
    __typename: 'Booker',
    firstName: 'John',
    lastName: 'Doe',
    city: 'Copenhagen',
    country: 'DK'
  }
}, {
  __typename: ('BookingAsHost' as const),
  id: '2',
  checkinOn: getDateTimestamp(addDays(NOW, 5)),
  checkoutOn: getDateTimestamp(addDays(NOW, 5 + 7 - 1)),
  numberOfNights: 7,
  participants: {
    adults: 4
  },
  reference: 'MC2KSA23',
  shortId: 'mc2ksa23',
  state: BookingState.Confirmed,
  invoice: {
    __typename: 'HostInvoice',
    total: {
      __typename: 'Money',
      majorAmount: 1234,
      currency: 'EUR'
    }
  },
  booker: {
    __typename: 'Booker',
    firstName: 'John',
    lastName: 'Doe',
    city: 'Copenhagen',
    country: 'DK'
  }
}, {
  __typename: ('BookingAsHost' as const),
  id: '3',
  checkinOn: getDateTimestamp(addDays(NOW, 12)),
  checkoutOn: getDateTimestamp(addDays(NOW, 12 + 3 - 1)),
  numberOfNights: 3,
  participants: {
    adults: 4,
    children: 2,
    infants: 2
  },
  reference: 'L4FB2KNG',
  shortId: 'l4fb2kng',
  state: BookingState.Pending,
  invoice: {
    __typename: 'HostInvoice',
    total: {
      __typename: 'Money',
      majorAmount: 1234,
      currency: 'EUR'
    }
  },
  booker: {
    __typename: 'Booker',
    firstName: 'John',
    lastName: 'Doe',
    city: 'Copenhagen',
    country: 'DK'
  }
}, {
  __typename: ('BookingAsHost' as const),
  id: '4',
  checkinOn: getDateTimestamp(addDays(NOW, 17)),
  checkoutOn: getDateTimestamp(addDays(NOW, 17 + 3 - 1)),
  numberOfNights: 3,
  participants: {
    adults: 2,
    children: 2,
    pets: 1
  },
  reference: 'VKX23MZK',
  shortId: 'vkx23mzk',
  state: BookingState.Confirmed,
  invoice: {
    __typename: 'HostInvoice',
    total: {
      __typename: 'Money',
      majorAmount: 1234,
      currency: 'EUR'
    }
  },
  booker: {
    __typename: 'Booker',
    firstName: 'John',
    lastName: 'Doe',
    city: 'Copenhagen',
    country: 'DK'
  }
}];
const LISTING_DATE_CONFIGURATIONS: ListingDateConfiguration[] = times(380).map((_, index) => {
  const groupedDates = {
    first: addDays(NOW, index),
    last: [20].includes(index) ? addDays(NOW, index + 3) : addDays(NOW, index)
  };
  const customPrice = [61, 62, 63, 64, 65, 66, 67].includes(index);
  return {
    customAvailability: [24, 25, 26, 27, 40, 41, 42, 43, 44, 45, 52, 53, 54].includes(index) ? true : undefined,
    available: ![24, 25, 26, 27, 40, 41, 42, 43, 44, 45, 52, 53, 54].includes(index),
    hasCustomPrice: customPrice,
    price: {
      majorAmount: customPrice ? 200 : isFriday(groupedDates.first) || isSaturday(groupedDates.first) ? 100 : 80,
      currency: 'EUR'
    },
    groupedDates: {
      first: getDateTimestamp(groupedDates.first),
      last: getDateTimestamp(groupedDates.last)
    }
  };
});
LISTING_DATE_CONFIGURATIONS.splice(21, 3);
const PRICING: CalendarAsideProps['pricing'] = {
  currency: 'EUR',
  scheme: ('SEASONAL' as CalendarAsideProps['pricing']['scheme']),
  dailyPriceHighSeason: {
    __typename: 'Money',
    minorAmount: 8000,
    majorAmount: 80,
    currency: 'EUR'
  },
  dailyPriceLowSeason: {
    __typename: 'Money',
    minorAmount: 6000,
    majorAmount: 60,
    currency: 'EUR'
  },
  dailyUtilityCostColdest: {
    __typename: 'Money',
    minorAmount: 0,
    majorAmount: 0,
    currency: 'EUR'
  },
  dailyUtilityCostWarmest: {
    __typename: 'Money',
    minorAmount: 0,
    majorAmount: 0,
    currency: 'EUR'
  }
};
const MONTH_CAP = {
  past: 6,
  future: 12
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "11e65yc",
  styles: "margin-left:-1rem;margin-right:-1rem;@media (min-width: 64em){margin-left:-4rem;margin-right:-4rem;}"
} : {
  name: "fnm7w4-CalendarExample",
  styles: "margin-left:-1rem;margin-right:-1rem;@media (min-width: 64em){margin-left:-4rem;margin-right:-4rem;};label:CalendarExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "ynfrsw",
  styles: "margin-bottom:2rem;padding-left:1rem;padding-right:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (min-width: 64em){padding-left:3rem;padding-right:3rem;}@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "krh2v-CalendarExample",
  styles: "margin-bottom:2rem;padding-left:1rem;padding-right:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (min-width: 64em){padding-left:3rem;padding-right:3rem;}@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:CalendarExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1v7qin8",
  styles: "margin-bottom:2rem;margin-left:3rem;margin-right:8rem;max-width:65ch"
} : {
  name: "16boed7-CalendarExample",
  styles: "margin-bottom:2rem;margin-left:3rem;margin-right:8rem;max-width:65ch;label:CalendarExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function CalendarExample() {
  const router = useRouter();
  const from = typeof router.query.from === 'string' ? startOfMonth(new Date(router.query.from)) : startOfMonth(NOW);
  const to = typeof router.query.to === 'string' ? new Date(router.query.to) : endOfMonth(addMonths(from, 3));
  const at = typeof router.query.at === 'string' ? new Date(router.query.at) : undefined;
  const minCapDate = startOfMonth(addMonths(NOW, -MONTH_CAP.past));
  const maxCapDate = endOfMonth(addMonths(NOW, MONTH_CAP.future));
  return _jsxs("div", {
    css: _ref,
    children: [_jsx("h1", {
      css: _ref2,
      children: "Calendar"
    }), _jsx("p", {
      css: _ref3,
      children: "The calendar helps users keep an overview over their holiday home. It is designed to provide high-level information on open, closed and booked nights, without overloading users with information."
    }), _jsx(Calendar, {
      from: from,
      to: to,
      at: at,
      weekStartsOn: 1,
      configs: LISTING_DATE_CONFIGURATIONS,
      events: EVENTS,
      bookings: BOOKINGS,
      getBookingLink: () => 'https://landfolk.com',
      monthCap: MONTH_CAP,
      monthsPerPage: 4,
      onPagination: (newFrom, newTo, newAt) => {
        const clampedFrom = clamp(newFrom, {
          start: minCapDate,
          end: maxCapDate
        });
        const clampedTo = clamp(newTo, {
          start: minCapDate,
          end: maxCapDate
        });
        const clampedAt = newAt ? clamp(newAt, {
          start: minCapDate,
          end: maxCapDate
        }) : undefined;
        router.replace({
          query: {
            ...omit(router.query, 'from', 'to', 'at'),
            from: getDateTimestamp(clampedFrom),
            to: getDateTimestamp(clampedTo),
            ...(clampedAt ? {
              at: getDateTimestamp(clampedAt)
            } : {})
          }
        }, undefined, {
          scroll: false,
          shallow: true
        });
      },
      children: _jsx(CalendarAside, {
        pricing: PRICING,
        minNights: 2,
        maxLeadMonths: 18,
        dateConfigurations: LISTING_DATE_CONFIGURATIONS,
        events: EVENTS
      })
    })]
  });
}