import theme from '@landfolk/styling/utils/theme';
import * as Portal from '@radix-ui/react-portal';
import confetti from 'canvas-confetti';
import { MutableRefObject, useEffect, useRef } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export type ConfettiProps = {
  onComplete?: (confettiKey?: number) => void;
};
export const ConfettiRain = ({
  onComplete
}: ConfettiProps) => {
  const canvasForegroundRef = (useRef() as MutableRefObject<HTMLCanvasElement>);
  const canvasBackgroundRef = (useRef() as MutableRefObject<HTMLCanvasElement>);
  const isMountedRef = useRef(true);
  const onCompleteRef = useRef(onComplete);
  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    if (!__IS_BROWSER__ || !canvasForegroundRef.current || !canvasBackgroundRef.current) return;
    const backgroundConfetti = confetti.create(canvasBackgroundRef.current, {
      resize: true,
      useWorker: true
    });
    const foregroundConfetti = confetti.create(canvasForegroundRef.current, {
      resize: true,
      useWorker: true
    });
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const aspectRatio = windowWidth / windowHeight;
    const baseOrigin = {
      x: 0.75 + 0.5 * (1 / aspectRatio),
      y: -0.5 * aspectRatio
    };
    const countFactor = windowWidth / 1600;
    const baseOptions: confetti.Options = {
      colors: [theme.colors.honey, theme.colors.winter, theme.colors.spring, theme.colors.summer, theme.colors.fall],
      origin: baseOrigin,
      angle: 210,
      particleCount: 100 * countFactor,
      spread: 80,
      drift: -0.5,
      startVelocity: Math.max(windowWidth, windowHeight) / 10,
      gravity: 0.3,
      ticks: 5000,
      scalar: 1.5,
      decay: 0.85,
      disableForReducedMotion: true
    };
    const shotsFired = [backgroundConfetti(baseOptions), backgroundConfetti({
      ...baseOptions,
      scalar: (baseOptions.scalar ?? 1) * 0.6,
      startVelocity: (baseOptions.startVelocity ?? 45) * 1.5,
      origin: {
        x: baseOrigin.x * 1.4,
        y: baseOrigin.y * 1.6667
      }
    }), foregroundConfetti({
      ...baseOptions,
      particleCount: 100 * countFactor,
      origin: {
        x: baseOrigin.x * 2,
        y: baseOrigin.y * 2
      },
      scalar: (baseOptions.scalar ?? 1) * 1.35,
      startVelocity: (baseOptions.startVelocity ?? 45) * 2,
      gravity: (baseOptions.gravity ?? 1) * 1.5
    })];
    Promise.all(shotsFired ?? []).then(() => {
      if (isMountedRef.current) {
        onCompleteRef.current?.();
      }
    });
    return () => {
      foregroundConfetti.reset();
      backgroundConfetti.reset();
    };
  }, []);
  return _jsxs(_Fragment, {
    children: [_jsx(Portal.Root, {
      children: _jsx("canvas", {
        ref: canvasBackgroundRef,
        tw: "w-screen h-screen fixed inset-0 pointer-events-none z-[-1]"
      })
    }), _jsx(Portal.Root, {
      children: _jsx("canvas", {
        ref: canvasForegroundRef,
        tw: "w-screen h-screen fixed inset-0 pointer-events-none z-[1000]"
      })
    })]
  });
};