function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import SwitchBox from '@landfolk/shared/components/SwitchBox';
import { useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "1x9x8ba-SwitchBoxExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "5z7ecy-SwitchBoxExample",
  styles: "max-width:65ch;label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "2rlicp-SwitchBoxExample",
  styles: "margin-bottom:1rem;label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "19ikgmw-SwitchBoxExample",
  styles: "margin-bottom:2rem;label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "33gn8n",
  styles: "max-width:48rem"
} : {
  name: "1k9xhsf-SwitchBoxExample",
  styles: "max-width:48rem;label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "5z7ecy-SwitchBoxExample",
  styles: "max-width:65ch;label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1rr6dw8",
  styles: "margin-top:2rem;margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;}"
} : {
  name: "1ukzezf-SwitchBoxExample",
  styles: "margin-top:2rem;margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){font-size:1rem;line-height:1.5rem;};label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1vjxmwf",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc"
} : {
  name: "t1tdnx-SwitchBoxExample",
  styles: "margin-top:-1rem;margin-left:1rem;list-style-type:disc;label:SwitchBoxExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function SwitchBoxExample() {
  const [checked, setChecked] = useState(false);
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "SwitchBox"
    }), _jsxs("section", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "Switchboxes function like switches, but are highlighted by being placed in a box and at the top of a page. They are only used for important decisions that users should be aware of."
      }), _jsx("p", {
        css: _ref4,
        children: "Just like switches, the switchbox only has an on and off state, so if users do not make an active decision, they make a passive one."
      })]
    }), _jsx(SwitchBox, {
      css: _ref5,
      checked: checked,
      onChange: () => setChecked(!checked),
      headline: "This is the switchbox",
      children: _jsx("p", {
        children: "It can be used for many things."
      })
    }), _jsxs("section", {
      css: _ref6,
      children: [_jsx("h3", {
        css: _ref7,
        children: "Best practices"
      }), _jsxs("ul", {
        css: _ref8,
        children: [_jsx("li", {
          children: "Use if the switch influences other settings in the page"
        }), _jsx("li", {
          children: "Place the switchbox, so users will easily notice it"
        }), _jsx("li", {
          children: "Concisely explain what users are switching on/off in the title"
        }), _jsx("li", {
          children: "Add more details to the consequences of switching on/off in the body, but aim for no more than 3 lines"
        })]
      })]
    })]
  });
}