function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { addMinutes, addWeeks, differenceInYears, isToday, isWithinInterval } from 'date-fns';
import { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Bicycle from '../../icons/Bicycles.svg';
import Calendar from '../../icons/Calendar.svg';
import Guide from '../../icons/Guide.svg';
import House from '../../icons/House.svg';
import Lightning from '../../icons/Lightning.svg';
import Pacifier from '../../icons/Pacifier.svg';
import Party from '../../icons/Party.svg';
import Pet from '../../icons/Pets.svg';
import Spa from '../../icons/Spa.svg';
import Towel from '../../icons/Towel.svg';
import Trampoline from '../../icons/Trampoline.svg';
import Wifi from '../../icons/Wifi.svg';
import { PseudoRandomGenerator } from '../../utils/random';
import { Confetti } from '../Confetti';
import { FeatureAvailability, ListingFeatures } from '../ListingFeatures';
import Nothing from '../Nothing';
import { ListingStatsProps } from '.';
import { StatBox } from './shared';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export type AffirmationsProps = {
  capacity: number;
  idealCapacity?: number;
  completedBookings: number;
  listingPublishedAt?: string;
  instantBookable: boolean;
  publishedLanfolkGuideSections?: number;
  features: ListingFeatures;
  publishedConfetti?: boolean;
  listingShortId: string;
  className?: string;
} & Pick<ListingStatsProps, 'onStatsSectionViewed'>;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "bmzdud",
  styles: "display:flex;@keyframes fade-in{0%{opacity:0;}100%{opacity:1;}}animation:fade-in 0.4s cubic-bezier(0, 0, 0.2, 1) forwards;align-items:flex-start;gap:1rem;--tw-bg-opacity:1;background-color:rgb(235 245 236 / var(--tw-bg-opacity))"
} : {
  name: "gqimx8-Affirmation",
  styles: "display:flex;@keyframes fade-in{0%{opacity:0;}100%{opacity:1;}}animation:fade-in 0.4s cubic-bezier(0, 0, 0.2, 1) forwards;align-items:flex-start;gap:1rem;--tw-bg-opacity:1;background-color:rgb(235 245 236 / var(--tw-bg-opacity));label:Affirmation;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "16hwlqf",
  styles: "flex-shrink:0;@media (max-width: 39.999em){order:2;}"
} : {
  name: "ka5svk-Affirmation",
  styles: "flex-shrink:0;@media (max-width: 39.999em){order:2;};label:Affirmation;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1d3w5wq",
  styles: "width:100%"
} : {
  name: "t2n1ha-Affirmation",
  styles: "width:100%;label:Affirmation;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "rlna96",
  styles: "font-weight:600;line-height:1.5rem"
} : {
  name: "oqbpwa-Affirmation",
  styles: "font-weight:600;line-height:1.5rem;label:Affirmation;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "ozibme",
  styles: "font-size:0.75rem;line-height:1rem"
} : {
  name: "1ib4ur7-Affirmation",
  styles: "font-size:0.75rem;line-height:1rem;label:Affirmation;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const Affirmation = (props: AffirmationsProps) => {
  const randomAffirmation = useRandomAffirmation(props);
  const iconRef = (useRef() as MutableRefObject<HTMLDivElement>);
  const iconPostion = iconRef.current?.getBoundingClientRect();
  const [showConfetti, setShowConfetti] = useState(false);
  const confettiOptions = useMemo(() => {
    if (!iconPostion) return;
    return ({
      origin: {
        x: iconPostion.x / window.innerWidth,
        y: iconPostion.y / window.innerHeight
      },
      angle: 0,
      spread: 360,
      startVelocity: 10,
      decay: 0.96,
      ticks: 50,
      gravity: 0.5,
      disableForReducedMotion: true
    } as confetti.Options);
  }, [iconPostion]);
  useEffect(() => {
    if (!props.publishedConfetti) return;
    const timeout = setTimeout(() => {
      props.publishedConfetti && setShowConfetti(true);
    }, 1700);
    return () => clearTimeout(timeout);
  }, [props.publishedConfetti]);
  if (!randomAffirmation) {
    return _jsx(Nothing, {});
  }
  return _jsxs(StatBox, {
    section: "affirmation",
    onStatsSectionViewed: props.onStatsSectionViewed,
    className: props.className,
    css: _ref,
    children: [_jsxs("div", {
      css: _ref2,
      ref: iconRef,
      children: [randomAffirmation.icon, (randomAffirmation.key === 'anniversary' || randomAffirmation.key === 'published' && showConfetti) && iconPostion && _jsx(Confetti, {
        options: confettiOptions
      })]
    }), _jsxs("div", {
      css: _ref3,
      children: [_jsx("header", {
        children: _jsx("h3", {
          css: _ref4,
          children: _jsx(FormattedMessage, {
            ...titles[(randomAffirmation.key as keyof typeof titles)],
            values: {
              value: randomAffirmation.value
            }
          })
        })
      }), _jsx("p", {
        css: _ref5,
        children: _jsx(FormattedMessage, {
          ...contents[(randomAffirmation.key as keyof typeof contents)],
          values: {
            value: randomAffirmation.value
          }
        })
      })]
    })]
  });
};
const useRandomAffirmation = (props: AffirmationsProps) => {
  const {
    capacity,
    idealCapacity,
    completedBookings,
    listingPublishedAt,
    instantBookable,
    publishedLanfolkGuideSections,
    features,
    listingShortId
  } = props;
  const anniversaryShown = useRef(sessionStorage.getItem('anniversaryShown'));
  const publishCompletedAt = sessionStorage.getItem('publishCompletedAt')?.split('-');
  const publishCompletedAtAddTen = publishCompletedAt && publishCompletedAt[0] === listingShortId && publishCompletedAt[1] && addMinutes(new Date(publishCompletedAt[1]), 10);
  const publishCompletedAtAddTenInMinutes = publishCompletedAtAddTen && publishCompletedAtAddTen.getMinutes();
  const publishCompletedAtAddTenInHours = publishCompletedAtAddTen && publishCompletedAtAddTen.getHours();
  const now = new Date();
  const publishCompletedAtDiff = publishCompletedAtAddTenInMinutes && publishCompletedAtAddTenInHours && publishCompletedAtAddTenInHours === now.getHours() && publishCompletedAtAddTenInMinutes - now.getMinutes();
  if (listingPublishedAt && isToday(new Date(listingPublishedAt))) {
    return {
      key: 'published',
      value: publishCompletedAtDiff && publishCompletedAtDiff > 0,
      icon: _jsx(Party, {})
    };
  }
  const availableFeature = FeatureAvailability.Available;
  const list: {
    key: string;
    value?: number;
    icon: ReactNode;
  }[] = [];
  if (idealCapacity && idealCapacity > 16 || !idealCapacity && capacity > 16) {
    list.push({
      key: 'spaceForEveryone',
      value: idealCapacity || capacity,
      icon: _jsx(House, {})
    });
  }
  if (completedBookings > 0 && completedBookings % 5 === 0) {
    list.push({
      key: 'completedBookings',
      value: completedBookings,
      icon: _jsx(Calendar, {})
    });
  }
  if (instantBookable) {
    list.push({
      key: 'instantBookable',
      icon: _jsx(Lightning, {})
    });
  }
  if (publishedLanfolkGuideSections && publishedLanfolkGuideSections > 0) {
    list.push({
      key: 'landfolkGuide',
      icon: _jsx(Guide, {})
    });
  }
  if (listingPublishedAt) {
    const today = new Date();
    const publishDate = new Date(listingPublishedAt);
    const yearsPassed = differenceInYears(today, publishDate);
    if (yearsPassed > 0) {
      const anniversaryThisYear = new Date(today.getFullYear(), publishDate.getMonth(), publishDate.getDate());
      const twoWeeksAfterAnniversary = addWeeks(anniversaryThisYear, 2);
      if (isWithinInterval(today, {
        start: anniversaryThisYear,
        end: twoWeeksAfterAnniversary
      })) {
        list.push({
          key: 'anniversary',
          value: yearsPassed,
          icon: _jsx(Party, {})
        });
      }
    }
  }
  if (features.wifi.availability === availableFeature) {
    list.push({
      key: 'wifi',
      icon: _jsx(Wifi, {})
    });
  }
  if (features.trampoline.availability === availableFeature) {
    list.push({
      key: 'trampoline',
      icon: _jsx(Trampoline, {})
    });
  }
  if (features.highChair.availability === availableFeature && features.toys.availability === availableFeature && features.childBed.availability === availableFeature) {
    list.push({
      key: 'children',
      icon: _jsx(Pacifier, {})
    });
  }
  if (features.spa.availability === availableFeature || features.sauna.availability === availableFeature || features.hotTub.availability === availableFeature) {
    list.push({
      key: 'luxury',
      icon: _jsx(Spa, {})
    });
  }
  if (features.linenAndTowels.availability === availableFeature) list.push({
    key: 'linenAndTowels',
    icon: _jsx(Towel, {})
  });
  if (features.petsAllowed.availability === availableFeature) {
    list.push({
      key: 'pets',
      icon: _jsx(Pet, {})
    });
  }
  if (features.bicycles.availability === availableFeature) {
    list.push({
      key: 'bicycles',
      // This value needs to manually updated
      value: 17,
      icon: _jsx(Bicycle, {})
    });
  }
  const nearestEightHour = Math.round(new Date().getHours() / 8) * 8;
  const randomGenerator = new PseudoRandomGenerator(nearestEightHour);
  if (list.find(item => item.key === 'anniversary') && !anniversaryShown.current) {
    sessionStorage.setItem('anniversaryShown', 'true');
    return list.find(item => item.key === 'anniversary');
  } else {
    return list.filter(item => item.key !== 'anniversary')[Math.floor(randomGenerator.next() * list.length)];
  }
};
const titles = defineMessages({
  spaceForEveryone: {
    id: "m1KPnWedTW",
    defaultMessage: [{
      "type": 0,
      "value": "Your holiday home has space for everyone"
    }]
  },
  completedBookings: {
    id: "mNm4NpblqP",
    defaultMessage: [{
      "type": 0,
      "value": "Amazing! You have welcomed "
    }, {
      "type": 6,
      "value": "value",
      "options": {
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " stay"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " stays"
          }]
        }
      },
      "offset": 0,
      "pluralType": "cardinal"
    }]
  },
  instantBookable: {
    id: "PjAA73EJ35",
    defaultMessage: [{
      "type": 0,
      "value": "You have activated Instant booking"
    }]
  },
  landfolkGuide: {
    id: "NCEu19XzF9",
    defaultMessage: [{
      "type": 0,
      "value": "You have created a Landfolk Guide. Great!"
    }]
  },
  anniversary: {
    id: "E7zEGlziQL",
    defaultMessage: [{
      "type": 0,
      "value": "Congrats with your Landfolk anniversary"
    }]
  },
  wifi: {
    id: "b6pP+O3MuF",
    defaultMessage: [{
      "type": 0,
      "value": "Sweet, there is wi-fi in your holiday home"
    }]
  },
  trampoline: {
    id: "mPLbSmDMpq",
    defaultMessage: [{
      "type": 0,
      "value": "Cool, you have a trampoline!"
    }]
  },
  children: {
    id: "eFh4lltIEC",
    defaultMessage: [{
      "type": 0,
      "value": "You are popular among families with children"
    }]
  },
  luxury: {
    id: "LKkpC8iqMz",
    defaultMessage: [{
      "type": 0,
      "value": "You share your luxury"
    }]
  },
  linenAndTowels: {
    id: "GM9Q/+JoHt",
    defaultMessage: [{
      "type": 0,
      "value": "You take care of towels and linen 👏"
    }]
  },
  pets: {
    id: "nLojTefXNY",
    defaultMessage: [{
      "type": 0,
      "value": "Awesome! You welcome pets"
    }]
  },
  bicycles: {
    id: "tjCfGxjT+G",
    defaultMessage: [{
      "type": 0,
      "value": "Your house is perfect for active guests"
    }]
  },
  published: {
    id: "mcXbljgKPE",
    defaultMessage: [{
      "type": 0,
      "value": "Congratulations!"
    }]
  }
});
const contents = defineMessages({
  spaceForEveryone: {
    id: "TCQGl0l/r+",
    defaultMessage: [{
      "type": 0,
      "value": "With "
    }, {
      "type": 1,
      "value": "value"
    }, {
      "type": 0,
      "value": " beds, there is room for even the biggest families."
    }]
  },
  completedBookings: {
    id: "mva//nhtr0",
    defaultMessage: [{
      "type": 0,
      "value": "Thank you for sharing your holiday home with our guests."
    }]
  },
  instantBookable: {
    id: "ashDAZtzSP",
    defaultMessage: [{
      "type": 0,
      "value": "You give your guest the best booking experience."
    }]
  },
  landfolkGuide: {
    id: "OjFGPtcwaM",
    defaultMessage: [{
      "type": 0,
      "value": "Our guests love to be well prepared for their holiday home getaway."
    }]
  },
  anniversary: {
    id: "/YAJ2J0oCS",
    defaultMessage: [{
      "type": 0,
      "value": "Thank you so much for having been part of Landfolk for "
    }, {
      "type": 6,
      "value": "value",
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "a year"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " years"
          }]
        }
      },
      "offset": 0,
      "pluralType": "cardinal"
    }, {
      "type": 0,
      "value": "."
    }]
  },
  wifi: {
    id: "a9UAUDKu/I",
    defaultMessage: [{
      "type": 0,
      "value": "Wi-fi is one of the most popular features among our guests."
    }]
  },
  trampoline: {
    id: "iUAagxlzvG",
    defaultMessage: [{
      "type": 0,
      "value": "Families with young children love holiday home with a trampoline."
    }]
  },
  children: {
    id: "dHU/l06RMy",
    defaultMessage: [{
      "type": 0,
      "value": "Families with children love houses with cots, high chairs and toys."
    }]
  },
  linenAndTowels: {
    id: "ntfLvLMGz6",
    defaultMessage: [{
      "type": 0,
      "value": "Our guests love this kind of service."
    }]
  },
  luxury: {
    id: "4vHCu1SJ8F",
    defaultMessage: [{
      "type": 0,
      "value": "Guests highly enjoy holiday homes with wilderness bath, sauna or spa"
    }]
  },
  pets: {
    id: "LpRaNLgDC/",
    defaultMessage: [{
      "type": 0,
      "value": "Many guests book a holiday home where they can bring their pet."
    }]
  },
  bicycles: {
    id: "I6HQHIyttL",
    defaultMessage: [{
      "type": 1,
      "value": "value"
    }, {
      "type": 0,
      "value": "% of our search results include bicycles."
    }]
  },
  published: {
    id: "1o1p1n5UHk",
    defaultMessage: [{
      "type": 0,
      "value": "Your holiday home is now live and "
    }, {
      "type": 5,
      "value": "value",
      "options": {
        "true": {
          "value": [{
            "type": 0,
            "value": "will be open for bookings in a few minutes"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "open for bookings"
          }]
        }
      }
    }, {
      "type": 0,
      "value": "."
    }]
  }
});