function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Button from '@landfolk/shared/components/Button';
import Select from '@landfolk/shared/components/forms/Select';
import TimeOptions from '@landfolk/shared/components/forms/TimeOptions';
import Clock from '@landfolk/shared/icons/Clock.svg';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const options = _jsxs(_Fragment, {
  children: [_jsx("option", {
    value: "Cozy",
    children: "Cozy"
  }), _jsx("option", {
    value: "Warm",
    children: "Warm"
  }), _jsx("option", {
    value: "Snug",
    children: "Snug"
  }), _jsx("option", {
    value: "Homey",
    children: "Homey"
  }), _jsx("option", {
    value: "Cheerful",
    disabled: true,
    children: "Cheerful"
  })]
});
var _ref = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "1vxi8vf-SelectExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "yjczae-SelectExample",
  styles: "max-width:65ch;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "6xk5wo-SelectExample",
  styles: "margin-bottom:1rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "6fxb52-SelectExample",
  styles: "margin-bottom:2rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "ia8sz4",
  styles: "display:flex;max-width:32rem;flex-direction:column"
} : {
  name: "1a66j83-SelectExample",
  styles: "display:flex;max-width:32rem;flex-direction:column;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "zbw0mx",
  styles: "margin-bottom:1rem;margin-top:0.5rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "5u24bm-SelectExample",
  styles: "margin-bottom:1rem;margin-top:0.5rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "eq7f8j",
  styles: "display:flex;align-items:flex-end"
} : {
  name: "1be6zlq-SelectExample",
  styles: "display:flex;align-items:flex-end;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1mys3sh",
  styles: "margin-left:1rem"
} : {
  name: "14s60mj-SelectExample",
  styles: "margin-left:1rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "1m1n2pz",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "1r30q39-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.5rem;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "1njbrur",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.25"
} : {
  name: "85u5ik-SelectExample",
  styles: "margin-bottom:1rem;margin-top:2rem;font-size:1.5rem;line-height:1.25;label:SelectExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function SelectExample() {
  return _jsxs("div", {
    children: [_jsx("h1", {
      css: _ref,
      children: "Select"
    }), _jsxs("section", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "Selects allow users to select an option from a predefined list. They always include a label and can include icons for the sake of clarity."
      }), _jsx("p", {
        css: _ref4,
        children: "Select is the preferred component if you want your data output to be uniform or if the data inquiry only has a handful of meaningful options."
      })]
    }), _jsxs("div", {
      css: _ref5,
      children: [_jsx("h2", {
        css: _ref6,
        children: "Default"
      }), _jsx(Select, {
        label: "Select adjective",
        children: options
      }), _jsx("h2", {
        css: _ref7,
        children: "Disabled"
      }), _jsx(Select, {
        disabled: true,
        label: "Select adjective",
        children: options
      }), _jsx("h2", {
        css: _ref8,
        children: "With errors message"
      }), _jsx(Select, {
        label: "Select adjective",
        errorMessage: "Error message",
        children: options
      }), _jsx("h2", {
        css: _ref9,
        children: "Required"
      }), _jsxs("form", {
        css: _ref10,
        children: [_jsx(Select, {
          required: true,
          label: "Select adjective",
          children: options
        }), _jsx(Button, {
          css: _ref11,
          children: "Submit"
        })]
      }), _jsx("h2", {
        css: _ref12,
        children: "With a custom placeholder"
      }), _jsx(Select, {
        placeholder: "Please choose an adjective...",
        label: "Select adjective",
        children: options
      }), _jsx("h2", {
        css: _ref13,
        children: "With default value"
      }), _jsx(Select, {
        defaultValue: "Snug",
        label: "Select adjective",
        children: options
      }), _jsx("h2", {
        css: _ref14,
        children: "With a disabled default value"
      }), _jsx(Select, {
        defaultValue: "Cheerful",
        label: "Select adjective",
        children: options
      }), _jsx("h2", {
        css: _ref15,
        children: "Without a placeholder"
      }), _jsx(Select, {
        defaultValue: "Snug",
        placeholder: false,
        label: "Select adjective",
        children: options
      }), _jsx("h2", {
        css: _ref16,
        children: "With a prefix"
      }), _jsx(Select, {
        label: "Select adjective",
        prefix: _jsx(Clock, {}),
        selectStyles: {
          "paddingLeft": "3.5rem"
        },
        children: options
      }), _jsx("h2", {
        css: _ref17,
        children: "With TimeOptions component as options"
      }), _jsx(Select, {
        label: "Select time",
        children: _jsx(TimeOptions, {
          start: "10:00",
          end: "20:00",
          step: 60
        })
      }), _jsx("h2", {
        css: _ref18,
        children: "With very long items"
      }), _jsxs(Select, {
        label: "Select something long",
        placeholder: false,
        children: [_jsx("option", {
          children: "This is a very long option that should not clash with the arrow or otherwise look off."
        }), _jsx("option", {
          children: "This option is also very long and should not be a problem."
        })]
      })]
    })]
  });
}